import isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import has from "lodash/has";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import { getDrivingConditionsFromTrims } from "../../utils/data-transformer";
import vehicleService from "../../vehicle/services/vehicle.service";
import { isDMSPlus } from "../../../api/app.util";

export const getCustomerAndVehicle = async ({
  customerId,
  vehicleId,
  schemaName,
  dealerCode
}) => {
  let customer = null;
  try {
    const response = await searchCustomerById({
      customerId,
      dealerCode,
      schemaName
    });
    customer = response.customers;
    let vehicle = null;
    let newVehicle;
    if (!isEmpty(response.customers) && !isEmpty(response.customers.vehicles)) {
      vehicle = response.customers.vehicles.filter(
        item => item.vehicleId.toString() === vehicleId.toString()
      );
      vehicle = vehicle.length > 0 ? vehicle[0] : [];
      newVehicle = await getMetaVehicleId({ vehicle, dealerCode });
    }
    return {
      customer: response.customers,
      vehicle: {
        ...vehicle,
        ...(!isEmpty(newVehicle) ? newVehicle.data : {})
      }
    };
  } catch (error) {
    console.error(error);
    return { customer: customer ? customer : null, vehicle: null };
  }
};

const searchCustomerById = ({ dealerCode, schemaName, customerId }) => {
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/customer/${schemaName}/${dealerCode}/${customerId}`,
        method: "get"
      },
      response => {
        if (response && response.success) {
          if (isEmpty(_get(response.customers, "vehicles", []))) {
            response.customers.vehicles = [];
          }
        }
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to find customer."
    );
  });
};

const getMetaVehicleId = async ({ vehicle, dealerCode }) => {
  const restEndPoint = `/vehicle/getMetaVehicleId/${dealerCode}`;
  const {
    make,
    model,
    year,
    trim,
    engineSize,
    engineType,
    driveType,
    transmissionType
  } = vehicle;
  const reqParams = {
    make,
    year,
    model,
    trim,
    engineSize,
    engineType,
    driveType,
    transmissionType
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: reqParams,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      },
      response => {
        if (!isEmpty(response)) {
          response.data.metaVehicleId = response.data.id;
        }
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to find vehicle."
    );
  });
};

export const loadDrivingConditionsData = async (
  vehicle,
  dealerCode,
  locale
) => {
  try {
    const appConfig = {
      dealerCode,
      locale
    };
    const response = await vehicleService.getVehicleTrims({
      vehicle,
      appConfig
    });
    if (response && response.success) {
      const record = response.vehicleInfo;
      vehicle.showDrivingCondition = has(record, "showDrivingCondition")
        ? record.showDrivingCondition
        : "";
      vehicle.defaultDrivingCondition = has(record, "defaultDrivingCondition")
        ? record.defaultDrivingCondition
        : "";
      const trims = has(record, "trims") ? record.trims : null;
      vehicle.drivingConditions = getDrivingConditionsFromTrims(trims, vehicle);
    }
    return vehicle;
  } catch (error) {
    console.error(error);
    vehicle.showDrivingCondition = "";
    vehicle.defaultDrivingCondition = "";
    vehicle.drivingConditions = [];
    return vehicle;
  }
};

export const getPayTypeDetails = async (dealerCode, make) => {
  let data = [];
  try {
    const response = await vehicleService.getPayTypeInformation(
      make,
      dealerCode
    );
    data = response?.payTypes || [];
  } catch (error) {
    console.error("Call to getPaytype api failed  with error", error);
  }
  return data;
};
// @note: call API only for DMS+ dealer case
export const getServiceTypeDetails = async (dealerCode, make, dmsType) => {
  let data = [];
  if (!isDMSPlus(dmsType)) return data;

  try {
    const response = await vehicleService.getServiceTypeInformation(
      make,
      dealerCode
    );
    data = response?.serviceTypes || [];
  } catch (error) {
    console.error("Call to getServicetype api failed  with error", error);
  }
  return data;
};

export const addMetaVehicle = async (vehicle, dealer) => {
  try {
    if (has(vehicle, "metaVehicleId")) {
      return vehicle;
    }
    const response = await vehicleService.getMetaVehicleId({
      vehicle,
      appConfig: dealer
    });
    if (response && response.success) {
      vehicle["metaVehicleId"] = response.data.id;
    }
    return vehicle;
  } catch (error) {
    console.error(error);
    vehicle["metaVehicleId"] = "";
    return vehicle;
  }
};

export const getVendorListDetails = async (dealerCode, make) => {
  let data = [];
  try {
    const response = await vehicleService.getVendorList(make, dealerCode);
    data = response || [];
  } catch (error) {
    console.error("Call to getVendorList api failed  with error", error);
  }
  return data;
};
