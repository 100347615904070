import React from "react";
import { PAYER_TYPES } from "../../constants/csr.constants";
import CustomerPaymentComponent from "./customer-pay-payment.component";
import PayTypesPaymentDisplayComponent from "./payTypes-payment-display.component";

const SplitPaymentComponent = ({
  payerConfigs,
  updatePayerChecked,
  chargeAccountDetails,
  updateCustomerPayerChecked,
  legitPayers,
  quoteSummary,
  deletePaymentLines,
  dealerProperties
}) => {
  // REFERENCE: Split payment - Epic: https://rally1.rallydev.com/#/?detail=/portfolioitem/epic/727045519559&fdp=true
  // REFERENCE: Split payment - UX: https://www.figma.com/proto/nVz6uqktMBbR7F7eUfCWBa/RO-Details?page-id=3305%3A162556&node-id=3321-185166&viewport=4233%2C3303%2C0.47&t=OtKRqZrgyAqRnBZR-1&scaling=min-zoom&starting-point-node-id=3321%3A185166&content-scaling=fixed

  const customerPayPayerConfig = payerConfigs.find(
    config => config.payType === PAYER_TYPES.C
  );
  return (
    <>
      <CustomerPaymentComponent
        payerConfig={customerPayPayerConfig}
        updatePayerChecked={updatePayerChecked}
        chargeAccountDetails={chargeAccountDetails}
        updateCustomerPayerChecked={updateCustomerPayerChecked}
        legitPayers={legitPayers}
        deletePaymentLines={deletePaymentLines}
      />
      {payerConfigs
        ?.filter(pc => pc.payType !== PAYER_TYPES.C && pc.show)
        ?.map(payerConfig => {
          return (
            <React.Fragment key={payerConfig.payType}>
              <hr className="spit-payment-horizontal-divider" />
              <PayTypesPaymentDisplayComponent
                payerConfig={payerConfig}
                payType={payerConfig.payType}
                updatePayerChecked={updatePayerChecked}
                quoteSummary={quoteSummary}
                dealerProperties={dealerProperties}
              />
            </React.Fragment>
          );
        })}
      <hr className="spit-payment-horizontal-divider" />
    </>
  );
};

export default SplitPaymentComponent;
