import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import {
  PAY_TYPE_GROUPS,
  PAYMENT_METHODS
} from "../../constants/csr.constants";
import format from "../../utils/format";
import moment from "moment";
import "./finalize-ro-confirmation-modal.scss";

const FinalizeROConfirmationModal = props => {
  // REFERENCE: Finalize RO - UX: https://www.sketch.com/s/8e61c245-4ea7-4e43-a0de-10834a4a2fe5/a/5nx4qg8

  const { show, quoteSummary, updatedPayers, printHandler, closeHandler } =
    props;

  const onPrintClicked = () => {
    printHandler();
  };

  const modalTitle = `Finalize RO ${
    quoteSummary.roNumber ?? quoteSummary.confirmationId
  }`;

  return (
    <ModalDialog
      htmlId="FinalizeROConfirmationModal"
      className="finalize-ro-confirmation-modal"
      show={show}
      header={<h4 className="modal-title">{modalTitle}</h4>}
      footer={
        <div>
          <Button
            htmlId="printButton"
            onClick={onPrintClicked}
            buttonStyle="primary"
          >
            Print
          </Button>
        </div>
      }
      onHide={closeHandler}
    >
      <div className="subheader">
        The following pay types have been addressed.
      </div>
      <div className="payers">
        {updatedPayers.map(payer => {
          const payerLabel = PAY_TYPE_GROUPS.find(
            ptg => ptg.value === payer.payType
          ).payerLabel;
          return (
            <div className="payer" key={`payer${payer.payType}`}>
              <div className="payer-header">
                {`${payerLabel} - ${format.currency(payer.finalPrice)}`}
              </div>
              <div className="closed-details">
                <Badge htmlId={`payerBadge${payer.payType}`} color="purple">
                  Closed - {moment(payer.closedDate).format("MM/DD/YY")}
                </Badge>
                {payer.paymentMethod ? (
                  <span className="payment-method">
                    {
                      PAYMENT_METHODS.find(
                        pm => pm.value === payer.paymentMethod
                      )?.displayText
                    }
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </ModalDialog>
  );
};
export default FinalizeROConfirmationModal;

FinalizeROConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  quoteSummary: PropTypes.object.isRequired,
  updatedPayers: PropTypes.array.isRequired,
  printHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired
};
