/* eslint-disable */
import React from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import {
  convertMinutesToHours,
  convertArraytoString
} from "../features/page-wrapper/utils/parts.util";
import { transformPartsWithDMSParts } from "../PartsLookupModule/utils/parts.util";
import { freezeObject } from "../utils/object";
import {
  OperationSources,
  DealerPublishedCategory,
  ServiceTypes,
  CatalogSources
} from "../features/page-wrapper/constants/page-wrapper.constants";
import { buildPart } from "../PartsLookupModule/utils/helper.util";
import { extractNotes } from "../features/utils/data-transformer";

const defaultService = {
  quoteServiceId: null,
  quoteId: null,
  name: "",
  totalPrice: 0,
  // @todo-edit:fix - For dealer-pub case - update this with laborApps[0].laborAppId;
  // For MENU, declined, recall cases - null
  // global ops case - quote payload will use state.laborApp
  laborAppId: null,
  labor: {
    serviceId: "", // all cases - this value to be service.id/operationId/extServiceId
    notes: "", // dont bind this field with catalog API; always read modified value from UI field {notes}
    price: 0,
    time: 0
  },
  // required for menus and all services
  defaultLaborRate: 0,
  asrNotes: "",
  commentsRequired: false,
  opCode: "",
  description: "",
  operationSource: "",
  serviceKind: "",
  defaultPayTypeCode: "",
  payTypeGroup: "",
  payTypeCode: "",
  payTypeDescription: "",
  defaultServiceTypeCode: "",
  serviceTypeCode: "",
  serviceContract: null,
  serviceTypeDescription: "",
  parts: [],
  // For search flow, partsPrice will hold totalPartsPriceOverride when  partsPriceOverridden flag is true;
  // For summary flow, verify it whether to set finalPartsPrice from API
  partsPrice: 0,
  totalPriceOverride: null,
  totalLaborPriceOverride: null,
  totalPartsPriceOverride: null,

  calculatedLaborPrice: null,
  calculatedTotalPartsPrice: null,
  calculatedServicePrice: null,

  finalLaborPrice: null,
  finalPartsPrice: null, // this field used to update from summary flow when edited
  // @todo-edit: price overridden flags added to context
  laborPriceOverridden: false,
  partsPriceOverridden: false,
  totalPriceOverridden: false
};

let defaultState = {
  service: defaultService,
  filteredParts: [],
  lookupParts: [],
  originalParts: [],
  originalPartsPrice: 0,
  currentEditingService: null,
  originalService: null,
  formattedRawOperationDetails: null,
  serviceType: "",
  payTypeCode: "",
  serviceTypeCode: "",
  serviceContract: null,
  initialized: false,
  isMenuService: false
};

defaultState = freezeObject(defaultState);

const ModifyPartsContext = React.createContext(defaultState);

const Actions = {
  INITIALIZE: "INITIALIZE",
  SET_DMS_LOOKUP_PARTS: "SET_DMS_LOOKUP_PARTS",
  INITIALIZE_WITH_MENU: "INITIALIZE_WITH_MENU",
  SET_DEALERSHIP_NOTES: "SET_DEALERSHIP_NOTES"
};

const ModifyPartsReducer = (state, action) => {
  switch (action.type) {
    // @note: this action is the equivalent of SET_CURRENT_EDITING_SERVICE in EditServiceContext
    case Actions.INITIALIZE: {
      const { currentEditingService, formattedRawOperationDetails } =
        action.payload;
      const {
        selectedVehicleAttributes,
        parts: savedParts,
        payTypeGroup,
        payTypeCode,
        payTypeDescription,
        serviceTypeCode,
        serviceContract,
        serviceTypeDescription,
        finalLaborPrice,
        finalPartsPrice,
        totalPriceOverride,
        totalPartsPriceOverride,
        totalLaborPriceOverride,
        calculatedServicePrice,
        calculatedTotalPartsPrice,
        extServiceId,
        parts: serviceParts,
        serviceName,
        servicePrice,
        dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        asrNotes,
        dealershipNotes,
        commentsRequired,
        serviceDescription,
        operationSource,
        serviceKind
      } = currentEditingService;
      // fix - below block extract Lookup Parts from current Editing Service for globalops, dealer-pub
      let extLaborId = !isEmpty(currentEditingService.labor)
        ? currentEditingService.labor.extLaborId || ""
        : "";
      const rawService = JSON.parse(
        currentEditingService.quoteRawService.rawService
      );
      // @todo-edit: fix - extLaborId will exist for dealer pub, diagnosis, globals ops only; other cases extLaborId is null
      let laborApp = { laborAppId: null, parts: [] };
      if (!extLaborId) {
        //@workaround: past quotes of dealer pub saved with extLaborId as null
        laborApp =
          rawService.catalogSource === CatalogSources.DEALERCATALOG &&
          rawService.laborApps &&
          !isEmpty(rawService.laborApps[0])
            ? rawService.laborApps[0]
            : { laborAppId: null, parts: [] };
      } else {
        const description = !isEmpty(currentEditingService.labor)
          ? currentEditingService.labor.description || ""
          : "";
        // Fallback logic for both global ops case and dealerpublish: always we will have extLaborId used to find match laborApp{object} from  rawService
        if (!description) {
          laborApp = rawService?.laborApps?.find(
            labor => labor.laborAppId && labor.laborAppId === extLaborId
          );
        } else {
          laborApp = rawService?.laborApps?.find(
            labor => labor.displayName && labor.displayName === description
          );
        }
      }
      // last resort fallback if non of the above scenarios are met for laborApp
      laborApp = isEmpty(laborApp) ? { laborAppId: null, parts: [] } : laborApp;
      const { parts: lookupParts } = laborApp; // always read lookupParts from here

      const partIds = savedParts.map(part =>
        !part.extPartId ? "" : part.extPartId.toString()
      );
      const lookupPartsIds = lookupParts.map(part => part.partId.toString());
      const vehicleAttributes = !isEmpty(selectedVehicleAttributes)
        ? JSON.parse(selectedVehicleAttributes)
        : {};
      const recommendedParts = lookupParts.reduce((acc, part) => {
        const partId = part.partId.toString();
        if (partIds.includes(partId)) {
          const savedPart = savedParts.find(
            item => item.extPartId.toString() === partId
          );
          if (!savedPart) {
            return acc;
          }
          acc.push(buildPart(savedPart, part));
        }
        return acc;
      }, []);
      const individualParts = savedParts.reduce((acc, part) => {
        const partId = !part.extPartId ? "" : part.extPartId.toString();
        if (!lookupPartsIds.includes(partId)) {
          acc.push(buildPart(part));
        }
        return acc;
      }, []);
      const filteredParts = [...recommendedParts, ...individualParts].map(
        part => {
          return {
            ...part,
            dmsPending: false
          };
        }
      );
      const { labor } = currentEditingService;
      const formattedEditService = {
        name: serviceName,
        totalPrice: servicePrice,
        laborAppId: extLaborId, //
        labor: {
          serviceId: extServiceId,
          price: !isEmpty(labor) ? labor.laborPrice : null,
          time: !isEmpty(labor) ? convertMinutesToHours(labor.laborTime) : 0
        },
        defaultLaborRate: !isEmpty(labor) ? labor.defaultLaborRate : 0,
        asrNotes,
        dealershipNotes: extractNotes(dealershipNotes),
        commentsRequired,
        opCode: dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        description: serviceDescription,
        operationSource,
        serviceKind,
        defaultPayTypeCode: has(currentEditingService, "defaultPayTypeCode")
          ? currentEditingService.defaultPayTypeCode
          : "",
        defaultServiceTypeCode: has(
          currentEditingService,
          "defaultServiceTypeCode"
        )
          ? currentEditingService.defaultServiceTypeCode
          : "",
        payTypeGroup,
        payTypeCode,
        payTypeDescription,
        serviceTypeCode,
        serviceContract,
        serviceTypeDescription,
        parts: isEmpty(serviceParts) ? [] : serviceParts,
        // final parts price
        partsPrice: finalPartsPrice,
        totalPriceOverride,
        totalLaborPriceOverride,
        totalPartsPriceOverride,

        calculatedLaborPrice: !isEmpty(labor) ? labor.laborPrice : null,
        calculatedTotalPartsPrice,
        calculatedServicePrice,

        finalLaborPrice,
        finalPartsPrice: null,
        // overridden flags derived from price override values
        totalPriceOverridden: isNumber(totalPriceOverride),
        laborPriceOverridden: isNumber(totalLaborPriceOverride),
        partsPriceOverridden: isNumber(totalPartsPriceOverride)
      };
      // const { operationSource, serviceKind } = formattedEditService;
      let serviceType;
      if (!isEmpty(operationSource) && !isEmpty(serviceKind)) {
        if (operationSource === OperationSources.GLOBALCATALOG) {
          serviceType = ServiceTypes.GLOBALREPAIR;
        } else if (operationSource === OperationSources.DEALERCATALOG) {
          if (serviceKind === DealerPublishedCategory.REPAIR) {
            serviceType = ServiceTypes.DIAGNOSIS;
          } else {
            serviceType = ServiceTypes.DEALERPUBLISHED;
          }
        } else if (operationSource === OperationSources.DECLINED) {
          serviceType = ServiceTypes.DECLINED;
        } else if (operationSource === OperationSources.RECALL) {
          serviceType = ServiceTypes.RECALL;
        }
      } else if (!isEmpty(operationSource)) {
        if (operationSource === OperationSources.MENU) {
          serviceType = ServiceTypes.MENU;
        }
      }
      return {
        ...state,
        currentEditingService,
        completedSteps: {
          ...state.completedSteps,
          vehicleAttr: true,
          serviceOptions: true
        },
        vehicleAttributes,
        filteredParts: [...filteredParts],
        errors: {
          ...state.errors,
          serviceOptions: false,
          vehicleAttributes: false,
          serviceNotes: false
        },
        service: { ...formattedEditService },
        originalService: { ...formattedEditService },
        formattedRawOperationDetails,
        // rule - only global ops case, we need laborApp read from current editing service; other cases laborApp is null
        laborApp:
          rawService.catalogSource === CatalogSources.GLOBALCATALOG
            ? laborApp
            : null,
        serviceType,
        lookupParts: [
          ...lookupParts.map(lookupPart => {
            return {
              ...lookupPart,
              selected: false
            };
          })
        ],
        initialized: true,
        isMenuService: false
      };
    }
    // @todo: treat menu saved in quote similar to dealer pub service
    case Actions.INITIALIZE_WITH_MENU: {
      const { currentEditingService, formattedRawOperationDetails } =
        action.payload;
      const {
        parts: savedParts,
        payTypeGroup,
        payTypeCode,
        payTypeDescription,
        serviceTypeCode,
        serviceContract,
        serviceTypeDescription,
        finalLaborPrice,
        finalPartsPrice,
        totalPriceOverride,
        totalPartsPriceOverride,
        totalLaborPriceOverride,
        calculatedServicePrice,
        calculatedTotalPartsPrice,
        extServiceId,
        serviceName,
        servicePrice,
        dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        serviceDescription,
        operationSource,
        serviceKind
      } = currentEditingService;
      // note - for menus, extLaborId = null always
      const extLaborId = currentEditingService.labor.extLaborId || "";
      // @check - rawService has catalog menuService format i,e not unified response format
      const rawService = JSON.parse(
        currentEditingService.quoteRawService.rawService
      );

      // always read lookupParts from rawService
      let lookupParts = [];
      if (rawService.parts) {
        lookupParts = rawService.parts.map(part => {
          return {
            ...part,
            ...(part.oemPartNumber
              ? { oemPartNumber: part.oemPartNumber }
              : { oemPartNumber: "" })
          };
        });
      }

      const partIds = savedParts.map(part =>
        !part.extPartId ? "" : part.extPartId.toString()
      );
      const lookupPartsIds = lookupParts.map(part => part.partId.toString());
      const recommendedParts = lookupParts.reduce((acc, part) => {
        const partId = part.partId.toString();
        if (partIds.includes(partId)) {
          const savedPart = savedParts.find(
            item => item.extPartId.toString() === partId
          );
          if (!savedPart) {
            return acc;
          }
          acc.push(buildPart(savedPart, part));
        }
        return acc;
      }, []);
      const individualParts = savedParts.reduce((acc, part) => {
        const partId = !part.extPartId ? "" : part.extPartId.toString();
        if (!lookupPartsIds.includes(partId)) {
          acc.push(buildPart(part));
        }
        return acc;
      }, []);
      const filteredParts = [...recommendedParts, ...individualParts].map(
        part => {
          return {
            ...part,
            dmsPending: false
          };
        }
      );
      const { labor } = currentEditingService;
      const formattedEditService = {
        name: serviceName,
        totalPrice: servicePrice,
        laborAppId: extLaborId,
        labor: {
          serviceId: extServiceId,
          price: !isEmpty(labor) ? labor.laborPrice : null,
          notes: "",
          time: !isEmpty(labor) ? convertMinutesToHours(labor.laborTime) : 0
        },
        defaultLaborRate: !isEmpty(labor) ? labor.defaultLaborRate : 0,
        asrNotes: "",
        commentsRequired: false,
        opCode: dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        description: serviceDescription,
        operationSource,
        serviceKind: null,
        defaultPayTypeCode: has(currentEditingService, "defaultPayTypeCode")
          ? currentEditingService.defaultPayTypeCode
          : "",
        payTypeGroup,
        payTypeCode,
        payTypeDescription,
        serviceTypeCode,
        serviceContract,
        serviceTypeDescription,
        parts: isEmpty(savedParts) ? [] : savedParts,
        // final parts price
        partsPrice: finalPartsPrice,
        totalPriceOverride,
        totalLaborPriceOverride,
        totalPartsPriceOverride,

        calculatedLaborPrice: !isEmpty(labor) ? labor.laborPrice : null,
        calculatedTotalPartsPrice,
        calculatedServicePrice,

        finalLaborPrice,
        // finalPartsPrice: null,
        finalPartsPrice,
        // overridden flags derived from price override values
        totalPriceOverridden: isNumber(totalPriceOverride),
        laborPriceOverridden: isNumber(totalLaborPriceOverride),
        partsPriceOverridden: isNumber(totalPartsPriceOverride)
      };
      // const { operationSource, serviceKind } = formattedEditService;
      const serviceType = ServiceTypes.MENU;

      operationSource === OperationSources.MENU;
      return {
        ...state,
        currentEditingService,
        completedSteps: {
          ...state.completedSteps,
          vehicleAttr: true,
          serviceOptions: true
        },
        vehicleAttributes: {},
        filteredParts: [...filteredParts],
        errors: {
          ...state.errors,
          serviceOptions: false,
          vehicleAttributes: false,
          serviceNotes: false
        },
        service: { ...formattedEditService },
        originalService: { ...formattedEditService },
        formattedRawOperationDetails, // @check - always be like unified response format like dealer pub
        laborApp: null, // if not, pass as { laborAppId: null, parts: [] }
        serviceType,
        lookupParts: [
          ...lookupParts.map(lookupPart => {
            return {
              ...lookupPart,
              selected: false
            };
          })
        ],
        initialized: true,
        isMenuService: true
      };
    }
    // When open track api has dms parts, this action will update lookupParts with proper priceSource,dmsPrice values
    case Actions.SET_DMS_LOOKUP_PARTS: {
      const { partsPricingAndInventory, hasDealerTire } = action.payload;
      const filteredPartsIds = state.filteredParts.map(part => part.partId);
      const missingParts = state.lookupParts.filter(part => {
        return !filteredPartsIds.includes(part.partId);
      });
      const partsToTransform = [...state.filteredParts, ...missingParts];
      const isModifyFromSummary = true;
      const transformedParts = transformPartsWithDMSParts(
        partsPricingAndInventory,
        partsToTransform,
        state.formattedRawOperationDetails.catalogSource,
        hasDealerTire,
        isModifyFromSummary
      );
      // Fix - If Part has manual price, DONOT replace with parts API DMS price
      const refinedParts = transformedParts?.map(part => {
        if (part?.priceSource === "MANUAL") {
          part.unitPrice = part?.partPrice;
        }
        return {
          ...part,
          dmsPending: false
        };
      });
      return {
        ...state,
        lookupParts: refinedParts
      };
    }
    case Actions.SET_DEALERSHIP_NOTES: {
      return {
        ...state,
        currentEditingService: {
          ...state.currentEditingService,
          dealershipNotes: action.payload
        }
      };
    }
    default: {
      console.log(`Unhandled action type: ${action.type}`);
    }
  }
};

const ModifyPartsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(ModifyPartsReducer, defaultState);
  const value = { state, dispatch };
  return (
    <ModifyPartsContext.Provider value={value}>
      {children}
    </ModifyPartsContext.Provider>
  );
};

ModifyPartsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useModifyPartsContext = () => {
  const context = React.useContext(ModifyPartsContext);
  if (context === undefined) {
    throw new Error(
      "usePartsLookupContext must be used within a PartsLookupProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch
  };
};

export {
  ModifyPartsProvider,
  useModifyPartsContext,
  Actions,
  ModifyPartsContext
};
