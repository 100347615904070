/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp  */
import React, { useEffect } from "react";
import { Quote, PastQuotes } from ".";
import { getUrlParameter } from "../api/app.util";
import {
  DEFAULT_APPSOURCE,
  appSources,
  appTypes
} from "../constants/app.constants";
// import { isTesting } from "../commonUtil/utils/is-dev";
import ServiceHistory from "../features/repair-order/components/service-history/service-history.component";
import ServiceLog from "../features/repair-order/components/service-log/service-log.component";
import ArchivedEventLog from "../features/repair-order/components/service-log/archived-event-log.component";
import CommonModalTestPage from "../features/ui/modals/common-modal/common-modal-test-page";
import { getAppType } from "../api/xmmAxios";
import { AddServiceContract } from "../features/service-contract/add-service-contract.component";

let appSource = getUrlParameter("appSource");
if (!appSource) {
  appSource = DEFAULT_APPSOURCE;
}
// Pendo - set location url according to the path provided
const withPendoTracking = (WrappedComponent, path) => {
  return () => {
    const { search, hash } = window.location;

    useEffect(() => {
      const domain = window.location.origin;
      const cleanUrl = `${domain}${path}${search}${hash}`;
      pendo.location.setUrl(cleanUrl);
    }, [search, hash]);

    return <WrappedComponent />;
  };
};
// @csr-logic
const appType = getAppType(getUrlParameter("appType"));
const routes = [];
if (appSource === appSources.IND) {
  // Standalone case: These routing pages render for Quoting UI when appSource is IND
  if (appType === appTypes.SQ) {
    routes.push({
      path: "/dashboard",
      exact: true,
      label: "Past quotes",
      localekey: "sq.nav.past_quotes",
      main: withPendoTracking(PastQuotes, "/dashboard")
    });
  }
  // Standalone case: These routing pages render for Service RO UI when appSource is IND
  if (appType === appTypes.CSR) {
    routes.push({
      path: "/dashboard",
      exact: true,
      label: "Repair Orders",
      localekey: "Repair Orders",
      main: withPendoTracking(PastQuotes, "/dashboard")
    });
    routes.push({
      path: "/rodetails",
      exact: true,
      label: "Repair Order Details",
      localekey: "Repair Order Details",
      main: withPendoTracking(Quote, "/rodetails")
    });
    routes.push({
      path: "/servicehistory",
      exact: true,
      label: "Service History",
      localekey: "Service History",
      main: () => <ServiceHistory />
    });
    routes.push({
      path: "/servicelog",
      exact: true,
      label: "Service Log",
      localekey: "Service Log",
      main: () => <ServiceLog />
    });
    routes.push({
      path: "/archivedeventlog",
      exact: true,
      label: "Archived Event Log",
      localekey: "Archived Event Log",
      main: () => <ArchivedEventLog />
    });
    routes.push({
      path: "/servicecontracts",
      exact: true,
      label: "Service Contracts",
      localekey: "Service Contracts",
      main: () => <AddServiceContract />
    });
    routes.push({
      path: "/common-modal-test-page",
      exact: true,
      label: "Common Modal Test Page",
      localekey: "Common Modal Test Page",
      main: () => <CommonModalTestPage />
    });
  }
} else if (appSource !== appSources.IND) {
  // External app case: Launch "New Quote" page of Quoting UI from external apps {Scheduling, Engage, Inspect}
  if (appType === appTypes.SQ) {
    routes.push({
      path: "/newquote",
      exact: true,
      label: "New quote",
      localekey: "sq.nav.new_quote",
      main: withPendoTracking(Quote, "/newquote")
    });
  }
  // External app case: Launch "RO Details" page of Service RO UI from external app {Engage}
  if (appType === appTypes.CSR && appSource === appSources.ENG) {
    routes.push({
      path: "/rodetails",
      exact: true,
      label: "Repair Order Details",
      localekey: "Repair Order Details",
      main: withPendoTracking(Quote, "/rodetails")
    });
  }
}
export default routes;
