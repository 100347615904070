import React from "react";
import {
  buttonStyle,
  CommonModal
} from "../common-modal/common-modal.component";
import PropTypes from "prop-types";

/**
 * Reusable modal to prompt the user to enter a reason for performing an action.
 * @param props.showModal {bool} Whether or not the modal should be visible.
 * @param props.title {string} Title to display in the modal header.
 * @param props.message {string} Message to display below the modal header.
 * @param props.alertType {alertIconType} Which alert icon to display. (info, warning, danger, or success)
 * @param props.alertMessage {Element} Alert message content. Can be a JSX element, or a simple string.
 * @param props.cancelButtonText {string?} Text to display for the Cancel button. (Optional; defaults to "Cancel".)
 * @param props.submitButtonText {string} Text to display for the Submit button.
 * @param props.onCancel {function} Handler for when the "X" button or Cancel button gets clicked.
 * @param props.onSubmit {function} Handler for when the Submit button gets clicked.
 * @returns {Element}
 */
const ReasonModal = props => {
  const {
    showModal,
    title,
    message,
    alertType,
    alertMessage,
    cancelButtonText,
    submitButtonText,
    onCancel,
    onSubmit
  } = props;

  return (
    <CommonModal
      show={showModal ?? false}
      onHide={onCancel}
      title={title}
      message={message}
      alert={
        alertType && alertMessage
          ? {
              type: alertType,
              message: alertMessage
            }
          : null
      }
      reasonProps={{
        isRequired: true
      }}
      buttons={[
        {
          text: cancelButtonText ?? "Cancel",
          style: buttonStyle.secondary,
          testId: "reasonModalCancelButton",
          onClick: onCancel
        },
        {
          text: submitButtonText,
          style: buttonStyle.danger,
          testId: "reasonModalSubmitButton",
          onClick: onSubmit
        }
      ]}
    />
  );
};

ReasonModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  alertType: PropTypes.string,
  alertMessage: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ReasonModal;
