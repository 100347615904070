import React from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import TextInput from "@cx/ui/TextInput";
import "./csr.scss";

const WarrantyFreeFieldsComponent = ({
  warrantyPayload,
  updateWarrantyPayload,
  dealerCompanies
}) => {
  const onSelect = cxEvent => {
    if (cxEvent.target.name === "claimType") {
      updateWarrantyPayload({
        ...warrantyPayload,
        claimType: cxEvent?.target?.value[0]?.value
      });
    } else if (cxEvent.target.name === "coverageCode") {
      updateWarrantyPayload({
        ...warrantyPayload,
        coverageCode: cxEvent?.target?.value[0]?.value
      });
    } else {
      updateWarrantyPayload({
        ...warrantyPayload,
        [cxEvent.target.name]:
          cxEvent?.target?.value !== "" ? cxEvent?.target?.value : null
      });
    }
  };

  const getClaimTypes = () => {
    if (
      dealerCompanies.includes("GENESISUSA") ||
      dealerCompanies.includes("HMA")
    ) {
      return hmaGenesisClaimTypeOptions;
    } else if (dealerCompanies.includes("KIAUSA1")) {
      return kiaClaimTypeOptions;
    } else if (
      dealerCompanies.includes("HONDAUSA") ||
      dealerCompanies.includes("ACURAUSA")
    ) {
      return hondaClaimTypeOptions;
    } else {
      return claimTypeOptions;
    }
  };

  const claimTypeOptions = [
    {
      value: "CM",
      label: "CM - Campaign"
    },
    {
      value: "PD",
      label: "PD - PDI"
    },
    {
      value: "PO",
      label: "PO - Primary Operation"
    },
    {
      value: "PP",
      label: "PP - Primary Part"
    }
  ];
  const hmaGenesisClaimTypeOptions = [
    {
      value: "WAR",
      label: "WAR - Warranty Claim"
    },
    {
      value: "RPA",
      label: "RPA - Retail Part Claim"
    },
    {
      value: "CPN",
      label: "CPN - Campaign Claim"
    },
    {
      value: "TRN",
      label: "TRN - Transportation Claim"
    },
    {
      value: "CUV",
      label: "CUV - CUV Warranty Claim"
    },
    {
      value: "HPP",
      label: "HPP - Protection Plan Claim"
    },
    {
      value: "PDI",
      label: "PDI - Pre Delivery Inspection Claim"
    }
  ];
  const kiaClaimTypeOptions = [
    {
      value: "C",
      label: "Perforation/Corrosion (C)"
    },
    {
      value: "D",
      label: "Powertrain (D)"
    },
    {
      value: "E",
      label: "Emission (E)"
    },
    {
      value: "F",
      label: "3rd Anniv. Special Svc (F)"
    },
    {
      value: "G",
      label: "Goodwill (G)"
    },
    {
      value: "I",
      label: "PDI (I)"
    },
    {
      value: "P",
      label: "Paint (P)"
    },
    {
      value: "R",
      label: "Recall/Campaign (R)"
    },
    {
      value: "S",
      label: "Service Parts (S)"
    },
    {
      value: "V",
      label: "SA001/RB001/CS001 (V)"
    },
    {
      value: "W",
      label: "Warranty Basic (W)"
    },
    {
      value: "3",
      label: "Anniv Oil Change (3)"
    }
  ];
  const hondaClaimTypeOptions = [
    {
      value: "REGULAR",
      label: "REGULAR"
    },
    {
      value: "QUICK",
      label: "QUICK"
    },
    {
      value: "TEMPLATE",
      label: "TEMPLATE"
    },
    {
      value: "PARTS - DEALER INSTALLED",
      label: "PARTS - DEALER INSTALLED"
    },
    {
      value: "PARTS - OVER THE COUNTER",
      label: "PARTS - OVER THE COUNTER"
    },
    {
      value: "VSC CLAIM",
      label: "VSC CLAIM"
    },
    {
      value: "SERVICE PASS",
      label: "SERVICE PASS"
    },
    {
      value: "MOTOCOMPACTO",
      label: "MOTOCOMPACTO"
    }
  ];
  const coverageOptions = [
    {
      value: "CM",
      label: "CM - Campaign"
    },
    {
      value: "FG",
      label: "FG - Factory Goodwill"
    },
    {
      value: "FW",
      label: "FW - Factory Warranty"
    },
    {
      value: "PD",
      label: "PD - PDI"
    },
    {
      value: "SC",
      label: "SC - Service Contract"
    }
  ];
  return (
    <Grid>
      <Row style={{ display: "flex" }}>
        <Col className="warranty-free-field-component-col">
          <SearchableSelect
            htmlId="claimTypeSelect"
            label="Claim type"
            name="claimType"
            onChange={onSelect}
            options={getClaimTypes()}
            enableMultiSelect={false}
            value={warrantyPayload?.claimType || ""}
          />
        </Col>
        <Col className="warranty-free-field-component-col">
          <TextInput
            htmlId="diagnosticCodeInput"
            label="Diagnostic code"
            name="diagnosticCode"
            onChange={onSelect}
            maxLength={6}
            value={warrantyPayload?.diagnosticCode || ""}
          />
        </Col>
        <Col className="warranty-free-field-component-col">
          <TextInput
            htmlId="symptomCodeInput"
            label="Symptom code"
            name="symptomCode"
            onChange={onSelect}
            maxLength={6}
            value={warrantyPayload?.symptomCode || ""}
          />
        </Col>
        {!(
          dealerCompanies.includes("GENESISUSA") ||
          dealerCompanies.includes("HMA") ||
          dealerCompanies.includes("KIAUSA1") ||
          dealerCompanies.includes("HONDAUSA") ||
          dealerCompanies.includes("ACURAUSA")
        ) ? (
          <Col className="warranty-free-field-component-col">
            <SearchableSelect
              htmlId="coverageCodeselect"
              label="Coverage code"
              name="coverageCode"
              onChange={onSelect}
              options={coverageOptions}
              enableMultiSelect={false}
              value={warrantyPayload?.coverageCode || ""}
            />
          </Col>
        ) : null}

        <Col className="warranty-free-field-component-col">
          <TextInput
            htmlId="casualPartInput"
            label="Primary failed part"
            name="casualPart"
            onChange={onSelect}
            value={warrantyPayload?.casualPart || ""}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default WarrantyFreeFieldsComponent;
