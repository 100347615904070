/* eslint-disable no-console */
/* eslint-disable react/jsx-max-depth */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import platform from "platform";
import Button from "@cx/ui/Button";
import TextArea from "@cx/ui/TextArea";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { toast } from "@cx/ui/Toast";
import { LicenseManager } from "ag-grid-enterprise";
import appServices from "../services/app.service";
import Header from "../features/ui/layouts/Header";
import Nav from "../features/ui/layouts/Nav";
import routes from "./routes";
import { DealerList } from ".";
import { NotFound, NoAccess } from "../features/ui/reusable/common";
import { buildUserPermissions } from "../features/utils/user-permissions.util";
import "react-virtualized/styles.css";
import "../theme/_app.scss";
import { AppProvider, initialAppState } from "../state/app-context";
import {
  fromQueryString,
  redirectToCSRPage,
  checkIfStandaloneApp,
  checkIfIsCSR,
  getUrlParameter,
  refineURL,
  getAppEnvironment,
  checkSourceCode,
  refineURLForCSR
} from "../api/app.util";
import {
  DEFAULT_LOCALE,
  DEFAULT_APPSOURCE,
  FULL_ACCESS,
  NOT_AUTHORIZED,
  DENIED,
  ACCESS_DEALER_PAGE
} from "../constants/app.constants";
import { UNDEFINED_VALUE } from "../constants/module.constants";
import { getMetaTagContentByName } from "../utils/dom.util";
import {
  jwtRefresh,
  loadUiConfig,
  jwtXsession,
  redirectToDefaultPage,
  makeSecureRestApi,
  getBridgeV2Configs,
  setLocale,
  getAppType // @csr-logic
} from "../api/xmmAxios";
import { detectBrowser } from "../utils/browser.util";
import { isEmptyObject, isArrayExist } from "../utils/object";
import { isTrue } from "../utils/value.util";
import { isEmptyString } from "../utils/string.util";
import { LanguageProvider } from "../i18n/LanguageProvider";
import { getLocaleStrings } from "../i18n/LocaleSender";
import { setTranslations, xlate } from "../utils/i18n/locales";
import ToastDefault from "../features/ui/Toast";
import { sleep } from "../utils/helper.util";
import * as gtag from "../features/utils/gtag/gtag-manager.util";
import * as gtmEvent from "../features/utils/gtag/gtag-event.util";
import { ErrorProvider } from "../features/ui/reusable/errormodule/error.provider";
import { NewQuoteProvider } from "../state/NewQuoteContext";
import { AG_GRID_LICENSE_2023 } from "../constants/license.constant";
import moment from "moment";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import {
  prepareDealerSettingsObject,
  updateDealerSettingsObject
} from "../features/utils/app-state.util";
import { assistanceRequestStatus } from "../constants/assiatance-request.constants";
import ErrorNotification from "../features/ui/reusable/errormodule/error-notification.component";
import { logApplicationStartup } from "../services/log.service";
import { APIErrorProvider } from "../features/ui/reusable/errormodule/api/api-error.provider";
import mfcodeService from "../services/mfcode.service";
// Add Ag-grid License key here at App level
LicenseManager.setLicenseKey(AG_GRID_LICENSE_2023);

// This component uses AppContext to show a viable alternative to Redux for sharing global data.
class App extends Component {
  static propTypes = {
    appSource: PropTypes.string,
    locale: PropTypes.string,
    userName: PropTypes.string,
    webKey: PropTypes.string,
    loginURL: PropTypes.string
  };

  constructor(props) {
    super(props);
    // Bind AppContext handler here
    this.setUserPermissions = this.setUserPermissions.bind(this);
    this.setWebKey = this.setWebKey.bind(this);
    this.setLoginURL = this.setLoginURL.bind(this);
    this.setDealer = this.setDealer.bind(this);
    this.setScopedDealers = this.setScopedDealers.bind(this);
    this.checkModuleAccess = this.checkModuleAccess.bind(this);
    this.setAppConfigParams = this.setAppConfigParams.bind(this);
    this.noAccessAction = this.noAccessAction.bind(this);
    // CustomEvent handlers
    this.reloadPastQuotes = this.reloadPastQuotes.bind(this);
    // this.scrollTopAction = this.scrollTopAction.bind(this);
    // this.scrollingDetection = this.scrollingDetection.bind(this);
    this.handleSessioinExpired = this.handleSessioinExpired.bind(this);
    const device = {
      browser: detectBrowser(),
      operatingSystem: platform.os.toString()
    };
    // TODO: read query params from browser URL
    const baseParams = fromQueryString();
    let isDebug =
      baseParams && baseParams.debugMode ? baseParams.debugMode : "";
    isDebug = isTrue(isDebug);
    const webKey = baseParams && baseParams.webKey ? baseParams.webKey : "";
    const loginURL = "";
    const appSource =
      baseParams && baseParams.appSource
        ? baseParams.appSource
        : DEFAULT_APPSOURCE;
    const locale =
      baseParams && baseParams.locale ? baseParams.locale : DEFAULT_LOCALE;
    const localeStrings = getLocaleStrings(locale);
    setTranslations(localeStrings, locale);
    const dealer = initialAppState.dealer;
    const quote = initialAppState.quote;
    const userPermissions = initialAppState.userPermissions;
    const { customerId, vehicleId, quoteId } = quote;
    // @appType logic now applies to both sq and csr
    const appType = getAppType(baseParams.appType);

    this.state = {
      debugInfo: "",
      device,
      windowSize: {
        width: UNDEFINED_VALUE,
        height: UNDEFINED_VALUE
      },
      location: window.location.pathname,
      locale,
      localeStrings: getLocaleStrings(locale),
      languages: [],
      webKey,
      loginURL,
      appEnv: initialAppState.appEnv,
      appSource,
      user: {
        userName: baseParams.userName ? baseParams.userName : "",
        userId: ""
      },
      userPermissions,
      schemaName: "",
      dealer,
      scopedDealers: [],
      appAccess: null,
      isAuthenticated: false,
      authMsg: "",
      isQuotingEnabled: false,
      isDealerTireEnabled: false,
      isCreatePoEnabled: false,
      isCreateSpecialPartsEnabled: false,
      isSupersededPartsEnabled: false,
      isVoidROEnabled: false,
      synonymsList: [],
      // store t_setting table data
      quoteSetting: [],
      // store - transformed t_dealer_setting table data
      dealerSettings: [],
      dealerProperties: null,
      dealerCompanies: [],
      serviceAssistanceStatus: {
        requestStatus: assistanceRequestStatus.NOT_REQUESTED,
        confirmationId: ""
      },
      partsAssistanceStatus: {
        requestStatus: assistanceRequestStatus.NOT_REQUESTED,
        confirmationId: ""
      },
      showServiceAssistanceRequestBanner: false,
      showPartsAssistanceRequestBanner: false,
      showAttentionRequestFailBanner: false,
      showResolvingRequestFailBanner: false,
      showSendQuoteToCustomerBanner: false,
      // isScrolling: false,
      pastQuotes: [],
      // @note- these params passed from external app to launch Quoting app
      customerId: baseParams.customerId || customerId,
      vehicleId: baseParams.vehicleId || vehicleId,
      quoteId: !baseParams.quoteId ? quoteId : baseParams.quoteId.toString(),
      accessType: !baseParams.quoteId ? null : "EDIT",
      setDealer: this.setDealer,
      setUser: this.setUser,
      setScopedDealers: this.setScopedDealers,
      setLocation: this.setLocation,
      timeZone: null,
      appType, // @csr-logic
      baseParams,
      isPartsView: false,
      isDebug,
      sliderIsOpen: false
    };

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("act")) {
      if (urlParams.get("act") === "partsDashboard") {
        urlParams.delete("act");
        this.props.history.replace(
          `/dashboard?${urlParams.toString()}&view=PARTS`
        );
      } else {
        const pathname = urlParams.get("act");
        urlParams.delete("act");
        this.props.history.replace(`/${pathname}?${urlParams.toString()}`);
      }
    }
  }
  componentDidMount() {
    this.readAppParams();
    // Add event listener
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("scroll", this.scrollingDetection, false);
    window.addEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.addEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );
    // window.addEventListener("fireScrollTopEvent", this.scrollTopAction, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // window.removeEventListener("scroll", this.scrollingDetection, false);
    window.removeEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.removeEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );
    // window.removeEventListener(
    //    "fireScrollTopEvent",
    //   this.scrollTopAction,
    //   false
    // );
  }
  // @note - scrolltop event feature disabled
  // scrollingDetection = event => {
  //   const lastScrollTop = 0;
  //   const currentScroll = window.pageYOffset || document.body.scrollTop;
  //   if (currentScroll > lastScrollTop) {
  //     this.setState({ isScrolling: true });
  //   } else {
  //     this.setState({ isScrolling: false });
  //   }
  //   this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  // };
  // TODO: remove XT params {pt,sqenv, xs} from query params and redirect to quoting portal
  redirectPortal() {
    const pt = getUrlParameter("pt");
    if (pt === "sq") {
      const str = refineURL();
      const queryStr = !str ? "" : "?" + str;
      window.location.replace(
        window.location.origin + window.location.pathname + queryStr
      );
      return;
    } else if (pt === "csr") {
      const str = refineURLForCSR();
      const queryStr = !str ? "" : "?" + str;
      window.location.replace(
        window.location.origin + window.location.pathname + queryStr
      );
      return;
    }
  }
  readAppParams() {
    const env = getUrlParameter("appEnv");
    const text2way = getUrlParameter("text2way");
    let appSource = getUrlParameter("appSource");
    let appEnv = null;
    if (!env) {
      appEnv = getAppEnvironment();
    } else {
      appEnv = env;
    }
    if (!appSource) {
      appSource = DEFAULT_APPSOURCE;
    }
    this.setState(
      {
        appEnv,
        appSource,
        text2way
      },
      () => {
        loadUiConfig(
          this.checkModuleAccess,
          this.noAccessAction,
          appEnv,
          appSource,
          this.setAppConfigParams,
          this.state.appType
        );
      }
    );
  }
  setAppConfigParams(data) {
    const { loginURL } = data;
    this.setLoginURL(loginURL);
  }
  noAccessAction(message) {
    const defaultMessage = !message
      ? xlate("sq.errors.no_access_msg")
      : message;
    this.setState(() => {
      return {
        appAccess: DENIED,
        isAuthenticated: false,
        authMsg: defaultMessage
      };
    });
  }
  // TODO: App level - Decode jwt token and redirect to access page
  checkModuleAccess() {
    this.initializeSession();
    this.redirectPortal();
    this.setLocation(window.location.pathname);
    // Call handler right away so state gets updated with initial window size
    this.handleResize();
    const isAuthenticated = false;
    let msg = "";
    // const webKey = getUrlParameter("webKey");
    // const hasAccess = this.checkBrowser(); // uncomment to check browser support
    const hasAccess = true;
    if (hasAccess) {
      jwtXsession(
        data => {
          const decodedAccessToken =
            data && data.accessToken ? jwtDecode(data.accessToken) : null;
          const userName = decodedAccessToken
            ? decodedAccessToken.sub
            : this.state.user.userName;
          const userId = decodedAccessToken
            ? decodedAccessToken.user_id
            : this.state.user.userId;
          this.setState(
            () => {
              return {
                authMsg: "",
                user: {
                  email: data ? data.email : "",
                  firstName: data ? data.firstName : "",
                  lastName: data ? data.lastName : "",
                  workPhone: data ? data.workPhone : "",
                  ...this.state.user,
                  userName,
                  userId
                }
              };
            },
            () => {
              // TODO: How to remove URL params from XT LOGIN redirected URL
              // window.location.replace(refineURL());
              this.handleApplicationAccess();
            }
          );
        },
        error => {
          this.setState(() => {
            return {
              isAuthenticated: false,
              authMsg: error.msg
            };
          });
        }
      );
    } else {
      msg = this.state.localeStrings["sq.common.browser_support_msg"];
      this.setState(() => {
        return {
          isAuthenticated,
          authMsg: msg
        };
      });
    }
  }
  initializeSession = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("pendingRefreshCalls");
    localStorage.removeItem("timeOffsetInSecs");
    // TODO: For Testing
    setInterval(() => {
      jwtRefresh(() => {
        console.log("session refreshed");
      });
    }, 5 * 60 * 1000);
  };
  // @placeholder: window custom event handler - trigger if any api failure to handle
  handleFatalApplicationAccess = () => {
    // const defaultLocale = window.navigator.language;
    // const { messageKey, defaultMessage } = event.detail;
    // const msg = xlate(messageKey);
    // toast.info(msg, {
    //   closeOnClick: true
    // });
  };
  // TODO: Step-1 method validates params "webKey=xtimemotors&userName=rajap&appSource=SCH"
  handleApplicationAccess = () => {
    let appAccess = "";
    let authMsg = "";
    let isAuthenticated = false;
    let userName = this.state.user.userName;
    const hostname = window.location.hostname;
    const initSource = getUrlParameter("appSource");
    // Note: set Default appSource as "IND"
    const appSource = !initSource ? DEFAULT_APPSOURCE : initSource;
    if (hostname === "localhost") {
      userName = getUrlParameter("userName");
    }

    if (!appSource) {
      appAccess = DENIED;
      authMsg = xlate("sq.errors.no_access_msg");
    } else {
      const sourceExist = checkSourceCode(appSource.toUpperCase());
      if (sourceExist) {
        // check user authenticated and callback dealer settings api
        if (!isEmptyObject(userName)) {
          this.authenticate(appSource);
        } else {
          isAuthenticated = false;
          appAccess = NOT_AUTHORIZED;
          authMsg = xlate("sq.errors.no_access_msg");
        }
      } else {
        appAccess = DENIED;
        authMsg = xlate("sq.errors.no_access_msg");
      }
    }
    sleep(0).then(() => {
      this.setState({ appAccess, authMsg, isAuthenticated });
    });
  };

  // Handler to call on window resize
  handleResize = () => {
    const windowSize = {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    };
    this.setWindowSize(windowSize);
  };

  useWindowSize = () => {
    const { windowSize } = this.state;
    return windowSize;
  };

  // Update Application state here
  setWindowSize = windowSize => {
    this.setState({
      windowSize
    });
  };
  setLocation = location => {
    this.setState({
      location
    });
  };
  // This way any UserContext.Consumer could access the setter to change the user.
  setUser = user => {
    this.setState({ user, isAuthenticated: true });
  };
  setUserPermissions = userPermissions => {
    this.setState({ userPermissions });
  };
  setDealer = dealer => {
    this.processLocales(dealer);
    this.setState({
      dealer,
      dealerCode: dealer.dealerCode,
      schemaName: dealer.schemaName
    });
    localStorage.setItem("dealerCode", dealer.dealerCode);
    logApplicationStartup(this.state);
  };
  setScopedDealers = scopedDealers => {
    this.setState(() => {
      return {
        scopedDealers
      };
    });
  };
  setWebKey = webKey => {
    this.setState({ webKey });
  };
  setLoginURL = loginURL => {
    this.setState({ loginURL });
  };
  copyDebugInfo = () => {
    const roNumber =
      this.state.roNumber ??
      /(?<=roNumber=)([^&]+)/.exec(window.location.href)?.[0];
    const debugInfo = JSON.stringify(
      {
        url: window.location.href,
        utcTime: moment().utc().format("YYYY-MM-DD ddd HH:mm:ss.SSS"),
        dealerCode: this.state.dealerCode,
        confirmationId: this.state.quoteId ?? null,
        roNumber,
        dealerProperties: this.state.dealerProperties,
        user: {
          userId: this.state.user.userId,
          quoteUserId: this.state.user.quoteUserId,
          username: this.state.user.userName
        },
        userPermissions: this.state.userPermissions
      },
      null,
      2
    );
    this.setState({ debugInfo });
    navigator.clipboard?.writeText(debugInfo);
    toast.info("Debug info copied to clipboard.", {
      autoClose: 2000,
      position: "top-left",
      closeOnClick: true
    });
  };

  processLocales(dealer) {
    let localeList =
      dealer.locale && dealer.locale.length !== 0 ? dealer.locale[0] : [];
    if (!Array.isArray(localeList)) {
      localeList = [localeList];
    }
    // add "locale" to localeStorage
    setLocale(this.state.locale);
  }

  // Step-2 - This API call returns User object and quoting permissions
  authenticate = appSource => {
    let appAccess = "";
    let authMsg = "";
    let isAuthenticated = false;
    let userName = this.state.user.userName;
    const hostname = window.location.hostname;
    /* Note: For Local Dev, Pass query param "userName" in window.location */
    if (hostname.indexOf("localhost") !== -1) {
      userName = getUrlParameter("userName");
    }
    if (!isEmptyObject(userName)) {
      const restUrl = "/user/userdetails/" + userName;
      makeSecureRestApi(
        {
          url: restUrl,
          method: "get",
          data: {}
        },
        response => {
          if (response) {
            if (!isArrayExist(response) && !isEmptyObject(response)) {
              if (!isEmptyObject(response.data)) {
                const user = response.data;
                user.userId = user.id;
                user.userName = user.username;
                localStorage.setItem("userId", user.id);
                localStorage.setItem("username", user.username);
                this.setUser(user);
                const permissions = buildUserPermissions(
                  user,
                  this.state.appType
                );
                this.setUserPermissions(permissions);
                const userIsPartsOnly =
                  permissions.canPerformPartsActions &&
                  !permissions.canPerformServiceManagerActions &&
                  !permissions.canPerformServiceAdvisorActions &&
                  !permissions.canPerformTechnicianActions;
                const isPartsView =
                  userIsPartsOnly ||
                  (permissions.canPerformPartsActions &&
                    this.state.baseParams.view === "PARTS");
                this.setState({ isPartsView });
                let proceedQuotingAccess = true;
                // DENIED Page - if user doesn't have either create/edit quote permissions cases
                // First check - user has service quoting permission
                // Second check - user should have create quote or edit quote permissions
                // parts person - user will have edit quote permission only
                if (
                  ["IND", "SCH", "ENG"].includes(appSource) &&
                  permissions.canAccessServiceQuoting
                ) {
                  if (permissions.canCreateQuote || permissions.canEditQuote) {
                    proceedQuotingAccess = true;
                  } else {
                    // DENIED - if user doesn't have either create/edit quote permissions cases
                    proceedQuotingAccess = false;
                  }
                } else {
                  // DENIED
                  proceedQuotingAccess = false;
                }
                if (proceedQuotingAccess) {
                  this.loadScopedDealers();
                  if (hostname.indexOf("localhost") !== -1) {
                    // DO NOTHING in case of localhost
                  }
                } else {
                  isAuthenticated = false;
                  appAccess = DENIED;
                  authMsg = xlate("sq.errors.no_access_msg");
                  this.setState({
                    appAccess,
                    authMsg,
                    isAuthenticated
                  });
                }
              } else {
                const config = {
                  method: "get",
                  url: restUrl,
                  statusCode: 200
                };
                gtmEvent.trackGAUnknowError(response, config);
              }
            }
          }
        },
        error => {
          console.error("authentication error", error);
          this.noAccessAction();
          this.setState(() => {
            return {
              isAuthenticated: false,
              appAccess: NOT_AUTHORIZED,
              authMsg: xlate("sq.errors.auth_error_msg")
            };
          });
        },
        "Unable to log you in."
      );
    }
  };
  appAccessCallback = () => {
    let appAccess = "";
    const authMsg = "";
    const isAuthenticated = true;
    const webKey = getUrlParameter("webKey");
    if (!webKey) {
      appAccess = ACCESS_DEALER_PAGE;
      this.setState({
        appAccess,
        authMsg,
        isAuthenticated
      });
    } else {
      appAccess = FULL_ACCESS;
      this.loadDealerSettings(isAuthenticated, appAccess, authMsg);
    }
  };
  // Step-4 - This API call returns dealer settings object using webKey
  loadDealerSettings = (isAuthenticated, appAccess, authMsg) => {
    const webKey = getUrlParameter("webKey");
    const env = getUrlParameter("appEnv");
    makeSecureRestApi(
      {
        url: "/dealer/settings/" + webKey,
        method: "get",
        data: {}
      },
      data => {
        if (data && data.dealer) {
          this.setDealer(data.dealer);
          this.getQuotingEnabled(isAuthenticated, appAccess, authMsg);
          // @note: load synonyms at app level
          this.getSynonyms();
          // call t_setting table data
          this.getSettingMasterData();
          // @note: First time - initialize GTM dataLayer when user, dealer objects are ready
          this.initGTagDataLayer();
          // @note: Initialize Pendo
          getBridgeV2Configs(env, this.initializePendo.bind(this));
          // @note: check dealerTire property always
          this.getDealerTireEnabled();
          // Retrieve global propery for createPoEnabled
          this.getCreatePoEnabledProperty();
          // check if special order parts are enabled
          if (this.state.appType === "CSR") {
            this.getCreateSpecialPartsEnabledProperty();
            this.getSupersededPartsEnabledProperty();
            this.getVoidROEnabledProperty();
          }
          // Retrieve dealer properties object and store in appContext for discounts/fees/sublet feature
          this.getDealerProperties();
          // Retrieve dealer companies list and store in appContext
          this.getDealerCompanies();
        } else {
          this.noAccessAction();
        }
      },
      () => {
        // if webKey is invalid
        this.noAccessAction();
      },
      "Unable to retrieve dealer settings."
    );
  };
  // Step-5 - read dealer property for Service Quoting and redirect to access page
  getQuotingEnabled(isAuthenticated, appAccess, authMsg) {
    const { dealerCode, localeStrings } = this.state;
    makeSecureRestApi(
      {
        url: `/dealer/dealerproperty/${dealerCode}/property/SERVICE_QUOTING_APP_ENABLED`,
        method: "get",
        data: {}
      },
      response => {
        const isQuotingEnabled = response.data === "Y";
        // When dealer property missing, show DENIED page
        if (!isQuotingEnabled) {
          this.setState({
            isQuotingEnabled,
            isAuthenticated: false,
            appAccess: DENIED,
            authMsg: xlate("sq.errors.no_access_msg")
          });
        } else {
          this.setState({
            isQuotingEnabled,
            isAuthenticated,
            appAccess,
            authMsg
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.read_service_quoting_enabled_property"];
        toast.error(msg, {
          closeOnClick: true
        });
        this.setState({
          isAuthenticated: false,
          appAccess: NOT_AUTHORIZED,
          authMsg: xlate("sq.errors.no_access_msg")
        });
      }
    );
  }
  // Step-6
  getSynonyms = async () => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const { items: synonyms } = await appServices.loadSynonyms(
        dealerCode,
        localeStrings
      );
      this.setState({
        synonymsList: synonyms
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.api_failure_msg"];
      console.error(msg);
    }
  };
  // @note: App level, load MF codes using dealercode, applicable to DTDMS, DMS+, CDK
  getDealerMFCodes = async dealerProperties => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const dealerMFcodes = await mfcodeService.readManufacturerCodesByDealer(
        dealerProperties,
        dealerCode
      );
      this.storeDealerMFcodesIntoWebstorage(dealerMFcodes);
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.api_failure_msg"];
      console.error(msg);
    }
  };
  // save dealer MF codes in seesion storage for CDk, DTDMS dealers
  storeDealerMFcodesIntoWebstorage = (dealerMFcodes = {}) => {
    sessionStorage.setItem("dealerMFcodes", JSON.stringify(dealerMFcodes));
  };
  // read t_setting table data in App level; store it in App.context
  getSettingMasterData = async () => {
    const { localeStrings } = this.state;
    try {
      const settingData = await appServices.loadSettingEnumForQuoting();
      this.setState({
        quoteSetting: settingData
      });
      this.getQuoteDealerSettings(settingData);
    } catch (error) {
      // @todo - change add custom local string for dealer settings
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.api_failure_msg"];
      console.error(msg);
    }
  };
  // read t_dealer_setting table data
  getQuoteDealerSettings = async settingData => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const rawDealerSettings = await appServices.loadQuoteDealerSettings(
        dealerCode
      );
      // FIX - Pass t_settings table data to find record matching {setting_id} of t_settings with in {setting_id} of dealer_setting
      const dealerSettingsObject = prepareDealerSettingsObject(
        rawDealerSettings,
        settingData
      );
      this.setState({
        dealerSettings: dealerSettingsObject
      });
    } catch (error) {
      // @todo - change add custom local string for dealer settings
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.api_failure_msg"];
      console.error(msg);
    }
  };
  // @todo: this method should be used to update dealerSettings in app state
  updateDealerSettings = dealerSettingsUpdates => {
    const newDealerSettingsObject = updateDealerSettingsObject(
      this.state.dealerSettings,
      dealerSettingsUpdates
    );
    this.setState({ dealerSettings: newDealerSettingsObject });
  };
  updateAssistanceStatus = ({ type, requestStatus, confirmationId, state }) => {
    switch (type) {
      case "service":
        this.setState({
          serviceAssistanceStatus: { requestStatus, confirmationId, state }
        });
        break;
      case "parts":
        this.setState({
          partsAssistanceStatus: { requestStatus, confirmationId, state }
        });
        break;
    }
  };
  handleAssistanceRequestBanner = (type, state) => {
    switch (type) {
      case "service":
        this.setState({
          showServiceAssistanceRequestBanner: state
        });
        break;
      case "parts":
        this.setState({
          showPartsAssistanceRequestBanner: state
        });
        break;
      case "requested_fail":
        this.setState({
          showAttentionRequestFailBanner: state
        });
        break;
      case "completed_fail":
        this.setState({
          showResolvingRequestFailBanner: state
        });
        break;
    }
  };
  handleSendQuoteToCustommerBanner = showSendQuoteToCustomerBanner => {
    this.setState({
      showSendQuoteToCustomerBanner
    });
  };
  // Step-8: verify DealerTire property to launch Quick filter with Tires
  getDealerTireEnabled = async () => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const dealerTireEnabled = await appServices.isDealerTireEnabled(
        dealerCode
      );
      this.setState({
        isDealerTireEnabled: dealerTireEnabled
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.read_service_quoting_enabled_property"];
      console.error(msg);
    }
  };

  getCreatePoEnabledProperty = async () => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const createPoEnabled = await appServices.getCreatePoEnabled(dealerCode);
      this.setState({
        isCreatePoEnabled: createPoEnabled
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.read_create_po_enabled_property"];
      console.error(msg);
    }
  };

  getCreateSpecialPartsEnabledProperty = async () => {
    const { localeStrings } = this.state;
    try {
      const createSpecialPartsEnabled =
        await appServices.getCreateSpecialPartsEnabled();
      this.setState({
        isCreateSpecialPartsEnabled: createSpecialPartsEnabled
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.get_dealer_settings"];
      console.error(msg);
    }
  };

  getSupersededPartsEnabledProperty = async () => {
    const { localeStrings } = this.state;
    try {
      const supersededPartsEnabled =
        await appServices.getSupersededPartsEnabled();
      this.setState({
        isSupersededPartsEnabled: supersededPartsEnabled
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.get_dealer_settings"];
      console.error(msg);
    }
  };

  /**
   * Retrieves the global flag for Void RO and updates the app context with it.
   * @returns {Promise<void>}
   */
  getVoidROEnabledProperty = async () => {
    const { localeStrings } = this.state;
    try {
      const voidROEnabled = await appServices.getVoidROEnabled();
      this.setState({
        isVoidROEnabled: voidROEnabled
      });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.get_dealer_settings"];
      console.error(msg);
    }
  };

  getDealerProperties = async () => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const dealerProperties = await appServices.loadDealerProperties(
        dealerCode
      );
      this.setState(
        {
          dealerProperties: { ...dealerProperties.properties }
        },
        () => {
          // Dealer properties into session storage to avoid prop drilling while calling getPartsPricingAndInventory API
          this.storeDealerPropertiesIntoWebstorage(dealerProperties.properties);
          this.getDealerMFCodes(dealerProperties.properties);
        }
      );
      this.setState({ timeZone: dealerProperties?.timeZone });
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.get_dealer_properties"];
      console.error(msg);
    }
  };

  storeDealerPropertiesIntoWebstorage = (properties = {}) => {
    sessionStorage.setItem("dealerProperties", JSON.stringify(properties));
  };

  getDealerCompanies = async () => {
    const { dealerCode, localeStrings } = this.state;
    try {
      const response = await appServices.loadDealerCompanies(dealerCode);
      const dealerCompaniesData = response.data;
      this.setState({
        dealerCompanies: dealerCompaniesData.map(x => x.name)
      });
      console.log("dealer companies", dealerCompaniesData);
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.get_dealer_companies"];
      console.error(msg);
    }
  };
  handleSessioinExpired = () => {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      // session was authorized before
      setTimeout(() => {
        this.setState({ showSessionExpired: true });
      }, 500);
    } else {
      // session was never authorized
      const queryStr = window.location.search;
      redirectToDefaultPage(queryStr);
    }
  };
  // This method is called when the user opens or closes eitehr the new or the past quote slider.
  // It is passed through appContext and sets local state to track the status of the slider.
  handleSliderStatus = isOpen => {
    this.setState({ sliderIsOpen: isOpen });
  };

  /**
   *
   * Initialize GTM dataLayer first time with unique account/application details at index.html
   * Push event data as productEvent, userTimings, pageviews to GTM dataLayer
   */
  // Step-7
  initGTagDataLayer() {
    const { user, dealer, locale, appType, userPermissions, appSource } =
      this.state;
    const applicationName =
      appType === "CSR" ? "ServiceRepairOrder" : "ServiceQuoting";
    let isReadOnly = false; // false - means user has full access permission
    const appEnvironment = gtag.getEnvironment();
    let trainingRole = UNDEFINED_VALUE;
    let userDomain = UNDEFINED_VALUE;
    let dmsType = UNDEFINED_VALUE;
    let schemaName = UNDEFINED_VALUE;
    const webKeyVal = getUrlParameter("webKey");
    // const buildVersion = getMetaTagContentByName("xtime:gitBranch").concat(
    //   " - ",
    //   getMetaTagContentByName("xtime:buildNumber")
    const buildVersion = getMetaTagContentByName("xtime:buildNumber");
    let dealerCountry = UNDEFINED_VALUE;
    // Only for usernames used for Automation, push trainingRole as "AUTOMATION" ( to differ from other real-time users)
    if (Object.keys(user).length > 0) {
      if (user.userName.toLowerCase().includes("automation")) {
        trainingRole = "AUTOMATION";
      }
      /* Push GA dimension "trainingRole" to differnciate dealers from QA automation / xtime support team will be 'XTEMPLOYEE' */
      if (has(user, "trainingRole") && !isNull(user.trainingRole)) {
        trainingRole = user.trainingRole;
      }
      // Push GA dimension userDomain eg: "xtime.com"
      if (has(user, "email") && !isNull(user.email)) {
        let email = user.email.toString();
        email = email.split("@");
        userDomain = email[1];
      }
    }
    // check for userPermissions{}; Push readonly as false if all permissions are true
    if (!isEmpty(userPermissions)) {
      const valuesArr = Object.values(userPermissions);
      const totalPermissions = valuesArr.length;
      const iterator = valuesArr.values();
      let count = 0;
      for (const value of iterator) {
        if (typeof value === "boolean" && value === true) {
          count++;
        }
      }
      isReadOnly = count > 0 && totalPermissions === count ? false : true;
      // console.log(userPermissions, totalPermissions, count, isReadOnly);
    }
    // check for dealer object to update GTM
    if (Object.keys(dealer).length > 0) {
      dmsType = !dealer.dmsType ? UNDEFINED_VALUE : dealer.dmsType;
      schemaName = !dealer.schemaName
        ? UNDEFINED_VALUE
        : dealer.schemaName.toLowerCase();
      dealerCountry = !dealer.dealerCountryCode
        ? UNDEFINED_VALUE
        : dealer.dealerCountryCode;
    }
    // Cox related common details
    const dataLayerObj = {
      common: {
        dataLayerVersion: 1,
        user: {
          bridgeUser: UNDEFINED_VALUE,
          applicationUser: user.username,
          userType: trainingRole,
          isInternalUser: true
        },
        application: {
          businessUnitName: "Xtime",
          name: applicationName,
          version: !buildVersion ? UNDEFINED_VALUE : buildVersion,
          environment: appEnvironment,
          isProduction: appEnvironment === "production" ? true : false
        },
        context: {
          dealershipId: !dealer.dealerCode
            ? UNDEFINED_VALUE
            : dealer.dealerCode,
          dealershipName: !dealer.dealerName
            ? UNDEFINED_VALUE
            : dealer.dealerName
        }
      },
      // Push application values like userId, role, enviornment, webKey to dataLayer for GA
      appName: applicationName,
      appVersion: !buildVersion ? UNDEFINED_VALUE : buildVersion,
      appSource,
      environment: appEnvironment,
      user: user.userId,
      dealerCode: !dealer.dealerCode ? UNDEFINED_VALUE : dealer.dealerCode,
      dmsType,
      schemaName,
      trainingRole,
      userDomain,
      readOnly: isReadOnly,
      webKey: !webKeyVal ? UNDEFINED_VALUE : webKeyVal,
      dealerCountry,
      locale
    };
    gtag.pushGTagAppDetails(dataLayerObj);
    // TODO: if wanted, track  launch hub from parent app
    // gtmEvent.trackLaunchHub(this.state.appSource, applicationName);
  }

  initializePendo() {
    const { user, dealer, locale, appType, userPermissions, appSource } =
      this.state;
    let trainingRole = UNDEFINED_VALUE;
    let email = UNDEFINED_VALUE;

    if (Object.keys(user).length > 0) {
      if (user.userName.toLowerCase().includes("automation")) {
        trainingRole = "AUTOMATION";
      }
      if (has(user, "trainingRole") && !isNull(user.trainingRole)) {
        trainingRole = user.trainingRole;
      }
      if (has(user, "email") && !isNull(user.email)) {
        email = user.email.toString();
      }
    }

    // Install Pendo
    pendo.initialize({
      visitor: {
        id: localStorage.getItem("coxBridgeUserId"),
        email: email,
        full_name: user.userName,
        xtm_role: trainingRole
      },
      account: {
        id: "xtm_" + (!dealer.dealerCode ? UNDEFINED_VALUE : dealer.dealerCode),
        name: !dealer.dealerName ? UNDEFINED_VALUE : dealer.dealerName
      }
    });
  }

  // TODO: check if we want to display unsupported browser msg
  checkBrowser() {
    const browserName = detectBrowser();
    let access = true;
    if (browserName === "Microsoft") {
      access = false;
    }
    return access;
  }
  //  TODO: scroll to element within container - working
  // @note - scrolltop event feature disabled
  // scrollTopAction = event => {
  //   const topElement = document.querySelector("#sq-container-top");
  //   topElement.scrollIntoView({
  //     behavior: "auto",
  //     block: "end"
  //   });
  //   this.setState({ isScrolling: false });
  // };

  // TODO: Step3: Update AppContext with scoped Dealers and access context in dealer selector (shared component)
  loadScopedDealers = () => {
    // Is there a React-y way to avoid rebinding `this`? fat arrow?
    const th = this;
    const { user } = this.state;
    const restUrl = "/user/scopeddealers/" + user.userId;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        if (response) {
          if (!isArrayExist(response) && !isEmptyObject(response)) {
            if (!isEmptyObject(response.scopedDealers)) {
              const scopedDealers = response.scopedDealers;
              scopedDealers.sort(this.sortingDealers);
              th.setScopedDealers(scopedDealers);
            } else {
              const config = {
                method: "get",
                url: restUrl,
                statusCode: 200
              };
              gtmEvent.trackGAUnknowError(response, config);
            }
            this.appAccessCallback();
          }
        }
      },
      error => {
        console.error("Error to fetch scoped-dealers", error);
        this.setState(() => {
          return {
            isAuthenticated: false,
            appAccess: NOT_AUTHORIZED,
            authMsg: xlate("sq.errors.no_access_msg")
          };
        });
      },
      "Unable to retrieve list of available dealers."
    );
  };
  // TODO: Always check if a and b are valid objects
  sortingDealers(a, b) {
    if (!isEmptyObject(a) && !isEmptyObject(b)) {
      if (!isEmptyString(a.dealerName) && !isEmptyString(b.dealerName)) {
        if (a.dealerName < b.dealerName) {
          return -1;
        }
        if (a.dealerName > b.dealerName) {
          return 1;
        }
      }
    }
    return 0;
  }
  // @note: dispatch event to refresh pastquotes when new quote is added
  reloadPastQuotes = quoteType => {
    if (quoteType === "NEWQUOTE" && window.location.pathname === "/dashboard") {
      window.dispatchEvent(
        new CustomEvent("refreshPastQuotes", {
          detail: { reload: true },
          bubbles: true,
          cancelable: true
        })
      );
    }
  };
  // @note: this handler called from pastQuotes grid cellclick to update quote params in app context
  updateQuoteParams = quoteParams => {
    const {
      customerId,
      vehicleId,
      quoteId,
      repairOrder,
      sourceSystem,
      roNumber
    } = quoteParams;
    const accessType = !quoteId ? null : "EDIT";
    this.setState({
      customerId,
      vehicleId,
      accessType,
      quoteId,
      repairOrder,
      sourceSystem,
      roNumber
    });
  };
  render() {
    const {
      isAuthenticated,
      locale,
      localeStrings,
      device,
      windowSize,
      location,
      webKey,
      loginURL,
      appEnv,
      appSource,
      schemaName,
      user,
      userPermissions,
      dealer,
      scopedDealers,
      isQuotingEnabled,
      isDealerTireEnabled,
      isCreatePoEnabled,
      isCreateSpecialPartsEnabled,
      isSupersededPartsEnabled,
      isVoidROEnabled,
      pastQuotes,
      synonymsList,
      quoteSetting,
      dealerSettings,
      dealerProperties,
      dealerCompanies,
      serviceAssistanceStatus,
      partsAssistanceStatus,
      showServiceAssistanceRequestBanner,
      showPartsAssistanceRequestBanner,
      showAttentionRequestFailBanner,
      showResolvingRequestFailBanner,
      showSendQuoteToCustomerBanner,
      customerId,
      vehicleId,
      quoteId,
      accessType,
      authMsg,
      appAccess,
      isDebug,
      appType,
      isPartsView, // @csr-logic
      text2way,
      sliderIsOpen,
      repairOrder,
      sourceSystem,
      roNumber,
      timeZone
    } = this.state;
    const quote = {
      accessType,
      customerId,
      vehicleId,
      quoteId,
      repairOrder,
      sourceSystem,
      roNumber
    };

    /* set localstate to Global context here */
    const contextValue = {
      isAuthenticated,
      locale,
      localeStrings,
      device,
      windowSize,
      location,
      webKey,
      loginURL,
      appEnv,
      appSource,
      schemaName,
      isQuotingEnabled,
      isDealerTireEnabled,
      isCreatePoEnabled,
      isCreateSpecialPartsEnabled,
      isSupersededPartsEnabled,
      isVoidROEnabled,
      pastQuotes,
      synonymsList,
      quoteSetting,
      dealerSettings,
      dealerProperties,
      dealerCompanies,
      serviceAssistanceStatus,
      partsAssistanceStatus,
      showServiceAssistanceRequestBanner,
      showPartsAssistanceRequestBanner,
      showAttentionRequestFailBanner,
      showResolvingRequestFailBanner,
      showSendQuoteToCustomerBanner,
      quote,
      user,
      userPermissions,
      dealer,
      scopedDealers,
      setWebKey: this.setWebKey,
      setUserPermissions: this.setUserPermissions,
      setDealer: this.setDealer,
      setLoginURL: this.setLoginURL,
      setScopedDealers: this.setScopedDealers,
      reloadPastQuotes: this.reloadPastQuotes,
      updateQuoteParams: this.updateQuoteParams,
      updateDealerSettings: this.updateDealerSettings,
      getQuoteDealerSettings: this.getQuoteDealerSettings,
      updateAssistanceStatus: this.updateAssistanceStatus,
      handleAssistanceRequestBanner: this.handleAssistanceRequestBanner,
      handleSendQuoteToCustommerBanner: this.handleSendQuoteToCustommerBanner,
      handleSliderStatus: this.handleSliderStatus,
      appAccess,
      isDebug,
      appType,
      isPartsView, // @csr-logic
      text2way,
      sliderIsOpen,
      sourceSystem,
      roNumber,
      timeZone
    };
    const hasRole = true;
    const accessDenied =
      appAccess === DENIED ||
      (appAccess === NOT_AUTHORIZED && !isAuthenticated);
    const showQuotingApp = appAccess === FULL_ACCESS && isAuthenticated;
    const showDealerSelector =
      appAccess === ACCESS_DEALER_PAGE && isAuthenticated;

    let content = (
      <div style={{ margin: 25 }}>
        <LoadingIndicator htmlId="LoadingMask" size="large" />
      </div>
    );
    // Note: Render pages based on appAccess
    if (accessDenied) {
      const appTitle =
        appType === "CSR"
          ? xlate("sq.common.app_name_csr")
          : xlate("sq.common.app_name");
      content = <NoAccess title={appTitle} message={authMsg} />;
    } else if (showDealerSelector) {
      content = (
        <div id="sq-wrapper">
          <div className="dealer-outer-box ">
            <DealerList />
          </div>
        </div>
      );
    } else if (showQuotingApp && hasRole) {
      // @csr-logic
      const csrPage = redirectToCSRPage(appSource, appType, quote);
      const redirectPage = !csrPage ? "/dashboard" : csrPage;
      let landingPage = appSource === "IND" ? redirectPage : "/newquote";
      // External app case: Launch "Newquote" page of Quoting UI from external apps {Scheduling, Engage, Inspect}
      if (appSource !== "IND") {
        // landingPage = "/newquote";
        landingPage = appType === "CSR" ? "/rodetails" : "/newquote";
      }
      // @csr-logic
      const isStandalone = checkIfStandaloneApp(appSource);
      const isCSR = checkIfIsCSR(appType);
      const headerPage =
        isStandalone || isDebug ? <Header user={user} appType={appType} /> : "";
      const navPage = isStandalone ? (
        <div className="hide">
          <Nav links={routes} />
        </div>
      ) : (
        ""
      );
      const clsHeader =
        (isStandalone && !isCSR) || isDebug ? "sq-header-wrapper" : "hide";
      const isNonProd =
        document.location.hostname === "localhost" ||
        document.location.hostname.includes("xtimeappsnp.xtime.com");

      content = (
        <div
          id="sq-wrapper"
          className={`${isCSR ? "csr-app" : ""} ${
            isPartsView ? "parts-view" : ""
          }`}
        >
          <div id={clsHeader}>
            {headerPage}
            {navPage}
          </div>
          <div
            className={
              isStandalone
                ? `sq-page-wrapper ${isCSR ? "top-0" : "top-40"}`
                : "sq-page-wrapper page-center"
            }
          >
            <span id="sq-container-top" />
            {isDebug ? <ErrorNotification /> : null}
            <Switch>
              <Route path="/" exact={true}>
                <Redirect
                  from="/"
                  to={{
                    pathname: landingPage,
                    search: window.location.search
                  }}
                />
              </Route>
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  // component={route.main} // Route with component
                  // pass props through Route render props
                  render={routeProps => (
                    <route.main {...routeProps} title="Props through render" />
                  )}
                />
              ))}

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>

            <footer className="scroll-footer">
              {/* {isScrollButton && (
                  <button
                    className={scrollStyle}
                    onClick={this.scrollTopAction}
                  >
                    <i className="fa fa-arrow-up" aria-hidden="true" />
                  </button>
                )} */}
            </footer>
          </div>
          {isNonProd ? (
            <div className="admin-container">
              <div className="buttons">
                <Button
                  htmlId="debugInfoButton"
                  buttonStyle="primary"
                  onClick={this.copyDebugInfo}
                >
                  Copy debug info
                </Button>
              </div>
              <div className="content">
                <TextArea
                  htmlId="debugInfoTextbox"
                  name="debugInfoTextbox"
                  onChange={cxEvent => {
                    this.setState({
                      debugInfo: cxEvent.target.value
                    });
                  }}
                  value={this.state.debugInfo ?? ""}
                  disabled={true}
                  label=""
                />
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <APIErrorProvider>
        <ErrorProvider isDebug={isDebug}>
          <LanguageProvider
            locale={locale.replace("_", "-")} // "en-US"
            key={locale.replace("_", "-")}
            messages={localeStrings}
          >
            <AppProvider value={contextValue}>
              <NewQuoteProvider>
                <ToastDefault />
                {content}
              </NewQuoteProvider>
            </AppProvider>
          </LanguageProvider>
        </ErrorProvider>
      </APIErrorProvider>
    );
  }
}

export default withRouter(App);

App.defaultProps = {
  appSource: "IND",
  appType: null,
  locale: "en_US",
  userName: "",
  webKey: "",
  loginURL: ""
};
