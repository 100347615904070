import cloneDeep from "lodash/cloneDeep";
import { generatePartId } from "../../../utils/helper.util";
import isEmpty from "lodash/isEmpty";

export const commitSplit = (
  commitResponse,
  quoteSummaryData,
  quoteServiceId,
  currentEditingPartData,
  selectedServiceMenuId
) => {
  const quoteSummaryCopy = cloneDeep(quoteSummaryData);
  if (commitResponse?.repairOrderParts?.length === 1) {
    const partData = commitResponse.repairOrderParts[0];
    quoteSummaryCopy.quoteServices?.forEach(service => {
      if (service.quoteServiceId === quoteServiceId) {
        if (isEmpty(service?.menuServices)) {
          service.parts?.forEach(p => {
            if (p.roPartNum === currentEditingPartData.roPartNum) {
              p.roPartNum = partData?.outputPartSequenceNumber;
              p.quantity = partData?.outputQuantity;
              p.adjustedQuantity = partData?.outputQuantity;
              p.location = partData?.outputLocation;
            }
          });
        } else {
          service.menuServices?.forEach(menu => {
            if (menu.serviceMenuId === selectedServiceMenuId) {
              menu.parts?.forEach(p => {
                if (p.roPartNum === currentEditingPartData.roPartNum) {
                  p.roPartNum = partData?.outputPartSequenceNumber;
                  p.quantity = partData?.outputQuantity;
                  p.adjustedQuantity = partData?.outputQuantity;
                  p.location = partData?.outputLocation;
                }
              });
            }
          });
        }
      }
    });
  } else {
    // split
    commitResponse?.repairOrderParts?.forEach(partData => {
      if (
        partData.inputPartSequenceNumber === partData.outputPartSequenceNumber
      ) {
        quoteSummaryCopy.quoteServices?.forEach(service => {
          if (service.quoteServiceId === quoteServiceId) {
            if (isEmpty(service?.menuServices)) {
              service.parts?.forEach(p => {
                if (p.roPartNum === currentEditingPartData.roPartNum) {
                  p.quantity = partData?.outputQuantity;
                  p.adjustedQuantity = partData?.outputQuantity;
                  p.location = partData?.outputLocation;
                }
              });
            } else {
              service.menuServices.forEach(menu => {
                if (menu.serviceMenuId === selectedServiceMenuId) {
                  menu.parts?.forEach(p => {
                    if (p.roPartNum === currentEditingPartData.roPartNum) {
                      p.quantity = partData?.outputQuantity;
                      p.adjustedQuantity = partData?.outputQuantity;
                      p.location = partData?.outputLocation;
                    }
                  });
                }
              });
            }
          }
        });
      } else {
        const keyId = generatePartId();
        const newLine = {
          ...cloneDeep(currentEditingPartData),
          quantity: partData?.outputQuantity,
          adjustedQuantity: partData?.outputQuantity,
          roPartNum: partData?.outputPartSequenceNumber,
          location: partData?.outputLocation,
          rowId: keyId,
          partId: keyId.toString(),
          extPartId: keyId,
          purchaseType: null,
          quoteServicePartId: null
        };

        quoteSummaryCopy.quoteServices?.forEach(service => {
          if (service.quoteServiceId === quoteServiceId) {
            if (isEmpty(service?.menuServices)) {
              service.parts.push(newLine);
            } else {
              service.menuServices?.forEach(menu => {
                if (menu.serviceMenuId === selectedServiceMenuId) {
                  menu.parts?.push(newLine);
                }
              });
            }
          }
        });
      }
    });
  }

  quoteSummaryCopy.serviceOrderId = commitResponse.serviceOrderId;

  return quoteSummaryCopy;
};
