/* eslint-disable */
/**
 * @note !important: Don't add new actions to update final, calculated and overrided price fields
 */
import React from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { get, isNumber, has, isEmpty } from "lodash";
import { CONFIG } from "./edit-service.config";
import { convertMinutesToHours } from "../utils/time";
import { convertArraytoString } from "../utils/format.util";
import { freezeObject } from "../utils/service.util";
import { catalogSources } from "../utils/edit-service.constants";
import { appType } from "../../api/xmmAxios";
import { EditServiceTemplate } from "../utils/edit-service.constants";
import priceCalculationUtil from "../utils/service-calculation.util";
import { priceSourceLabels } from "../../constants/pages.constants";
import {
  buildPart,
  transformParts,
  transformPartsWithDMSParts
} from "../../utils/common-parts.util";
const errors = {
  vehicleAttributes: false,
  serviceOptions: false,
  serviceNotes: false,
  opCode: false,
  laborTime: false,
  laborPrice: false,
  parts: false,
  partsPrice: false,
  payTypes: false,
  serviceTypes: false,
  vendorList: false
};

const changed = {
  laborApp: false,
  serviceNotes: false,
  opCode: false,
  laborTime: false,
  laborPrice: false,
  laborRateOverride: false,
  laborRate: false,
  parts: false,
  partsPrice: false,
  // * @csr-specific fields
  catalogLabor: false,
  warranty: false,
  complaint: false,
  correction: false,
  cause: false,
  serviceLineCheckBoxes: false,
  technician: false
};
// @note: same template used in this edit-service context and service-data-mapper (editModuleProps..() method return same object)
const defaultService = cloneDeep(EditServiceTemplate);

let defaultState = {
  debugMode: false,
  appType: null,
  userPermissions: {},
  payTypes: [],
  serviceTypes: [],
  vendorList: [],
  payTypeSubTypes: [],
  costAllocationTypes: [],
  internalAccount: "",
  technicians: [],
  warrantyPlans: null,
  expenseCodeList: {},
  commonConsumerId: "",
  rawOperationDetails: {},
  originalService: defaultService,
  service: defaultService,
  serviceType: "",
  skillLevel: "",
  settings: CONFIG,
  // @todo this should be included only on the for GlobalRepair Services payload
  vehicleAttributes: {},
  laborApp: {},
  completedSteps: {
    vehicleAttr: false,
    serviceOptions: false,
    details: false
  },
  filteredParts: [],
  // @todo-poc: This prop used for PartsLookup module to render recommended parts as master list
  lookupParts: [],
  errors,
  changed,
  username: "",
  vehicle: null,
  partsPricingAndInventory: null,
  currentEditingService: null
};

defaultState = freezeObject(defaultState);

const EditServiceContext = React.createContext(defaultState);

const Actions = {
  SET_APP_TYPE: "SET_APP_TYPE",
  SET_EDIT_SETTINGS: "SET_EDIT_SETTINGS",
  SET_DEBUG_MODE: "SET_DEBUG_MODE",
  SET_USERNAME: "SET_USERNAME",
  SET_LOGGED_IN_USER: "SET_LOGGED_IN_USER",

  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_RAWOPERATION_DETAILS: "SET_RAWOPERATION_DETAILS",
  SET_ORIGINAL_SERVICE: "SET_ORIGINAL_SERVICE",
  SET_SERVICE: "SET_SERVICE",
  MODIFY_SERVICE: "MODIFY_SERVICE",
  SET_PAY_TYPES: "SET_PAY_TYPES",
  SET_SERVICE_TYPES: "SET_SERVICE_TYPES",
  SET_VENDOR_LIST: "SET_VENDOR_LIST",
  SET_PAY_TYPE_SUB_TYPES: "SET_PAY_TYPE_SUB_TYPES",
  SET_PAY_TYPE_COST_ALLOCATION_TYPES: "SET_PAY_TYPE_COST_ALLOCATION_TYPES",
  SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE:
    "SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE",
  SET_TECHNICIAN_LIST: "SET_TECHNICIAN_LIST",
  SET_VEHICLE: "SET_VEHICLE",
  SET_SERVICE_TYPE: "SET_SERVICE_TYPE",
  SET_LABOR_TIME: "SET_LABOR_TIME",
  SET_LABOR_RATE: "SET_LABOR_RATE",
  SET_OPCODE: "SET_OPCODE",
  SET_PAY_TYPE_SUB_TYPE: "SET_PAY_TYPE_SUB_TYPE",
  SET_PAY_TYPE_COST_ALLOCATION: "SET_PAY_TYPE_COST_ALLOCATION",
  SET_SERVICE_COMMENTS: "SET_SERVICE_COMMENTS",
  SET_DEALERSHIP_NOTES: "SET_DEALERSHIP_NOTES",
  SET_VEHICLE_ATTRIBUTES: "SET_VEHICLE_ATTRIBUTES",
  SET_LABOR_APP: "SET_LABOR_APP",
  SET_STEP_COMPLETED: "SET_STEP_COMPLETED",
  SET_FILTERED_PARTS: "SET_FILTERED_PARTS",
  SET_LOOKUP_PARTS: "SET_LOOKUP_PARTS",
  SET_DMS_LOOKUP_PARTS: "SET_DMS_LOOKUP_PARTS",
  SET_SERVICE_PARTS: "SET_SERVICE_PARTS",

  SET_PAYTYPE_DETAILS: "SET_PAYTYPE_DETAILS",
  SET_SERVICETYPE_DETAILS: "SET_SERVICETYPE_DETAILS",
  SET_SERVICE_CONTRACT_DETAILS: "SET_SERVICE_CONTRACT_DETAILS",
  SET_QUOTE_SUMMARY: "SET_QUOTE_SUMMARY",
  SET_CURRENT_EDITING_SERVICE: "SET_CURRENT_EDITING_SERVICE",
  SET_PARTS: "SET_PARTS",
  SET_COMMON_CONSUMER_ID: "SET_COMMON_CONSUMER_ID",
  WIPE_STATE: "WIPE_STATE",
  SET_ERRORS: "SET_ERRORS",
  SET_CHANGED: "SET_CHANGED",
  // update parts inventory using callback made upon paytype edits
  SET_PARTS_PRICING_INVENTORY: "SET_PARTS_PRICING_INVENTORY",
  REMOVE_PARTS_PRICING_AND_INVENTORY: "REMOVE_PARTS_PRICING_AND_INVENTORY",

  // @note: Add CSR specific actions
  SET_LABOR_TAX_CODE: "SET_TAX_CODE",
  SET_CAUSE: "SET_CAUSE",
  SET_COMPLAINT: "SET_COMPLAINT",
  SET_CORRECTION: "SET_CORRECTION",
  SET_SERVICE_LINE_COMPLETE: "SET_SERVICE_LINE_COMPLETE",
  SET_READY_FOR_INVOICING: "SET_READY_FOR_INVOICING",
  SET_TECHNICIAN: "SET_TECHNICIAN",
  SET_WARRANTY_PLANS: "SET_WARRANTY_PLANS",
  SET_SELECTED_WARRANTY_PLAN: "SET_SELECTED_WARRANTY_PLAN",
  SET_EXPENSE_CODE_LIST_FOR_WARRANTY_LIST:
    "SET_EXPENSE_CODE_LIST_FOR_WARRANTY_LIST",
  SET_WARRANTY_DETAILS: "SET_WARRANTY_DETAILS",
  SET_SUBMITTED_TO_OEM: "SET_SUBMITTED_TO_OEM",
  SET_WARRANTY_DETAILS_INVOICES: "SET_WARRANTY_DETAILS_INVOICES",
  SET_ALL_CATALOG_FEES_DISCOUNTS: "SET_ALL_CATALOG_FEES_DISCOUNTS",
  SET_CATALOG_FEES: "SET_CATALOG_FEES",
  SET_CATALOG_DISCOUNTS: "SET_CATALOG_DISCOUNTS",
  SET_TECH_VIEW: "SET_TECH_VIEW",
  SET_MANAGER_VIEW: "SET_MANAGER_VIEW",
  SET_DEALER_PROPERTIES: "SET_DEALER_PROPERTIES",
  SET_DEALER_COMPANIES: "SET_DEALER_COMPANIES"
};

const editServiceReducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_DEBUG_MODE: {
      return {
        ...state,
        debugMode: action.payload
      };
    }
    // @csr-logic
    case Actions.SET_APP_TYPE: {
      return {
        ...state,
        appType: action.payload
      };
    }
    case Actions.SET_DEALER_PROPERTIES: {
      return {
        ...state,
        dealerProperties: action.payload
      };
    }
    case Actions.SET_DEALER_COMPANIES: {
      return {
        ...state,
        dealerCompanies: action.payload
      };
    }
    case Actions.SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload
      };
    }
    case Actions.SET_EDIT_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      };
    }
    case Actions.SET_RAWOPERATION_DETAILS: {
      return {
        ...state,
        rawOperationDetails: action.payload
      };
    }
    // holds catalog API service copy here
    case Actions.SET_ORIGINAL_SERVICE: {
      return {
        ...state,
        originalService: action.payload
      };
    }
    case Actions.SET_SERVICE: {
      console.log("SET_SERVICE", action.payload);
      return {
        ...state,
        service: action.payload
      };
    }
    case Actions.MODIFY_SERVICE: {
      const { diff, eraseLaborRateOverride, eraseTotalLaborPriceOverride } =
        action.payload;
      return {
        ...state,
        service: {
          ...state.service,
          laborPriceOverridden: eraseTotalLaborPriceOverride
            ? false
            : state.service.laborPriceOverridden,
          totalLaborPriceOverride: eraseTotalLaborPriceOverride
            ? null
            : state.service.totalLaborPriceOverride,
          ...diff,
          labor: {
            ...state.service.labor,
            laborRateOverride: eraseLaborRateOverride
              ? null
              : state.service.labor.laborRateOverride,
            ...diff?.labor
          },
          warranty: {
            ...state.service.warranty,
            ...diff?.warranty
          }
        }
      };
    }
    case Actions.SET_VEHICLE: {
      return {
        ...state,
        vehicle: action.payload
      };
    }
    // @note: Search-flow: This action will hold operationSource value of catalog service all services {global, non-global, recall, menu, declined}
    case Actions.SET_SERVICE_TYPE: {
      return {
        ...state,
        serviceType: action.payload
      };
    }
    case Actions.SET_PARTS: {
      return {
        ...state,
        service: {
          ...state.service,
          parts: action.payload
        }
      };
    }
    // @note: combine all paytype fields in one action
    case Actions.SET_PAYTYPE_DETAILS: {
      const { payTypeCode, payTypeDescription, payTypeGroup } = action.payload;
      return {
        ...state,
        service: {
          ...state.service,
          payTypeCode,
          payTypeDescription,
          payTypeGroup
        }
      };
    }
    // @note: combine all servicetype fields in one action
    case Actions.SET_SERVICETYPE_DETAILS: {
      const { serviceTypeCode, serviceTypeDescription } = action.payload;
      return {
        ...state,
        service: {
          ...state.service,
          serviceTypeCode,
          serviceTypeDescription
        }
      };
    }
    case Actions.SET_SERVICE_CONTRACT_DETAILS: {
      return {
        ...state,
        service: {
          ...state.service,
          serviceContract: action.payload
        }
      };
    }
    case Actions.SET_PAY_TYPE_SUB_TYPE: {
      return {
        ...state,
        service: {
          ...state.service,
          subTypeId: action.payload
        }
      };
    }
    case Actions.SET_PAY_TYPE_COST_ALLOCATION: {
      console.log("costAllocation", action.payload);
      return {
        ...state,
        service: {
          ...state.service,
          allocationSubTypeId: action.payload
        }
      };
    }
    case Actions.SET_PAY_TYPE_NEW_COST_ALLOCATION_TYPE: {
      console.log("new-costAllocation inside new action", action.payload);
      return {
        ...state,
        service: {
          ...state.service,
          internalAccount: action.payload
        }
      };
    }

    case Actions.SET_LABOR_TIME: {
      return {
        ...state,
        service: {
          ...state.service,
          labor: {
            ...state.service.labor,
            time: action.payload
          }
        }
      };
    }
    // @csr-field
    case Actions.SET_LABOR_RATE: {
      const { laborRate } = action.payload;
      return {
        ...state,
        service: {
          ...state.service,
          labor: {
            ...state.service.labor,
            laborRate: laborRate
          }
        }
      };
    }
    // @csr-field labor tax code
    case Actions.SET_LABOR_TAX_CODE: {
      return {
        ...state,
        service: {
          ...state.service,
          laborTaxCode: action.payload
        }
      };
    }
    // For CSR specific Cause
    case Actions.SET_CAUSE: {
      return {
        ...state,
        service: {
          ...state.service,
          cause: action.payload
        }
      };
    }
    // For CSR specific complaint
    case Actions.SET_COMPLAINT: {
      return {
        ...state,
        service: {
          ...state.service,
          complaint: action.payload
        }
      };
    }
    // For CSR specific correction
    case Actions.SET_CORRECTION: {
      return {
        ...state,
        service: {
          ...state.service,
          correction: action.payload
        }
      };
    }
    // For CSR specific service line complete
    case Actions.SET_SERVICE_LINE_COMPLETE: {
      console.log("SET_SERVICE_LINE_COMPLETE", action.payload);
      return {
        ...state,
        service: {
          ...state.service,
          completedTime: action.payload
        }
      };
    }
    // For CSR specific ready for invoicing
    case Actions.SET_READY_FOR_INVOICING: {
      console.log("SET_READY_FOR_INVOICING", action.payload);
      return {
        ...state,
        service: {
          ...state.service,
          paymentStatus: action.payload
        }
      };
    }
    // For CSR specific for technicians
    case Actions.SET_TECHNICIAN: {
      console.log("Technician", action.payload);
      return {
        ...state,
        service: {
          ...state.service,
          technicians: action.payload
        }
      };
    }
    //* For CSR specific for payType = "Warranty"
    case Actions.SET_WARRANTY_DETAILS: {
      console.log("warrantyDetails", action.payload);

      return {
        ...state,
        service: {
          ...state.service,
          warranty: action.payload
        }
      };
    }
    //* For CSR specific for payType = "Warranty and expense code = 601-VOR FREIGT"
    case Actions.SET_WARRANTY_DETAILS_INVOICES: {
      state?.service?.warranty?.warrantyDetails?.expenseCodes?.map(code => {
        if (code?.name === action?.payload?.expenseName) {
          code.invoices = action?.payload?.invoices;
        }
      });
      return {
        ...state,
        service: {
          ...state.service
        }
      };
    }
    //* For CSR specific for payType = "Warranty"
    case Actions.SET_SUBMITTED_TO_OEM: {
      console.log("OEM SUBMIT", action.payload);

      return {
        ...state,
        service: {
          ...state.service,
          warranty: {
            ...state.service.warranty,
            warrantySubmissionState: action.payload
          }
        }
      };
    }
    case Actions.SET_OPCODE: {
      return {
        ...state,
        service: {
          ...state.service,
          opCode: action.payload
        }
      };
    }
    case Actions.SET_PAY_TYPE_SUB_TYPE: {
      return {
        ...state,
        service: {
          ...state.service,
          subTypeId: action.payload
        }
      };
    }
    case Actions.SET_SERVICE_COMMENTS: {
      return {
        ...state,
        service: {
          ...state.service,
          labor: {
            ...state.service.labor,
            notes: action.payload
          }
        }
      };
    }
    case Actions.SET_DEALERSHIP_NOTES: {
      return {
        ...state,
        service: {
          ...state.service,
          dealershipNotes: action.payload
        }
      };
    }
    case Actions.SET_VEHICLE_ATTRIBUTES: {
      const { type, value } = action.payload;
      const { rawOperationDetails } = state;
      const { selectableVehicleAttributes } = rawOperationDetails;
      let newState = { ...state };
      if (
        isEmpty(state.vehicleAttributes) &&
        !isEmpty(selectableVehicleAttributes)
      ) {
        newState = {
          ...state,
          vehicleAttributes: selectableVehicleAttributes.reduce((acc, next) => {
            acc[next] = "";
            return acc;
          }, {})
        };
      }
      newState = {
        ...newState,
        vehicleAttributes: {
          ...newState.vehicleAttributes,
          [type]: value
        }
      };
      return newState;
    }
    // @note: Global Operation case: to read selected laborApp object from Service options dropdown from UI
    case Actions.SET_LABOR_APP: {
      const { vehicleAttributes } = state;
      const { parts } = action.payload;
      // @note: Parts filtered based on vehicle Attrs
      const newParts = Array.isArray(parts)
        ? parts.filter(part => {
            const { vehicleAttributes: attributes } = part;
            if (null === attributes) {
              return true;
            }
            return Object.keys(attributes).every(attribute => {
              return (
                vehicleAttributes[attribute] === attributes[attribute] ||
                vehicleAttributes[attribute] === "idk"
              );
            });
          })
        : [];
      const { partsPricingAndInventory } = state;
      let rawParts = [];
      if (
        !isEmpty(partsPricingAndInventory) &&
        Array.isArray(partsPricingAndInventory)
      ) {
        // Global ops case: update service.parts with DMS Parts API data if exists
        rawParts = transformPartsWithDMSParts(
          partsPricingAndInventory,
          newParts,
          "GlobalCatalog"
        );
      } else {
        // Global ops case: update service.parts with catalog API response
        rawParts = transformParts(newParts);
      }
      const propLaborAppId =
        isEmpty(action.payload) || !has(action.payload, "laborAppId")
          ? null
          : action.payload.laborAppId;
      const filteredParts = [...newParts];
      // NEW-FIX: @search-flow: call price util to add price-related fields {overrides, calculated, final } to context.service
      const selectedlaborApp = action.payload;
      const { payTypes, rawOperationDetails } = state;
      // @note: call price util for caculation fields
      const serviceWithCalculation =
        priceCalculationUtil.initCalculationForGlobalService(
          rawOperationDetails,
          selectedlaborApp,
          payTypes,
          filteredParts
        );
      console.log(
        "SET_LABOR_APP > serviceWithCalculation",
        serviceWithCalculation
      );
      return {
        ...state,
        laborApp: { ...action.payload },
        // set laborAppId when globalops laborApp selected or done clicked
        laborAppId: propLaborAppId,
        filteredParts,
        // For Global ops case, read rawOperationDetails and extract parts from selected laborApps
        lookupParts: [...rawParts],
        originalService: serviceWithCalculation,
        service: serviceWithCalculation
      };
    }
    case Actions.SET_SERVICE_PARTS: {
      const serviceParts = action.payload;
      return { ...state, serviceParts: [...serviceParts] };
    }
    // For Dealer Publish, Menu service cases: read object[rawOperationDetails]
    // and transform parts from laborApps[0]
    case Actions.SET_LOOKUP_PARTS: {
      let rawParts = [];
      const { laborApps, catalogSource } = action.payload;
      if (!isEmpty(laborApps)) {
        const laborApp = laborApps[0];
        if (!isEmpty(laborApp.parts) && Array.isArray(laborApp.parts)) {
          rawParts = transformParts(laborApp.parts, catalogSource);
        }
      }
      console.log("SET_LOOKUP_PARTS", rawParts);
      return {
        ...state,
        lookupParts: rawParts
      };
    }
    // When open track api has dms parts, this action will update lookupParts with proper priceSource,dmsPrice values
    // If there is a matching filtered part, it will update quantityOnHand, dmsPrice and recordType on it as well.
    case Actions.SET_DMS_LOOKUP_PARTS: {
      const dmsParts = action.payload.map(part => {
        return {
          ...part,
          priceSource: priceSourceLabels.MSRP
        };
      });
      const updatedFilteredParts = state.filteredParts.map(filteredPart => {
        const matchingDmsPart = dmsParts.find(
          dmsPart => dmsPart.partId === filteredPart.partId
        );
        const updatedParts = {
          ...filteredPart
        };
        // Fix - update total prices for each part
        if (matchingDmsPart) {
          updatedParts.quantityAvailable = matchingDmsPart?.quantityAvailable;
          updatedParts.dmsPrice = matchingDmsPart?.dmsPrice;
          updatedParts.recordType = matchingDmsPart?.recordType;
          // Fix - If Part has manual price, DONOT replace with parts API DMS price
          if (filteredPart?.priceSource !== priceSourceLabels.MANUAL) {
            updatedParts.unitPrice = matchingDmsPart?.unitPrice;
            updatedParts.partPrice = matchingDmsPart?.partPrice;
            updatedParts.unitCost = matchingDmsPart?.unitCost;
          }
        }
        return updatedParts;
      });
      return {
        ...state,
        lookupParts: dmsParts,
        filteredParts: updatedFilteredParts
      };
    }
    /**
     * @note: Global ops case: This action read parts from service options dropdown selection, which is laborApp{}
     * Add service case - parts data  in serivce.parts(non-global);  filteredParts(global repair);
     * Edit service summary (global/non-global)- parts data  is updated in "filteredParts" using this Action SET_CURRENT_EDITING_SERVICE  (action logic of edit service context)
     * */
    case Actions.SET_FILTERED_PARTS: {
      let currentParts = action.payload;
      currentParts = currentParts?.map(part => {
        return {
          ...part,
          dmsPending: false
        };
      });
      return {
        ...state,
        filteredParts: currentParts
      };
    }
    // @note: Global ops case:
    case Actions.SET_STEP_COMPLETED: {
      const { step, completed } = action.payload;
      return {
        ...state,
        completedSteps: {
          ...state.completedSteps,
          [step]: completed
        }
      };
    }
    // These Actions to be called in each submodule to update errors object with particular component validation
    case Actions.SET_ERRORS: {
      const { field, value } = action.payload;
      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: value
        }
      };
    }
    // These Actions to be called in each submodule to update changed object with particular component edits
    case Actions.SET_CHANGED: {
      const { field, value } = action.payload;
      return {
        ...state,
        changed: {
          ...state.changed,
          [field]: value
        }
      };
    }
    case Actions.SET_USERNAME: {
      return {
        ...state,
        username: action.payload
      };
    }
    case Actions.SET_LOGGED_IN_USER: {
      return {
        ...state,
        loggedInUserDetails: action.payload
      };
    }
    // @todo: debugMode is passed as props from Search context which is overwritten by this action. see work around below
    case Actions.WIPE_STATE: {
      defaultState.debugMode = state.debugMode;
      return defaultState;
    }
    case Actions.SET_PARTS_PRICING_INVENTORY: {
      return {
        ...state,
        partsPricingAndInventory: action.payload
      };
    }
    case Actions.REMOVE_PARTS_PRICING_AND_INVENTORY: {
      return {
        ...state,
        partsPricingAndInventory: null
      };
    }
    case Actions.SET_QUOTE_SUMMARY: {
      return {
        ...state,
        quoteSummary: action.payload
      };
    }
    case Actions.SET_CURRENT_EDITING_SERVICE: {
      const {
        selectedVehicleAttributes,
        parts: savedParts,
        payTypeCode,
        payTypeDescription,
        payTypeGroup,
        serviceTypeCode,
        serviceContract,
        finalLaborPrice,
        finalPartsPrice,
        totalPriceOverride,
        totalPartsPriceOverride,
        totalLaborPriceOverride,
        calculatedServicePrice,
        calculatedTotalPartsPrice,
        // fix - add required fields from current editing service
        extServiceId,
        serviceName,
        servicePrice,
        dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        asrNotes,
        dealershipNotes,
        commentsRequired,
        serviceDescription,
        serviceKind,
        // CSR specific fields
        cause,
        correction,
        complaint,
        completedTime,
        paymentStatus,
        technicians,
        warranty,
        quoteServiceId,
        laborRateOverride,
        laborRate,
        catalogFees,
        catalogDiscounts
      } = action.payload;
      // fix - below block extract Lookup Parts from current Editing Service
      const currentEditingService = action.payload;

      // //* for parsing warranty as warrantyDetails in currentEditing service
      // delete currentEditingService?.warranty;
      // currentEditingService.warrantyDetails = warranty;

      const rawService = JSON.parse(
        currentEditingService.quoteRawService.rawService
      );
      let extLaborId = !isEmpty(currentEditingService.labor)
        ? currentEditingService.labor.extLaborId || null
        : null;
      // @todo-edit: fix - extLaborId will exist for dealer pub, diagnosis, globals ops only; recall, declined, menus case null is saved for extLaborId
      let laborAppLocal = { laborAppId: null, parts: [] };
      //@workaround: For past quotes with dealer pub service saved with extLaborId as null
      if (!extLaborId) {
        const tempLaborApp =
          rawService.catalogSource === catalogSources.DEALERCATALOG &&
          !isEmpty(rawService?.laborApps?.[0])
            ? rawService.laborApps[0]
            : { laborAppId: null, parts: [] };
        extLaborId = tempLaborApp.laborAppId;
        // @workaround - dealer pub case, when extLaborId missing in quote, we still need to read lookupParts using rawService
        laborAppLocal = tempLaborApp;
      } else {
        const description = !isEmpty(currentEditingService.labor)
          ? currentEditingService.labor.description || ""
          : "";
        // Fallback logic for both global ops case and dealerpublish: always we will have extLaborId used to find match laborApp{object} from  rawService
        if (!description) {
          laborAppLocal = rawService?.laborApps?.find(
            labor => labor.laborAppId && labor.laborAppId === extLaborId
          );
        } else {
          laborAppLocal = rawService?.laborApps?.find(
            labor => labor.displayName && labor.displayName === description
          );
        }
      }
      // last resort fallback if none of the above scenarios are met for laborAppLocal
      laborAppLocal = isEmpty(laborAppLocal)
        ? { laborAppId: null, parts: [] }
        : laborAppLocal;
      // FIX - read dealerLaborRateId value under laborApps object which is matched with currentEditingService extServiceId using quoteRawService.rawService
      const dealerLaborRateId = get(laborAppLocal, "dealerLaborRateId", null);
      currentEditingService.dealerLaborRateId = dealerLaborRateId;
      // read lookupParts when rawService matched with extLaborId to fetch laborApp record for global, dealer pub cases
      const { parts: lookupParts } = laborAppLocal;
      const partIds = savedParts.map(part =>
        !part.extPartId ? "" : part.extPartId.toString()
      );
      const lookupPartsIds = lookupParts.map(part => part.partId.toString());
      const vehicleAttributes = !isEmpty(selectedVehicleAttributes)
        ? JSON.parse(selectedVehicleAttributes)
        : {};
      const recommendedParts = lookupParts.reduce((acc, part) => {
        const partId = part.partId.toString();
        if (partIds.includes(partId)) {
          const savedPart = savedParts.find(
            item => item.extPartId.toString() === partId
          );
          if (!savedPart) {
            return acc;
          }
          acc.push(buildPart(savedPart, part));
        }
        return acc;
      }, []);
      const individualParts = savedParts.reduce((acc, part) => {
        const partId = !part.extPartId ? "" : part.extPartId.toString();
        if (!lookupPartsIds.includes(partId)) {
          acc.push(buildPart(part));
        }
        return acc;
      }, []);
      const filteredParts = [...recommendedParts, ...individualParts].map(
        part => {
          return {
            ...part,
            dmsPending: false
          };
        }
      );
      // @note: read {partsPrice} from quote API response field {finalPartsPrice}; No need to sum of all parts price here
      const { labor, laborTaxCode, quoteServiceType } = currentEditingService;
      // @regression fix - Quote API will have operationSource as null for DECLINED, RECALL, MENU cases
      const operationSource = !currentEditingService.operationSource
        ? quoteServiceType
        : currentEditingService.operationSource;
      const formattedEditService = {
        name: serviceName,
        totalPrice: servicePrice,
        // @edit-fix: set laborAppId for global ops, dealer pub, diagnosis
        laborAppId: extLaborId,
        labor: {
          catalogLabor: labor?.catalogLabor,
          serviceId: extServiceId,
          price: !isEmpty(labor) ? labor.laborPrice : null,
          time: !isEmpty(labor) ? convertMinutesToHours(labor.laborTime) : 0,
          laborRateOverride: labor?.laborRateOverride,
          laborRate: labor?.laborRate
        },
        defaultLaborRate: !isEmpty(labor) ? labor.defaultLaborRate : 0,
        laborRate,
        dealerLaborRateId,
        asrNotes,
        dealershipNotes,
        commentsRequired,
        opCode: dmsOpcode,
        subTypeId,
        allocationSubTypeId,
        internalAccount,
        // @note: read service level description from saved quote at quoteService.serviceDescription
        description: serviceDescription,
        operationSource,
        serviceKind,
        // fix - if defaultPayTypeCode not found in quote service api, copy it from unified response and set in newquote context
        defaultPayTypeCode: has(action.payload, "defaultPayTypeCode")
          ? action.payload.defaultPayTypeCode
          : "",
        payTypeCode,
        payTypeDescription,
        payTypeGroup,
        defaultServiceTypeCode: has(action.payload, "defaultServiceTypeCode")
          ? action.payload.defaultServiceTypeCode
          : "",
        serviceTypeCode,
        serviceContract,
        // @note: read savedParts from quoteService
        parts: isEmpty(savedParts) ? [] : savedParts,
        // @note: read finalPartsPrice {which is parts Price} saved in quote API response
        partsPrice: finalPartsPrice,
        finalPartsPrice,
        finalLaborPrice,
        totalPriceOverride,
        totalLaborPriceOverride,
        totalPartsPriceOverride,

        calculatedLaborPrice: !isEmpty(labor) ? labor.laborPrice : null,
        calculatedTotalPartsPrice,
        calculatedServicePrice,
        // overridden flags derived from price override values
        totalPriceOverridden: isNumber(totalPriceOverride),
        laborPriceOverridden: isNumber(totalLaborPriceOverride),
        partsPriceOverridden: isNumber(totalPartsPriceOverride),

        // CSR Specific fields
        cause,
        correction,
        complaint,
        completedTime,
        paymentStatus,
        technicians,
        warranty,
        quoteServiceId,
        catalogFees,
        catalogDiscounts
      };
      if (appType === "CSR") {
        formattedEditService.laborTaxCode = !isEmpty(laborTaxCode)
          ? laborTaxCode
          : null;
        formattedEditService.labor.laborCostMethod = !isEmpty(labor)
          ? labor?.laborCostMethod
          : null;
        formattedEditService.labor.laborCostOverride = !isEmpty(labor)
          ? labor?.laborCostOverride
          : null;
      }
      console.log(
        "edit context - formattedEditService/savedParts/filteredParts",
        formattedEditService,
        savedParts,
        filteredParts
      );
      const updatedState = {
        ...state,
        currentEditingService,
        completedSteps: {
          ...state.completedSteps,
          vehicleAttr: true,
          serviceOptions: true
        },
        vehicleAttributes,
        filteredParts: [...filteredParts], //  TBD - has merged parts of recommened + individual why?, ask Hector
        errors: {
          ...state.errors,
          serviceOptions: false,
          vehicleAttributes: false,
          serviceNotes: false
        },
        // For summary case: we should add same data fields of (defaultService) we used for search flow including overridden falgs, default laborrate,
        service: { ...formattedEditService },
        originalService: { ...formattedEditService },
        // rule - only global ops case, we need laborApp read from current editing service; other cases laborApp is null
        laborApp:
          rawService.catalogSource === catalogSources.GLOBALCATALOG
            ? laborAppLocal
            : null,
        lookupParts: [
          ...lookupParts.map(lookupPart => {
            return {
              ...lookupPart,
              selected: false
            };
          })
        ]
      };

      return updatedState;
    }
    case Actions.SET_PAY_TYPES: {
      return {
        ...state,
        payTypes: action.payload
      };
    }
    case Actions.SET_SERVICE_TYPES: {
      return {
        ...state,
        serviceTypes: action.payload
      };
    }
    case Actions.SET_VENDOR_LIST: {
      return {
        ...state,
        vendorList: action.payload
      };
    }
    case Actions.SET_SERVICE_CONTRACTS: {
      return {
        ...state,
        serviceContracts: action.payload
      };
    }
    case Actions.SET_PAY_TYPE_SUB_TYPES: {
      return {
        ...state,
        payTypeSubTypes: action.payload
      };
    }
    case Actions.SET_PAY_TYPE_COST_ALLOCATION_TYPES: {
      return {
        ...state,
        costAllocationTypes: action.payload
      };
    }
    case Actions.SET_TECHNICIAN_LIST: {
      console.log("SET_TECHNICIAN_LIST", action.payload);
      const technicians = action.payload;
      return {
        ...state,
        technicians
      };
    }
    case Actions.SET_ALL_CATALOG_FEES_DISCOUNTS: {
      console.log("SET_ALL_CATALOG_FEES_DISCOUNTS", action.payload);
      return {
        ...state,
        discountsAndFees: action.payload
      };
    }

    case Actions.SET_CATALOG_FEES: {
      return {
        ...state,
        service: {
          ...state.service,
          catalogFees: action.payload
        }
      };
    }

    case Actions.SET_CATALOG_DISCOUNTS: {
      return {
        ...state,
        service: {
          ...state.service,
          catalogDiscounts: action.payload
        }
      };
    }

    case Actions.SET_WARRANTY_PLANS: {
      return {
        ...state,
        warrantyPlans: action.payload
      };
    }

    case Actions.SET_SELECTED_WARRANTY_PLAN: {
      const warrantyPlan = action.payload;

      const warrantyPlanLaborRate = warrantyPlan?.laborRate;
      const laborRate =
        warrantyPlanLaborRate !== null && warrantyPlanLaborRate !== undefined
          ? warrantyPlanLaborRate
          : state.service?.labor?.laborRate;

      return {
        ...state,
        service: {
          ...state.service,
          totalLaborPriceOverride: null,
          labor: {
            ...state.service.labor,
            laborRate,
            laborRateOverride: null,
            catalogLabor: warrantyPlan
          }
        }
      };
    }

    // CSR for adding expense code list when paytype is warranty
    case Actions.SET_EXPENSE_CODE_LIST_FOR_WARRANTY_LIST: {
      console.log("expenseCodeList", action.payload);
      return {
        ...state,
        expenseCodeList: action.payload
      };
    }
    case Actions.SET_COMMON_CONSUMER_ID: {
      return {
        ...state,
        commonConsumerId: action.payload
      };
    }
    default: {
      console.log(`Unhandled action type: ${action.type}`);
    }
  }
};

const EditServiceProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(editServiceReducer, defaultState);
  const value = { state, dispatch };
  return (
    <EditServiceContext.Provider value={value}>
      {children}
    </EditServiceContext.Provider>
  );
};

EditServiceProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useEditServiceContext = () => {
  const context = React.useContext(EditServiceContext);
  if (context === undefined) {
    throw new Error(
      "useEditServiceContext must be used within a EditServiceProvider"
    );
  }
  return {
    state: context.state,
    dispatch: context.dispatch
  };
};

export {
  EditServiceProvider,
  useEditServiceContext,
  Actions,
  EditServiceContext
};
