// Below Data model template prepared when service is selected from search results
// {
//   recordId,
//   serviceName, // fuseJS serach label
//   price,
//   synonyms, // ["ALT", "50k"]
//   categoryId,
//   categoryGroupName, // {category filter based fuse search}
//   source,  // { MENU, RECALL, DECLINED, GLOBAL_REPAIR_OPS,DEALER_PUB_MAINT_OPS, DEALER_PUB_REPAIR_OPS }
//   opCode,
//   rawRecord: {} // alacarte, menu, global ops raw response
// }
import {
  QuoteServiceTypeMap,
  ServiceKindMap,
  GlobalOpsServiceType,
  DealerPublishedCategory,
  ServiceDataModel
} from "../constants/search.constants";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import has from "lodash/has";
import {
  doesEmpty,
  convertHoursToMinutes,
  convertMinutesToHours
} from "./quote.util";
import { filterPartsBySelected } from "./service.util";
import { payTypeCodes } from "../../constants/quote-status.constants";
import { OperationSources } from "../../features/page-wrapper/constants/page-wrapper.constants";

const synonymsMapper = (synonymsList, categoryId) => {
  const match = synonymsList.find(
    item => item.serviceCategoryId === categoryId
  );
  if (match) {
    return match.synonyms.synonyms;
  } else {
    return [];
  }
};

const globalOperationMapper = (globalOperations, synonymsList) => {
  if (isEmpty(globalOperations)) {
    return [];
  }
  const globalOperationMapResult = globalOperations.map(operation => {
    let quoteServiceType = QuoteServiceTypeMap.DEALER_PUB_MAINT_OPS;
    if (
      operation.serviceKind === ServiceKindMap.REPAIR &&
      operation.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = QuoteServiceTypeMap.DEALER_PUB_REPAIR_OPS;
    } else if (
      operation.serviceKind === ServiceKindMap.MAINTENANCE &&
      operation.operationSource === GlobalOpsServiceType.DEALERCATALOG
    ) {
      quoteServiceType = QuoteServiceTypeMap.DEALER_PUB_MAINT_OPS;
    } else if (
      operation.serviceKind === ServiceKindMap.REPAIR &&
      operation.operationSource === GlobalOpsServiceType.GLOBALCATALOG
    ) {
      quoteServiceType = QuoteServiceTypeMap.GLOBAL_REPAIR_OPS;
    }
    return {
      recordId: String(operation.operationId),
      serviceName: operation.operationName,
      ...(operation.hasOwnProperty("price") && !isEmpty(operation.price)
        ? {
            price: !doesEmpty(operation.price.price)
              ? operation.price.price.toString()
              : ""
          }
        : { price: "" }),
      synonyms: synonymsMapper(synonymsList, operation.categoryId),
      categoryId: operation.categoryId,
      categoryName: operation.hasOwnProperty("categoryName")
        ? operation.categoryName
        : "",
      categoryGroupName: operation.hasOwnProperty("categoryGroupName")
        ? operation.categoryGroupName
        : "",
      source: quoteServiceType,
      tags: operation.hasOwnProperty("tags") ? operation.tags : null,
      operationSource: operation.operationSource,
      opCode: !isEmpty(operation.opCode) ? operation.opCode : "",
      rawRecord: operation
    };
  });
  return globalOperationMapResult;
};
// @note: filter records with "diagnosis/repair" and dealer catalog repair ops read from globalOperations
const diagnosisServicesMapper = globalOperations => {
  const diagnosisMap = [];
  for (const service of globalOperations) {
    if (isDiagnosisService(service)) {
      diagnosisMap.push(service);
    }
  }
  return diagnosisMap;
};

const isDiagnosisService = service => {
  if (service.rawRecord.serviceCategoryName) {
    const serviceCategoryName = service.rawRecord.serviceCategoryName
      .split(" ")
      .join("")
      .toLowerCase();
    if (
      serviceCategoryName.indexOf("diagnose/repair") > -1 &&
      service.rawRecord.serviceKind &&
      service.rawRecord.serviceKind === ServiceKindMap.REPAIR
    ) {
      return true;
    }
  } else if (
    service.source === QuoteServiceTypeMap.DEALER_PUB_REPAIR_OPS ||
    service.source === "TOP_SERVICES"
  ) {
    const categoryName = service.rawRecord.categoryName
      ? service.rawRecord.categoryName.split(" ").join("").toLowerCase()
      : "";
    if (
      categoryName.indexOf("diagnose/repair") > -1 &&
      service.rawRecord.serviceKind &&
      service.rawRecord.serviceKind === ServiceKindMap.REPAIR
    ) {
      return true;
    }
  }
  return false;
};

const isGlobalCatalog = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.GLOBALCATALOG &&
    service.rawRecord.serviceKind === ServiceKindMap.REPAIR
  ) {
    return true;
  } else {
    return false;
  }
};

const isDealerPublishedMaintenance = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
    service.rawRecord.serviceKind === DealerPublishedCategory.MAINTENANCE
  ) {
    return true;
  } else {
    return false;
  }
};

const isDealerPublishedRepair = service => {
  if (
    service.rawRecord.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
    service.rawRecord.serviceKind === DealerPublishedCategory.REPAIR
  ) {
    return true;
  } else {
    return false;
  }
};

const topServicesMapper = (topServices, synonymsList) => {
  if (isEmpty(topServices)) {
    return [];
  }
  const topServicesMap = topServices.map(service => ({
    recordId: String(service.operationId),
    serviceName: service.operationName,
    ...(service.hasOwnProperty("price") && !isEmpty(service.price)
      ? {
          price: !doesEmpty(service.price.price)
            ? service.price.price.toString()
            : ""
        }
      : { price: "" }),
    synonyms: synonymsMapper(synonymsList, service.categoryId),
    categoryId: service.categoryId,
    categoryName: service.hasOwnProperty("categoryName")
      ? service.categoryName
      : "",
    categoryGroupName: service.hasOwnProperty("categoryGroupName")
      ? service.categoryGroupName
      : "",
    source: "TOP_SERVICES",
    operationSource: service.operationSource,
    tags: service.hasOwnProperty("tags") ? service.tags : null,
    opCode: !isEmpty(service.opCode) ? service.opCode : "",
    rawRecord: service
  }));
  return topServicesMap;
};

const declinedServicesMapper = (declinedServices, synonymsList) => {
  if (isEmpty(declinedServices)) {
    return [];
  }
  const declinedServicesMap = declinedServices.map(service => ({
    recordId: String(service.id),
    id: service.id,
    operationId: service?.operationId || null,
    operationItemId: service?.operationItemId || null,
    serviceName: service?.name || "",
    ...(service.hasOwnProperty("price") && !doesEmpty(service.price)
      ? { price: service.price.toString() }
      : { price: "" }),
    synonyms: synonymsMapper(synonymsList, ""),
    categoryId: service?.categoryId || "",
    categoryGroupName: "",
    source: QuoteServiceTypeMap.DECLINED,
    operationSource: QuoteServiceTypeMap.DECLINED,
    catalogOperationSource:
      service?.catalogOperationSource || service?.operationSource || null,
    declinedDate: service?.declinedDate || null,
    // declined service API returns notes only
    asrNotes: service?.notes || "",
    duration: service?.duration || 0,
    catalogLabor: service?.catalogLabor ?? null,
    laborRateOverride: service?.laborRateOverride ?? null,
    laborRate: service?.laborRate || null,
    // read description from API; saved as description under Quote service
    description: service?.description || "",
    // @todo-beta: decline service api won't return paytypes, set default paytype values for all decline services
    payTypeCode: "",
    payTypeDescription: "",
    payTypeGroup: "",
    defaultServiceTypeCode: "",
    laborTaxCode: service?.laborTaxCode || null,
    serviceTypeCode: service?.serviceTypeCode || "",
    serviceTypeDescription: service?.serviceTypeDescription || "",
    serviceContract: service?.serviceContract || null,
    // since declined api will not return paytypecode, set default paytypecode for declined service
    defaultPayTypeCode: "",
    // @note: declined api wrapper will copy opCode to declined services
    opCode:
      service.hasOwnProperty("opCode") && !isEmpty(service.opCode)
        ? service.opCode
        : "",
    // TBD - debug and cleanup this rawRecord usage
    rawRecord: {
      ...service,
      operationSource: QuoteServiceTypeMap.DECLINED,
      // TBD - verify if operationId needed in create/update payload
      operationId: service.id
    },
    // @fix - create rawApiService here with API response, instead of dependent on rawRecord in edit decline file
    rawApiService: {
      ...service,
      operationSource: QuoteServiceTypeMap.DECLINED
    },
    cause: service?.cause || null,
    complaint: service?.complaint || null,
    correction: service?.correction || null
  }));
  return declinedServicesMap;
};
// @note: This wrapper called when Search module has recall api response to transform
const recallServiceMapper = recallServices => {
  if (isEmpty(recallServices)) {
    return [];
  }
  const recallServicesMap = recallServices.map(service => ({
    recordId: String(service.id),
    id: service.id,
    serviceName: service?.name || "",
    ...(service.hasOwnProperty("price") && !doesEmpty(service.price)
      ? { price: service.price.toString() }
      : { price: "" }),
    synonyms: [],
    categoryId: service?.categoryId || "",
    categoryGroupName: "",
    asrNotes: "",
    duration: service?.duration || 0,
    catalogLabor: service?.catalogLabor ?? null,
    laborRateOverride: service?.laborRateOverride ?? null,
    laborRate: service?.laborRate || null,
    // read description from API; saved as description under Quote service
    description: service?.description || "",
    // @todo-beta: recall service api won't return paytypes, set default paytype values for all recall services
    payTypeCode: "",
    payTypeDescription: "",
    payTypeGroup: "",
    defaultServiceTypeCode: "",
    laborTaxCode: service?.laborTaxCode || null,
    serviceTypeCode: service?.serviceTypeCode || "",
    serviceTypeDescription: service?.serviceTypeDescription || "",
    serviceContract: service?.serviceContract || null,
    // since recall api will not return paytypecode, set default paytypecode for recall service
    defaultPayTypeCode: "",
    // @note: recall api wrapper will copy opCode to recall services
    opCode:
      service.hasOwnProperty("opCode") && !isEmpty(service.opCode)
        ? service.opCode
        : "",
    source: QuoteServiceTypeMap.RECALL,
    operationSource: QuoteServiceTypeMap.RECALL,
    // TBD - debug and cleanup this rawRecord usage
    rawRecord: {
      ...service,
      operationSource: QuoteServiceTypeMap.RECALL,
      // TBD - verify if operationId needed in create/update payload
      operationId: service.id
    },
    // @fix - create rawApiService here with API response, instead of depending rawRecord in edit recall file
    rawApiService: {
      ...service,
      operationSource: QuoteServiceTypeMap.RECALL
    },
    cause: service?.cause || null,
    complaint: service?.complaint || null,
    correction: service?.correction || null
  }));
  return recallServicesMap;
};
// Special case - Dealer tire
const editModulePropsForDealerTire = service => {
  const seriviceObj = editModulePropsFromService(service);
  seriviceObj.labor.notes = !isEmpty(service.laborApps[0])
    ? service.laborApps[0].notes
    : "";
  return seriviceObj;
};
// @param{service} API unified response called for all service types
const editModulePropsFromService = service => {
  if (
    service.operationSource === "GlobalCatalog" ||
    service.catalogSource === "GlobalCatalog"
  ) {
    return editModulePropsFromGlobalRepairService(service);
  }
  const { laborApps } = service;
  // IMPORTANT: this mapper returns serviceObject which is directly used to update editService context.service
  let serviceObject = cloneDeep(ServiceDataModel);
  if (!isEmpty(laborApps)) {
    const laborAppsObj = service.laborApps[0];
    serviceObject = {
      name: laborAppsObj?.name || "",
      totalPrice: laborAppsObj?.totalPrice || 0,
      // @todo-edit: fix - read laborAppId from unified API and pass in quote payload as labor.extLaborId
      laborAppId: laborAppsObj?.laborAppId || null,
      labor: {
        serviceId: service?.operationId || laborAppsObj?.laborAppId || "",
        price: laborAppsObj?.laborPrice || 0,
        time: laborAppsObj?.laborHours || 0,
        notes: "", // @fix - never read notes from catalog API repsonse;
        laborCostMethod: service?.laborCostMethod || null,
        laborCostOverride: service?.laborCostOverride || null,
        catalogLabor: service?.labor?.catalogLabor ?? null,
        laborRateOverride: service?.labor?.laborRateOverride ?? null,
        laborRate: service?.labor?.laborRate || null
      },
      defaultLaborRate: service?.defaultLaborRate || 0,
      dealerLaborRateId: laborAppsObj?.dealerLaborRateId || null,
      asrNotes: service?.asrNotes || "",
      dealershipNotes: "", // default
      commentsRequired: has(service, "commentsRequired")
        ? Boolean(service.commentsRequired)
        : false,
      opCode: formatOpcode(laborAppsObj?.opCode || ""),
      subTypeId: service?.subTypeId,
      allocationSubTypeId: service?.allocationSubTypeId,
      internalAccount: service?.internalAccount,
      // @note: catalogAPI returns {description} always outside laborApps[]
      description: service?.description || "",
      operationSource: service?.operationSource || "",
      serviceKind: service?.serviceKind || "",
      partsPrice: laborAppsObj?.partsPrice || 0,
      parts: laborAppsObj?.parts || [],
      // price override fields added, based on getOperationDetails API response has overridden flags
      totalLaborPriceOverride: laborAppsObj?.laborPriceOverridden
        ? laborAppsObj.laborPrice
        : null,
      totalPartsPriceOverride: laborAppsObj.partsPriceOverridden
        ? laborAppsObj.partsPrice
        : null,
      totalPriceOverride: laborAppsObj.totalPriceOverridden
        ? laborAppsObj.totalPrice
        : null,
      laborPriceOverridden: laborAppsObj?.laborPriceOverridden || false,
      partsPriceOverridden: laborAppsObj?.partsPriceOverridden || false,
      totalPriceOverridden: laborAppsObj?.totalPriceOverridden || false,
      // @note - add calculated fields as null by default
      calculatedLaborPrice: null,
      calculatedTotalPartsPrice: null,
      calculatedServicePrice: null,
      finalLaborPrice: null,
      finalPartsPrice: null,
      // @note: always read defaultPayTypeCode coming from catalog unified api response for non-global ops
      defaultPayTypeCode: service?.defaultPayTypeCode || "",
      defaultServiceTypeCode: service?.defaultServiceType || "",
      skillLevel: service?.dispatchSkillLevel || "",
      make: service?.make || "",
      payTypeGroup: "",
      payTypeCode: "",
      payTypeDescription: "",
      laborTaxCode: service?.laborTaxCode || null,
      serviceTypeCode: service?.serviceTypeCode || "",
      serviceTypeDescription: service?.serviceTypeDescription || "",
      serviceContract: service?.serviceContract || null,
      cause: service?.cause || null,
      complaint: service?.complaint || null,
      correction: service?.correction || null,
      defaultTaxCode: service?.defaultTaxCode || null,
      discountsAndFees: has(service, "discountsAndFees")
        ? service?.discountsAndFees
        : { discounts: [], fees: [] }
    };
    if (service?.payTypeCode === "W") {
      serviceObject.technicians = service?.technicians || [];
      serviceObject.warranty = service?.warranty || null;
    }
  }
  return serviceObject;
};

// IMPORTANT: this mapper returns serviceObject which is directly used to update editService context.service
const editModulePropsFromGlobalRepairService = service => {
  let serviceObject = cloneDeep(ServiceDataModel);
  // @check - globalOps search case - {laborApps, operationSource, description, serviceKind} are copied
  if (!isEmpty(service)) {
    serviceObject = {
      ...serviceObject,
      name: service?.name || "",
      labor: {
        serviceId: service?.id || service?.operationId || "",
        price: 0,
        notes: "",
        time: 0,
        laborCostMethod: service?.laborCostMethod || null,
        laborCostOverride: service?.laborCostOverride || null,
        catalogLabor: service?.labor?.catalogLabor ?? null,
        laborRateOverride: service?.labor?.laborRateOverride ?? null,
        laborRate: service?.labor?.laborRate || null
      },
      defaultLaborRate: service?.defaultLaborRate || 0,
      catalogLabor: service?.catalogLabor ?? null,
      laborRateOverride: service?.laborRateOverride ?? null,
      laborRate: service?.laborRate || 0,
      dealerLaborRateId: null,
      // @note: always read defaultPayTypeCode coming from catalog unified api response - global repair
      defaultPayTypeCode: service?.defaultPayTypeCode || "",
      defaultServiceTypeCode: service?.defaultServiceType || "",
      skillLevel: service?.dispatchSkillLevel || "",
      make: service?.make || "",
      commentsRequired: has(service, "commentsRequired")
        ? Boolean(service.commentsRequired)
        : false,
      opCode: formatOpcode(service?.opCode || ""),
      subTypeId: service?.subTypeId,
      allocationSubTypeId: service?.allocationSubTypeId,
      internalAccount: service?.internalAccount,
      asrNotes: service?.asrNotes || "",
      dealershipNotes: "", // default
      // @note: catalogAPI returns {description} always outside laborApps[]
      description: service?.description || "",
      operationSource: service?.operationSource || "",
      serviceKind: service?.serviceKind || "",
      cause: service?.cause || null,
      complaint: service?.complaint || null,
      correction: service?.correction || null,
      defaultTaxCode: service?.defaultTaxCode || null,
      discountsAndFees: has(service, "discountsAndFees")
        ? service?.discountsAndFees
        : {
            discounts: [],
            fees: []
          },
      // price override and overridden flags to be updated in editService context.service when laborApp dropdown is selected in UI
      parts: []
    };
  }
  return serviceObject;
};

const editModulePropsFromRecall = recallService => {
  const recallObject = {
    // @note:push service object to read operationId
    ...recallService,
    name: recallService.serviceName,
    totalPrice: has(recallService, "price") ? Number(recallService.price) : 0,
    laborAppId: null,
    labor: {
      serviceId: recallService.recordId,
      price: 0,
      time: convertMinutesToHours(recallService.duration),
      catalogLabor: recallService?.catalogLabor ?? null,
      laborRateOverride: recallService?.laborRateOverride ?? null,
      laborRate: recallService?.laborRate || null,
      notes: "",
      laborCostMethod: recallService?.laborCostMethod || null,
      laborCostOverride: recallService?.laborCostOverride || null
    },
    defaultLaborRate: recallService?.defaultLaborRate || 0,
    dealerLaborRateId: null, // by default
    asrNotes: recallService?.asrNotes || "",
    dealershipNotes: "", // default
    commentsRequired: has(recallService, "commentsRequired")
      ? recallService.commentsRequired
      : false,
    // note: opCode is extracted within declined API response wrapper
    opCode: has(recallService, "opCode")
      ? formatOpcode(recallService.opCode)
      : "",
    description: has(recallService, "description")
      ? recallService.description
      : "",
    operationSource:
      recallService?.operationSource || QuoteServiceTypeMap.RECALL, // this default value never change
    serviceKind: ServiceKindMap.RECALL, // this default value never change
    partsPrice: recallService?.partsPrice || 0,
    parts: recallService?.parts || [],
    // @note: recall service will not have price override fields
    totalPriceOverride: null,
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    totalPriceOverridden: false,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    calculatedLaborPrice: null,
    calculatedTotalPartsPrice: null,
    calculatedServicePrice: null,
    finalLaborPrice: null,
    // @note: default paytype will be dervied in edit service module
    defaultPayTypeCode: "",
    payTypeGroup: "",
    payTypeCode: "",
    payTypeDescription: "",
    defaultServiceTypeCode: "",
    laborTaxCode: recallService?.laborTaxCode || null,
    serviceTypeCode: recallService?.serviceTypeCode || "",
    serviceTypeDescription: recallService?.serviceTypeDescription || "",
    serviceContract: recallService?.serviceContract || null,
    cause: null,
    complaint: null,
    correction: null
  };
  return recallObject;
};

const editModulePropsFromDeclinedService = declinedService => {
  const declinedServiceObject = {
    // @note:push service object to read operationId
    ...declinedService,
    name: declinedService.serviceName,
    totalPrice: has(declinedService, "price")
      ? Number(declinedService.price)
      : 0,
    laborAppId: null,
    labor: {
      serviceId: declinedService.recordId,
      price: 0,
      time: convertMinutesToHours(declinedService.duration),
      catalogLabor: declinedService?.catalogLabor ?? null,
      laborRateOverride: declinedService?.laborRateOverride ?? null,
      laborRate: declinedService?.laborRate || null,
      notes: "",
      laborCostMethod: declinedService?.laborCostMethod || null,
      laborCostOverride: declinedService?.laborCostOverride || null
    },
    defaultLaborRate: declinedService?.defaultLaborRate || 0,
    dealerLaborRateId: null, // by default
    asrNotes: declinedService?.asrNotes || "",
    dealershipNotes: "", // default
    commentsRequired: declinedService?.commentsRequired || false,
    // note: opCode is extracted within declined API response wrapper
    opCode: has(declinedService, "opCode")
      ? formatOpcode(declinedService.opCode)
      : "",
    description: has(declinedService, "description")
      ? declinedService.description
      : "",
    operationSource:
      declinedService?.operationSource || QuoteServiceTypeMap.DECLINED, // this default value never change
    serviceKind: ServiceKindMap.DECLINED, // this default value never change
    partsPrice: declinedService?.partsPrice || 0,
    parts: declinedService?.parts || [],
    // IMPORTANT: as per requirement, decline service.price considered as total price override
    totalPriceOverridden: has(declinedService, "price") ? true : false,
    totalPriceOverride: has(declinedService, "price")
      ? Number(declinedService.price)
      : 0,
    // @note: declined service will not have price override fields
    totalLaborPriceOverride: null,
    totalPartsPriceOverride: null,
    laborPriceOverridden: false,
    partsPriceOverridden: false,
    calculatedLaborPrice: null,
    calculatedTotalPartsPrice: null,
    calculatedServicePrice: null,
    finalLaborPrice: null,
    // @note: default paytype will be dervied in edit service module
    defaultPayTypeCode: "",
    payTypeGroup: "",
    payTypeCode: "",
    payTypeDescription: "",
    defaultServiceTypeCode: "",
    laborTaxCode: declinedService?.laborTaxCode || null,
    serviceTypeCode: declinedService?.serviceTypeCode || "",
    serviceTypeDescription: declinedService?.serviceTypeDescription || "",
    serviceContract: declinedService?.serviceContract || null,
    cause: null,
    complaint: null,
    correction: null
  };
  return declinedServiceObject;
};

// @note - Transform parts object for Menu service, return parts datamodel same like global operation details API datamodel
const prepareMenuServiceParts = parts => {
  const partsWithPartId = parts.map(part => {
    return {
      ...part,
      // @todo-poc: add rowId field to support ag-grid getRowNodeId() binded with rowId for poc.
      // @note: partId is a string used for filter logic accross parts grid.
      ...(has(part, "id") && { partId: part.id.toString() }),
      ...(!has(part, "id") &&
        has(part, "partId") && { partId: part.partId.toString() }),
      // @note: rowId is a number.
      ...(has(part, "id") && { rowId: Number(part.id) }),
      ...(!has(part, "id") &&
        has(part, "partId") && { rowId: Number(part.partId) }),
      ...(has(part, "description") ? { partName: part.description } : ""),
      ...(!has(part, "description") && has(part, "partName")
        ? { description: part.partName }
        : ""),
      quantity: has(part, "adjustedQuantity") ? part.adjustedQuantity : 0,
      unitPrice: has(part, "unitPrice") ? part.unitPrice : 0,
      oemPartNumber: has(part, "oemPartNumber") ? part.oemPartNumber : "",
      oilType: has(part, "oilType") ? part.oilType : "",
      unitOfMeasure: has(part, "adjustedUom") ? part.adjustedUom : "",
      // @workaround: copy {manufacturerCode} if returned in catalog menus API to field {dtDmsPartCode}
      // DMS opentrack API payload expecting this field {dtDmsPartCode} to return DMS parts
      dtDmsPartCode: get(
        part,
        "dtDmsPartCode",
        get(part, "manufacturerCode", null)
      ),
      priceSource:
        has(part, "priceSource") && part.priceSource
          ? part.priceSource
          : "MSRP",
      ...(has(part, "partPriceSource") && {
        partPriceSource: part.partPriceSource
      }),
      position: "",
      notes: get(part, "notes", null),
      relationship: null,
      qualifiers: null,
      dmsPending: false,
      // @note: Parts under menu services should always be pre-selected
      selected: true
    };
  });
  return partsWithPartId;
};

// @note: change - price overrides fields are read from menu catalog API
const editModulePropsFromMenuService = (menuService, originalService) => {
  console.log(
    ">> Search edit menu service data mapper",
    menuService,
    originalService
  );
  const menuServiceObject = {
    // @menu: these fields not returned in API - laborAppId, asrNotes, commentsRequired, defaultLaborRate; hence set default values
    name: menuService?.name || "",
    totalPrice: menuService?.price || 0,
    laborAppId: null, // always null for menu
    labor: {
      serviceId: menuService?.id || null,
      price: menuService?.scheduledLaborPrice || 0,
      notes: "",
      time: convertMinutesToHours(get(menuService, "durationMins", 0)),
      catalogLabor: menuService?.catalogLabor ?? null,
      laborRateOverride: menuService?.laborRateOverride ?? null,
      laborRate: menuService?.laborRate || null
    },
    asrNotes: "", // always set "" for menu
    commentsRequired: false, // always false for menu
    catalogLabor: null,
    // always set 0; defaultLaborRate not exists in API
    defaultLaborRate: 0,
    laborRateOverride: null,
    laborRate: 0,
    defaultTaxCode: menuService?.defaultTaxCode || null,
    dealerLaborRateId: get(originalService, "dealerLaborRateId", null),
    // Within the ui, we expect dealershipNotes as string by design
    dealershipNotes: menuService?.dealershipNotes || "",
    opCode: has(menuService, "dmsOpcode")
      ? formatOpcode(menuService.dmsOpcode)
      : "",
    subTypeId: menuService?.subTypeId,
    allocationSubTypeId: menuService?.allocationSubTypeId,
    internalAccount: menuService?.internalAccount,
    // read description from menu API; saved as description under Quote service
    description: menuService?.description || "",
    operationSource: QuoteServiceTypeMap.MENU,
    // check if menuService has payTypeCode field, api returns defaultPayTypeCode at menu level only
    defaultPayTypeCode: menuService?.payTypeCode || "",
    payTypeGroup: menuService?.payTypeGroup || "",
    payTypeCode: menuService?.payTypeCode || "",
    payTypeDescription: menuService?.payTypeDescription || "",
    serviceTypeCode: menuService?.serviceTypeCode || "",
    serviceContract: menuService?.serviceContract || null,
    laborTaxCode: menuService?.laborTaxCode || null,
    partsPrice: menuService?.partsPrice || 0,
    parts: has(menuService, "part")
      ? prepareMenuServiceParts(menuService.part)
      : [],
    originalParts: has(originalService, "part")
      ? prepareMenuServiceParts(originalService.part)
      : [],
    // @note: read calculated, final price, override fields from menu service updated before processing to edit menu service page
    totalPriceOverridden: menuService?.totalPriceOverridden || false,
    partsPriceOverridden: menuService?.partsPriceOverridden || false,
    laborPriceOverridden: menuService?.laborPriceOverridden || false,
    totalPriceOverride: menuService.totalPriceOverridden
      ? menuService?.price || null
      : null,
    totalLaborPriceOverride: menuService?.totalLaborPriceOverride || null,
    totalPartsPriceOverride: menuService?.totalPartsPriceOverride || null,
    calculatedLaborPrice: menuService?.calculatedLaborPrice || 0,
    calculatedTotalPartsPrice: menuService?.calculatedTotalPartsPrice || 0,
    calculatedServicePrice: menuService?.calculatedServicePrice || 0,
    finalLaborPrice: menuService?.finalLaborPrice || 0,
    cause: menuService?.cause || null,
    complaint: menuService?.complaint || null,
    correction: menuService?.correction || null,
    technicians: menuService?.technicians || null
  };
  console.log(
    "%c editModulePropsFromMenuService",
    "color: orange;",
    menuServiceObject
  );
  return menuServiceObject;
};

// @note: menuService sent from review menu page has overridden, calculated fields same as global operation details API data model
const updateRawOperationFromMenuService = service => {
  const newService = cloneDeep(service);
  delete newService.part;
  newService.displayName = service?.name || "";
  newService.laborAppId = null; // always null for menu
  newService.opCode = service?.dmsOpcode || "";
  newService.subTypeId = service?.subTypeId;
  newService.allocationSubTypeId = service?.allocationSubTypeId;
  newService.internalAccount = service?.internalAccount;
  newService.notes = null; // always null for menu
  newService.laborPrice = service?.scheduledLaborPrice || 0;
  newService.laborHours = has(service, "durationMins")
    ? convertMinutesToHours(service.durationMins)
    : 0;
  newService.catalogLabor = service?.catalogLabor ?? null;
  newService.laborRateOverride = service?.laborRateOverride ?? null;
  newService.laborRate = service?.laborRate || null;
  newService.dealerLaborRateId = get(service, "dealerLaborRateId", null);
  newService.totalPrice = service?.price || 0;
  newService.partsPrice = service?.partsPrice || 0;
  newService.laborPriceOverridden = service?.laborPriceOverridden || false;
  newService.partsPriceOverridden = service?.partsPriceOverridden || false;
  newService.totalPriceOverridden = service?.totalPriceOverridden || false;
  newService.dealerCode = service?.dealerCode || "";
  newService.payTypeGroup = service?.payTypeGroup || "";
  newService.payTypeCode = service?.payTypeCode || "";
  newService.payTypeDescription = service?.payTypeDescription || "";
  newService.serviceTypeCode = service?.serviceTypeCode || "";
  newService.serviceContract = service?.serviceContract || null;
  newService.laborTaxCode = service?.laborTaxCode || null;
  newService.operationSource = QuoteServiceTypeMap.MENU;
  newService.parts = has(service, "part")
    ? prepareMenuServiceParts(service.part)
    : [];
  const laborApps = [];
  laborApps.push(newService);

  const rawObject = {
    laborApps,
    abbreviation: null,
    catalogSource: QuoteServiceTypeMap.MENU,
    operationSource: QuoteServiceTypeMap.MENU,
    commentsRequired: false, // Always false for Menu case
    catalogLabor: null,
    dealerCode: "",
    // @note: MENU, defaultLaborRate never returned from catalog API
    defaultLaborRate: 0,
    laborRateOverride: null,
    laborRate: 0,
    // @note- catalog Menu API returns {description} at menu service level, copy this field at rawService level
    description: service?.description || "",
    enabled: null,
    extId: null,
    laborTimeMarkupPercent: null,
    loanerAllowed: null,
    make: "",
    operationId: service.id,
    parentId: null,
    mandatoryInAppt: null,
    notApplicableParts: [],
    selectableVehicleAttributes: [],
    serviceKind: null,
    serviceCategoryId: service?.serviceCategoryId || null,
    serviceCategoryName: service?.serviceCategoryName || "",
    vehicleAttributes: null,
    variant: null,
    // @note: payType values are updated for selectedMenu and fowarded to each menuService when paytypes API is ready
    payTypeGroup: service?.payTypeGroup || "",
    payTypeCode: service?.payTypeCode || "",
    payTypeDescription: service?.payTypeDescription || "",
    serviceContract: service?.serviceContract || null
  };
  return rawObject;
};

// This method to prepare payload service object using editedDeclinedService and catalog's declined service
const updateDeclinedService = (declinedAPIService, editedDeclinedService) => {
  const mapService = {};
  const { labor } = editedDeclinedService;
  // rawService should not be formatted, pass declined service API response
  const rawApiService = declinedAPIService?.rawApiService || {};
  const rawService = !isEmpty(rawApiService)
    ? JSON.stringify(rawApiService)
    : declinedAPIService?.quoteRawService?.rawService || {};
  const serviceId = declinedAPIService.id || declinedAPIService.recordId || "";
  mapService.operationId = declinedAPIService?.operationId || null;
  mapService.operationItemId = declinedAPIService?.operationItemId || null;
  mapService.laborAppId = editedDeclinedService?.laborAppId || null;
  mapService.catalogOperationSource =
    declinedAPIService?.catalogOperationSource ||
    editedDeclinedService?.catalogOperationSource;
  const serviceDescription = getDescriptionFromApi(editedDeclinedService);
  // read fields from API service
  mapService.id = serviceId;
  mapService.serviceId = serviceId.toString();
  mapService.operationSource =
    declinedAPIService?.operationSource || "DECLINED";
  mapService.serviceKind = editedDeclinedService?.serviceKind || null;
  mapService.quoteRawService = { rawService };
  // prepare payload from edit service {}
  mapService.serviceName = editedDeclinedService?.name || "";
  mapService.serviceDescription = serviceDescription;
  mapService.price = editedDeclinedService?.totalPrice || 0;
  mapService.dealershipNotes = editedDeclinedService?.dealershipNotes || "";
  mapService.asrNotes = editedDeclinedService?.asrNotes || "";
  // CHECK - pass proper labor fields - duration, durationMins, laborPrice
  mapService.duration = labor?.time || 0;
  mapService.catalogLabor = labor?.catalogLabor ?? null;
  mapService.laborRateOverride = labor?.laborRateOverride ?? null;
  mapService.laborRate = labor?.laborRate || null;
  mapService.durationMins = convertHoursToMinutes(labor?.time || 0);
  mapService.laborPrice = editedDeclinedService?.calculatedLaborPrice || 0;
  mapService.laborTaxCode = editedDeclinedService?.laborTaxCode || null;
  mapService.dmsOpcode = editedDeclinedService?.opCode || "";
  mapService.subTypeId = editedDeclinedService?.subTypeId;
  mapService.allocationSubTypeId = editedDeclinedService?.allocationSubTypeId;
  mapService.internalAccount = editedDeclinedService?.internalAccount;
  mapService.payTypeGroup = editedDeclinedService?.payTypeGroup || "";
  mapService.payTypeCode = editedDeclinedService?.payTypeCode || "";
  mapService.payTypeDescription =
    editedDeclinedService?.payTypeDescription || "";
  mapService.serviceTypeCode = editedDeclinedService?.serviceTypeCode || "";
  mapService.serviceTypeDescription =
    editedDeclinedService?.serviceTypeDescription || "";
  mapService.serviceContract = editedDeclinedService?.serviceContract || null;
  mapService.calculatedTotalPartsPrice =
    editedDeclinedService?.calculatedTotalPartsPrice || 0;
  mapService.calculatedServicePrice =
    editedDeclinedService?.calculatedServicePrice || 0;
  mapService.totalLaborPriceOverride =
    editedDeclinedService?.totalLaborPriceOverride || null;
  mapService.totalPriceOverride =
    editedDeclinedService?.totalPriceOverride || null;
  mapService.totalPartsPriceOverride =
    editedDeclinedService?.totalPartsPriceOverride || null;
  mapService.parts = filterPartsBySelected(
    refactorPartsWithPartId(editedDeclinedService)
  );
  mapService.finalLaborPrice = editedDeclinedService?.finalLaborPrice || 0;
  // CHECK THIS: always partsPrice will have override parts price or calculated parts price
  // change - use finalPartsPrice updated with price utils
  mapService.finalPartsPrice = editedDeclinedService?.finalPartsPrice || 0;
  // NO catalog fields found to add in payload{}
  mapService.serviceCategory = "";
  mapService.serviceCategoryId = "";
  mapService.serviceCategoryName = "";

  mapService.cause = editedDeclinedService?.cause || null;
  mapService.complaint = editedDeclinedService?.complaint || null;
  mapService.correction = editedDeclinedService?.correction || null;

  mapService.vehicleAttributes =
    editedDeclinedService?.vehicleAttributes || null;

  console.log("newDeclinedService>", mapService);
  return mapService;
};

const updateRecallService = (recallAPIService, editedRecallService) => {
  const mapService = {};
  const { labor } = editedRecallService;
  const serviceDescription = getDescriptionFromApi(editedRecallService);

  // rawService should not be formatted, pass recall service API response
  const rawApiService = recallAPIService?.rawApiService || {};
  const rawService = JSON.stringify(rawApiService);
  const serviceId = recallAPIService.id || recallAPIService.recordId || "";
  // read fields from API service
  mapService.id = serviceId;
  mapService.serviceId = serviceId.toString();
  mapService.operationSource = recallAPIService?.operationSource || "RECALL";
  mapService.serviceKind = editedRecallService?.serviceKind || null;
  mapService.quoteRawService = { rawService };
  // prepare payload from edit service {}
  mapService.serviceName = editedRecallService?.name || "";
  mapService.serviceDescription = serviceDescription;
  mapService.price = editedRecallService?.totalPrice || 0;
  mapService.asrNotes = editedRecallService?.asrNotes || "";
  mapService.dealershipNotes = editedRecallService?.dealershipNotes || "";
  // mapService.dealershipNotes =
  //   (editedRecallService?.dealershipNotes && [
  //     { note: editedRecallService?.dealershipNotes?.trim() }
  //   ]) ||
  //   null;
  // CHECK labor fields
  mapService.duration = labor?.time || 0;
  mapService.catalogLabor = labor?.catalogLabor ?? null;
  mapService.laborRateOverride = labor?.laborRateOverride ?? null;
  mapService.laborRate = labor?.laborRate || null;
  mapService.durationMins = convertHoursToMinutes(labor?.time || 0);
  mapService.laborPrice = editedRecallService?.calculatedLaborPrice || 0;
  mapService.laborTaxCode = editedRecallService?.laborTaxCode || null;
  mapService.dmsOpcode = editedRecallService?.opCode || "";
  mapService.subTypeId = editedRecallService?.subTypeId;
  mapService.allocationSubTypeId = editedRecallService?.allocationSubTypeId;
  mapService.internalAccount = editedRecallService?.internalAccount;
  mapService.payTypeGroup = editedRecallService?.payTypeGroup || "";
  mapService.payTypeCode = editedRecallService?.payTypeCode || "";
  mapService.payTypeDescription = editedRecallService?.payTypeDescription || "";
  mapService.serviceTypeCode = editedRecallService?.serviceTypeCode || "";
  mapService.serviceTypeDescription =
    editedRecallService?.serviceTypeDescription || "";
  mapService.serviceContract = editedRecallService?.serviceContract || null;
  mapService.calculatedTotalPartsPrice =
    editedRecallService?.calculatedTotalPartsPrice || 0;
  mapService.calculatedServicePrice =
    editedRecallService?.calculatedServicePrice || 0;
  mapService.totalLaborPriceOverride =
    editedRecallService?.totalLaborPriceOverride || null;
  mapService.totalPriceOverride =
    editedRecallService?.totalPriceOverride || null;
  mapService.totalPartsPriceOverride =
    editedRecallService?.totalPartsPriceOverride || null;
  mapService.parts = filterPartsBySelected(
    refactorPartsWithPartId(editedRecallService)
  );
  mapService.finalLaborPrice = editedRecallService?.finalLaborPrice || 0;
  // CHECK THIS: always partsPrice will have override parts price or calculated parts price
  // change - use finalPartsPrice updated with price utils
  mapService.finalPartsPrice = editedRecallService?.finalPartsPrice || 0;
  // NO catalog fields found to add in payload{}
  mapService.serviceCategory = "";
  mapService.serviceCategoryId = "";
  mapService.serviceCategoryName = "";
  mapService.cause = editedRecallService?.cause || null;
  mapService.complaint = editedRecallService?.complaint || null;
  mapService.correction = editedRecallService?.correction || null;

  console.log("newRecallService>", mapService);
  return mapService;
};
// @params{selectedMenuSerivce} - API menuService selected from Review menu page
// @params{editStateService} - modified fields in edit service page placed in this object using edit service context
const updateMenuService = (selectedMenuSerivce, editStateService) => {
  console.log("updateMenuService", selectedMenuSerivce, editStateService);
  const newService = cloneDeep(selectedMenuSerivce);
  const serviceDescription = getDescriptionFromApi(selectedMenuSerivce);
  newService.price = editStateService.totalPrice;
  newService.scheduledLaborPrice = editStateService.calculatedLaborPrice;
  newService.durationMins = convertHoursToMinutes(editStateService.labor.time);
  newService.catalogLabor = editStateService?.labor?.catalogLabor ?? null;
  newService.laborRateOverride =
    editStateService?.labor?.laborRateOverride ?? null;
  newService.laborRate = editStateService?.labor?.laborRate || null;
  newService.dmsOpcode = editStateService.opCode;
  newService.subTypeId = editStateService.subTypeId;
  newService.allocationSubTypeId = editStateService.allocationSubTypeId;
  newService.internalAccount = editStateService.internalAccount;
  newService.serviceDescription = serviceDescription;
  // Within the ui, we expect dealershipNotes as string by design
  newService.dealershipNotes = editStateService.dealershipNotes || "";
  newService.part = prepareMenuServiceParts(editStateService.parts);
  newService.parts = filterPartsBySelected(
    prepareMenuServiceParts(editStateService.parts)
  );
  newService.partsPrice = editStateService.partsPrice;
  // TBD - this field to be verify whether to pass in wrapper for quote payload util method
  newService.calculatedLaborPrice = editStateService.calculatedLaborPrice;
  newService.calculatedTotalPartsPrice =
    editStateService.calculatedTotalPartsPrice;
  newService.calculatedServicePrice = editStateService.calculatedServicePrice;
  // Fix - From UI, override allowed on Labor price, hence set labor override fields if exist from edit service page
  newService.laborPriceOverridden = !isNull(
    editStateService?.totalLaborPriceOverride
  )
    ? true
    : selectedMenuSerivce.laborPriceOverridden;
  newService.totalLaborPriceOverride = editStateService.totalLaborPriceOverride;
  newService.totalPriceOverride = editStateService.totalPriceOverride;
  newService.totalPartsPriceOverride = editStateService.totalPartsPriceOverride;
  newService.finalLaborPrice = editStateService.finalLaborPrice;
  // always partsPrice will have override parts price or calculated parts price
  // change - use finalPartsPrice updated with price utils
  newService.finalPartsPrice = editStateService.finalPartsPrice;
  newService.dealerCode = editStateService.dealerCode;
  newService.payTypeGroup = editStateService.payTypeGroup;
  newService.payTypeCode = editStateService.payTypeCode;
  newService.payTypeDescription = editStateService.payTypeDescription;
  newService.serviceContract = editStateService.serviceContract;
  newService.laborTaxCode = get(editStateService, "laborTaxCode", null);

  newService.cause = get(editStateService, "cause", null);
  newService.complaint = get(editStateService, "complaint", null);
  newService.correction = get(editStateService, "correction", null);

  newService.dealerLaborRateId = get(
    selectedMenuSerivce,
    "dealerLaborRateId",
    null
  );
  newService.technicians = editStateService?.technicians || [];
  return {
    ...newService
  };
};
/**
 *
 * @param {Object} catalogAPIService - passed unified response of catalog API of Dealer publish, global repair, menu service case;
 * For Recall, Declined, "editedService" object passed in argu catalogAPIService
 * @returns {string} description for a service
 */

const getDescriptionFromApi = catalogAPIService => {
  const description = get(catalogAPIService, "description", "");
  return description;
};

const updateDealerPublished = (catalogAPIService, editedService) => {
  const payloadService = {};
  const laborApp = get(catalogAPIService, "laborApps[0]", {});

  // catalogAPIService Fields
  const serviceDescription = getDescriptionFromApi(catalogAPIService);
  payloadService.asrNotes = get(catalogAPIService, "asrNotes", "");
  payloadService.shopDuration = get(
    catalogAPIService,
    "shopDurationMins",
    null
  );
  payloadService.loanerAllowed = get(catalogAPIService, "loanerAllowed", null);
  payloadService.waiterAllowed = get(catalogAPIService, "waiterAllowed", null);
  payloadService.commentsRequired = get(
    catalogAPIService,
    "commentsRequired",
    null
  );
  payloadService.quoteRawService = get(catalogAPIService, "quoteRawService", {
    rawService: ""
  });
  payloadService.serviceCategory = get(
    catalogAPIService,
    "serviceCategoryName",
    ""
  );
  payloadService.serviceCategoryId = get(
    catalogAPIService,
    "serviceCategoryId",
    ""
  );
  payloadService.serviceDescription = serviceDescription;
  // IMPORTANT: read fields - operationSource,serviceKind always from catalogAPIService level
  payloadService.operationSource = get(
    catalogAPIService,
    "catalogSource",
    null
  );
  payloadService.serviceKind = get(catalogAPIService, "serviceKind", null);
  // case: always read id from catalog API response but not from laborApps[0];
  const extServiceId =
    get(catalogAPIService, "id", null) ||
    get(catalogAPIService, "operationId", null);
  payloadService.extServiceId = extServiceId;
  payloadService.id = extServiceId;
  // editedService Fields
  payloadService.calculatedServicePrice = get(
    editedService,
    "calculatedServicePrice",
    0
  );
  payloadService.calculatedTotalPartsPrice = get(
    editedService,
    "calculatedTotalPartsPrice",
    0
  );
  payloadService.dmsOpcode = get(editedService, "opCode", null);
  payloadService.subTypeId = get(editedService, "subTypeId", null);
  payloadService.allocationSubTypeId = get(
    editedService,
    "allocationSubTypeId",
    null
  );
  payloadService.internalAccount = get(editedService, "internalAccount", null);
  payloadService.finalLaborPrice = get(editedService, "finalLaborPrice", 0);
  // change - use finalPartsPrice updated with price utils
  payloadService.finalPartsPrice = get(editedService, "finalPartsPrice", 0);
  payloadService.labor = {
    defaultLaborRate: get(
      editedService,
      "defaultLaborRate",
      laborApp.defaultLaborRate
    ),
    dealerLaborRateId: get(laborApp, "dealerLaborRateId", null),
    extLaborId: get(laborApp, "laborAppId", null),
    description: get(laborApp, "displayName", ""),
    laborPrice: get(editedService, "calculatedLaborPrice", 0),
    laborTime: convertHoursToMinutes(get(editedService, "labor.time", 0)),
    catalogLabor: get(editedService, "labor.catalogLabor", null),
    laborRateOverride: get(editedService, "labor.laborRateOverride", null),
    laborRate: get(editedService, "labor.laborRate", null)
  };
  payloadService.parts = filterPartsBySelected(get(editedService, "parts", []));
  payloadService.payTypeCode = get(editedService, "payTypeCode", "");
  payloadService.payTypeDescription = get(
    editedService,
    "payTypeDescription",
    ""
  );
  payloadService.serviceTypeCode = get(editedService, "serviceTypeCode", "");
  payloadService.serviceTypeDescription = get(
    editedService,
    "serviceTypeDescription",
    ""
  );
  payloadService.payTypeGroup = get(editedService, "payTypeGroup", "");
  payloadService.dealershipNotes = get(editedService, "dealershipNotes", "");
  payloadService.serviceName = get(editedService, "name", laborApp.name);
  payloadService.servicePrice = get(editedService, "totalPrice", 0);
  payloadService.totalLaborPriceOverride = get(
    editedService,
    "totalLaborPriceOverride",
    null
  );
  payloadService.totalPartsPriceOverride = get(
    editedService,
    "totalPartsPriceOverride",
    null
  );
  payloadService.totalPriceOverride = get(
    editedService,
    "totalPriceOverride",
    null
  );
  // csr fields
  payloadService.labor.laborCostMethod = get(
    catalogAPIService,
    "laborCostMethod",
    null
  );
  payloadService.labor.laborCostOverride = get(
    catalogAPIService,
    "laborCostOverride",
    null
  );
  payloadService.cause = get(editedService, "cause", null);
  payloadService.complaint = get(editedService, "complaint", null);
  payloadService.correction = get(editedService, "correction", null);
  payloadService.laborTaxCode = get(editedService, "laborTaxCode", null);
  if (editedService?.payTypeCode === "W") {
    payloadService.technicians = get(editedService, "technicians", []);
    payloadService.warranty = get(editedService, "warranty", null);
  }
  if (editedService?.payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
    payloadService.serviceContract = get(
      editedService,
      "serviceContract",
      null
    );
  }
  payloadService.discountsAndFees = has(editedService, "discountsAndFees")
    ? editedService?.discountsAndFees
    : {
        discounts: [],
        fees: []
      };
  return payloadService;
};
/**
 *
 * @param {Object} catalogAPIService - holds unified response of catalog API
 * @param {Object} editedService - holds modified fields from edit service page
 * @returns
 */
const updateServiceGlobalRepair = (catalogAPIService, editedService) => {
  const payloadService = {};
  // read selected laborApps[] in edit page and passed as "laborApp" to editedService
  const laborApp = editedService.laborApp;
  // catalogAPIService Fields
  const serviceDescription = getDescriptionFromApi(catalogAPIService);
  payloadService.asrNotes = get(catalogAPIService, "asrNotes", "");
  payloadService.shopDuration = get(
    catalogAPIService,
    "shopDurationMins",
    null
  );
  payloadService.loanerAllowed = get(catalogAPIService, "loanerAllowed", null);
  payloadService.waiterAllowed = get(catalogAPIService, "waiterAllowed", null);
  payloadService.commentsRequired = get(
    catalogAPIService,
    "commentsRequired",
    null
  );
  payloadService.quoteRawService = get(
    catalogAPIService,
    "quoteRawService",
    {}
  );
  payloadService.serviceCategory = get(
    catalogAPIService,
    "serviceCategoryName",
    null
  );
  payloadService.serviceCategoryId = get(
    catalogAPIService,
    "serviceCategoryId",
    null
  );
  // case: always read id from catalog API response but not from laborApps[];
  const extServiceId =
    get(catalogAPIService, "id", null) ||
    get(catalogAPIService, "operationId", null);
  payloadService.extServiceId = extServiceId;
  payloadService.id = extServiceId;
  // IMPORTANT: read fields - operationSource,serviceKind always from catalogAPIService level
  payloadService.operationSource = get(
    catalogAPIService,
    "catalogSource",
    null
  );
  payloadService.serviceKind = get(catalogAPIService, "serviceKind", null);
  payloadService.serviceDescription = serviceDescription;
  // editedService Fields
  payloadService.calculatedServicePrice = get(
    editedService,
    "calculatedServicePrice",
    0
  );
  payloadService.calculatedTotalPartsPrice = get(
    editedService,
    "calculatedTotalPartsPrice",
    0
  );
  payloadService.dealershipNotes = get(editedService, "dealershipNotes", "");
  payloadService.dmsOpcode = get(editedService, "opCode", null);
  payloadService.subTypeId = get(editedService, "subTypeId", null);
  payloadService.allocationSubTypeId = get(
    editedService,
    "allocationSubTypeId",
    null
  );
  payloadService.internalAccount = get(editedService, "internalAccount", null);
  payloadService.finalLaborPrice = get(editedService, "finalLaborPrice", 0);
  // always partsPrice will have override parts price or calculated parts price
  // change - use finalPartsPrice updated with price utils
  payloadService.finalPartsPrice = get(editedService, "finalPartsPrice", 0);
  payloadService.labor = {
    defaultLaborRate: get(
      editedService,
      "defaultLaborRate",
      laborApp.defaultLaborRate
    ),
    dealerLaborRateId: get(laborApp, "dealerLaborRateId", null),
    // case: always read selected laborAppId
    extLaborId: get(laborApp, "laborAppId", null),
    description: get(laborApp, "displayName", ""),
    laborPrice: get(editedService, "calculatedLaborPrice", 0),
    laborTime: convertHoursToMinutes(get(editedService, "labor.time", 0)),
    catalogLabor: get(editedService, "labor.catalogLabor", null),
    laborRateOverride: get(editedService, "labor.laborRateOverride", null),
    laborRate: get(editedService, "labor.laborRate", null)
  };
  // Global catalog case: always read filteredParts
  payloadService.parts = filterPartsBySelected(
    get(editedService, "filteredParts", [])
  );
  payloadService.payTypeCode = get(editedService, "payTypeCode", "");
  payloadService.payTypeDescription = get(
    editedService,
    "payTypeDescription",
    ""
  );
  payloadService.serviceTypeCode = get(editedService, "serviceTypeCode", "");
  payloadService.serviceTypeDescription = get(
    editedService,
    "serviceTypeDescription",
    ""
  );
  payloadService.payTypeGroup = get(editedService, "payTypeGroup", "");
  payloadService.serviceName = get(editedService, "name", laborApp.name);
  payloadService.servicePrice = get(editedService, "totalPrice", 0);
  payloadService.totalLaborPriceOverride = get(
    editedService,
    "totalLaborPriceOverride",
    null
  );
  payloadService.totalPartsPriceOverride = get(
    editedService,
    "totalPartsPriceOverride",
    null
  );
  payloadService.totalPriceOverride = get(
    editedService,
    "totalPriceOverride",
    null
  );
  payloadService.vehicleAttributes = get(
    editedService,
    "vehicleAttributes",
    null
  );
  // CSR specific fields
  payloadService.labor.laborCostMethod = get(
    catalogAPIService,
    "laborCostMethod",
    null
  );
  payloadService.labor.laborCostOverride = get(
    catalogAPIService,
    "laborCostOverride",
    null
  );
  payloadService.cause = get(editedService, "cause", null);
  payloadService.complaint = get(editedService, "complaint", null);
  payloadService.correction = get(editedService, "correction", null);
  payloadService.laborTaxCode = get(editedService, "laborTaxCode", null);
  // this is applicable when payType is warranty
  if (editedService?.payTypeCode === "W") {
    payloadService.technicians = get(editedService, "technicians", []);
    payloadService.warranty = get(editedService, "warranty", null);
  }
  if (editedService?.payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
    payloadService.serviceContract = get(
      editedService,
      "serviceContract",
      null
    );
  }
  payloadService.discountsAndFees = has(editedService, "discountsAndFees")
    ? editedService?.discountsAndFees
    : {
        discounts: [],
        fees: []
      };
  console.log(
    "update GLOBAL",
    catalogAPIService,
    editedService,
    payloadService
  );
  return payloadService;
};
// util returns first word after spliting opcode string
const formatOpcode = opCode => {
  let opCodeSelected = "";
  if (opCode) {
    const opCodes = !isEmpty(opCode) ? opCode.split(";") : [];
    opCodeSelected = !isEmpty(opCodes) ? opCodes[0] : opCode;
  }
  return opCodeSelected;
};

// util used to update PartId for parts
const refactorPartsWithPartId = service => {
  const refinedParts = service.parts.map(part => {
    const partId = !part.partId ? "" : part.partId;
    const description = has(part, "partName") ? part.partName : "";
    return { ...part, partId, description };
  });
  return refinedParts;
};

export default {
  globalOperationMapper,
  diagnosisServicesMapper,
  topServicesMapper,
  declinedServicesMapper,
  recallServiceMapper,
  isDiagnosisService,
  isGlobalCatalog,
  isDealerPublishedMaintenance,
  isDealerPublishedRepair,
  editModulePropsFromService,
  editModulePropsFromGlobalRepairService,
  editModulePropsFromDeclinedService,
  editModulePropsFromRecall,
  editModulePropsFromMenuService,
  editModulePropsForDealerTire,
  updateDeclinedService,
  updateRecallService,
  updateDealerPublished,
  updateServiceGlobalRepair,
  updateMenuService,
  updateRawOperationFromMenuService
};
