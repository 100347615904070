import IconMore from "@cx/ui/Icons/IconMore";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useEditServiceContext } from "../../state/edit-service.context";
import { TECH_TIME_STATUSES } from "../../utils/edit-service.constants";

const TechActionsCellRenderer = props => {
  const { state } = useEditServiceContext();
  const { dealerProperties, userPermissions } = state;
  const isTechTimeTrackingEnabled =
    dealerProperties?.ENABLE_DMSPLUS_TECH_TIME_CAPTURE === "Y";
  const canSeePrimaryMenuItem = userPermissions.canUpdatePrimaryTechnician;
  const canSeeOverrideMenuItem =
    isTechTimeTrackingEnabled && userPermissions.canOverrideTechTime;
  const canSeeRemoveMenuItem = userPermissions.canRemoveTechnicianRow;
  const hasMenuItemsAvailable =
    canSeePrimaryMenuItem || canSeeOverrideMenuItem || canSeeRemoveMenuItem;

  const { data, onPerformTechAction } = props;
  const isOverrideTechTimeEnabled = data.status === TECH_TIME_STATUSES.STOP;
  const [isPrimary, setIsPrimary] = useState(data?.isPrimary || false);
  const primaryTechAlreadyExists = state?.service?.technicians?.some(
    c => c.isPrimary === true
  );
  const techCount = state?.service?.technicians.length;

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className="tech-select-container">
      <div>
        {canSeePrimaryMenuItem ? (
          <div className="tech-select-item">
            <input
              className="tech-input"
              type="checkbox"
              name="isPrimary"
              checked={!!(isPrimary && primaryTechAlreadyExists)}
              disabled={
                (!isPrimary && primaryTechAlreadyExists) || techCount === 1
              }
              onChange={e => {
                onPerformTechAction({
                  actionType: "Primary",
                  data: { ...data, isPrimary: e.target.checked }
                });
                setIsPrimary(e.target.checked);
              }}
            />
            <label
              htmlFor="isPrimary"
              title="Primary"
              className={
                (!isPrimary && primaryTechAlreadyExists) || techCount === 1
                  ? "disabled"
                  : ""
              }
            >
              Primary
            </label>
          </div>
        ) : null}
        {canSeeOverrideMenuItem ? (
          <div className="tech-select-item">
            <button
              className="btn-link btn-gray"
              disabled={!isOverrideTechTimeEnabled}
              title={
                isOverrideTechTimeEnabled
                  ? ""
                  : 'Only available for technician lines with "Time ended" status.'
              }
              onClick={() => {
                hide();
                onPerformTechAction({
                  actionType: "Override",
                  data
                });
              }}
            >
              Override actual time
            </button>
          </div>
        ) : null}
        {canSeeRemoveMenuItem ? (
          <>
            <div className="tech-hr" />
            {/* REFERENCE: Delete tech time - User Story: https://rally1.rallydev.com/#/644875473405d/myrally?detail=%2Fuserstory%2F819069117933%2Fdetails&fdp=true */}
            <div className="tech-select-item">
              <button
                data-testid="removeTechnicianMenuItem"
                className={
                  isTechTimeTrackingEnabled && data?.workStarted
                    ? "btn-link btn-gray disabled"
                    : "btn-link btn-gray "
                }
                onClick={() => {
                  hide();
                  onPerformTechAction({
                    actionType: "Remove",
                    data
                  });
                }}
              >
                Remove
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom-end"
    >
      <button
        data-testid={`techActionMenuIcon_${data.techTimeId}`}
        className="btn-link tech-action-menu"
        onClick={visible ? hide : show}
        size="sm"
        disabled={!hasMenuItemsAvailable}
      >
        <IconMore htmlId="techActionMenuIcon" />
      </button>
    </Tippy>
  );
};

export default TechActionsCellRenderer;

TechActionsCellRenderer.propTypes = {
  data: PropTypes.shape({
    techTimeId: PropTypes.number,
    workStarted: PropTypes.string,
    status: PropTypes.string,
    isPrimary: PropTypes.bool
  }),
  onPerformTechAction: PropTypes.func
};
