/**
 * Updates Opener Window (if detected), otherwise, opens a new tab with a custom URL
 *
 * @param {string} URL
 * @param {boolean} closeWindow
 * @return void
 */
const updateOpenerWindow = (URL, target, openInSameTab = false) => {
  if (window.opener) {
    window.opener.location.href = URL;
    window.self.close();
  } else if (openInSameTab) {
    window.parent.postMessage(
      {
        action: "SCHEDULE_APPT_FROM_CSR",
        deepLink: URL
      },
      window.location.ancestorOrigins[0]
    );
  } else {
    window.open(URL, target);
  }
};

export { updateOpenerWindow };
