import isEmpty from "lodash/isEmpty";
import {
  getPayloadForPartsInventoryUsingQuoteService,
  readPartsFromQuoteService,
  findMatchingMenuService,
  isPartInstock
} from "../../page-wrapper/utils/data-util";
import globalOperationsService from "../../page-wrapper/services/global-operations.service";
import axiosService, { makeSecureRestApi } from "../../../api/xmmAxios";
import isNumber from "lodash/isNumber";
import has from "lodash/has";

/**
 *
 * @param {string} payType
 * @param {Vehicle} vehicle
 * @param {Object} currentEditingService
 * @param {Object} abortSignal - axiosInstance
 * @returns parts | Array<any>
 */
// This method return DMS data for a quote/ro service level parts only
// called in Quote/RO Summary page - Service level to show in-stock labels
const getDMSPartsPricingForService = async ({
  payType,
  serviceType,
  vehicle,
  service,
  commonConsumerId,
  abortSignal
}) => {
  if (isEmpty(service.parts)) {
    return;
  }
  let partsPricingAndInventory = [];
  try {
    const payTypeCode = payType || "";
    const serviceTypeCode = serviceType || "";
    // @workaround - if operationSource missing in service datamodel
    const operationSource = !service.operationSource
      ? service.quoteServiceType
      : service.operationSource;
    // service case:  payload should read parts from service
    const postParams = readPartsFromQuoteService(
      service,
      vehicle,
      payTypeCode,
      serviceTypeCode,
      operationSource,
      commonConsumerId
    );
    partsPricingAndInventory =
      await globalOperationsService.getPartsPricingAndInventory(
        null,
        postParams,
        abortSignal
      );
  } catch (error) {
    partsPricingAndInventory = [];
    console.log(
      "Summary page QuoteService - opentrack API failed with error",
      error
    );
  }
  return partsPricingAndInventory;
};

/**
 *
 * @param {string} payType
 * @param {Vehicle} vehicle
 * @param {Object} currentEditingService
 * @param {Object} abortSignal - axiosInstance
 * @returns parts | Array<any>
 */
// Review this method called from modify part wrapper and edit service hoc
const asyncHandleGetPartsInventoryCommon = async ({
  currentEditingService,
  payType,
  vehicle,
  commonConsumerId,
  abortSignal
}) => {
  if (isEmpty(currentEditingService.parts)) {
    return;
  }
  let partsPricingAndInventory = [];
  try {
    const payTypeCode = payType || "";
    // issue:For menu, operationSource is null in Quote; such as pass "quoteServiceType" read from quoteServices[]
    const operationSource = !currentEditingService.operationSource
      ? currentEditingService.quoteServiceType
      : currentEditingService.operationSource;
    // issue: common payload util need correction; one for menu case, other for all services case;
    // service case:  payload should read quoteServices[i].extServiceId, parts
    // menuService case: payload should read menuServices[i].extServiceId, parts
    const postParams = getPayloadForPartsInventoryUsingQuoteService(
      currentEditingService,
      vehicle,
      payTypeCode,
      operationSource,
      commonConsumerId,
      currentEditingService.serviceTypeCode
    );
    partsPricingAndInventory =
      await globalOperationsService.getPartsPricingAndInventory(
        null,
        postParams,
        abortSignal
      );
  } catch (error) {
    partsPricingAndInventory = [];
    console.log(
      "[service-details service] called DMS opentrack API failed with error",
      error
    );
  }
  return partsPricingAndInventory;
};

const updateDisplayOrderForServices = (confirmationId, updatedServices) => {
  updatedServices.forEach(service =>
    patchDisplayOrder(confirmationId, service)
  );
};

/**
 * Patch the displayOrder of a service
 * @param {*} confirmationId
 * @param {*} service
 * @returns a Promise of the patch async call
 */
const patchDisplayOrder = (confirmationId, service) => {
  const { quoteServiceId: serviceId, displayOrder } = service;
  const restUrl = `service/${serviceId}/quote/{dealerCode}/${confirmationId}`;
  const data = { displayOrder };

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save sorting changes."
    );
  });
};
/**
 * Updates OverridePrevRoNumber field of an specific service
 * @param {String} confirmationId
 * @param {Object} service
 * @return {Promise<Object>}
 */
export const updateOverridePrevRoNumber = (confirmationId, service) => {
  const { quoteServiceId: serviceId, overridePrevRONumber } = service;
  const restUrl = `service/${serviceId}/quote/{dealerCode}/${confirmationId}`;
  const data = { overridePrevRONumber };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save overridePrevRoNumber"
    );
  });
};
/**
 * Creates a Pre-RO based on selected services from an RO.
 * @param {String} confirmationId
 * @param {Number[]} serviceIds
 * @param {String} reason
 * @return {Promise<Object>}
 */
export const createPreRo = (confirmationId, serviceIds, reason) => {
  const restUrl = `csr/dealerCode/{dealerCode}/${confirmationId}/createPreRO`;
  const data = serviceIds;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        headers: { "x-reason": reason },
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to create a Pre RO"
    );
  });
};

const fetchDmsParts = async (
  newService,
  menuServices,
  appConfig,
  vehicle,
  commonConsumerId
) => {
  const menuService = findMatchingMenuService(menuServices, newService);
  axiosService.setupAxios(appConfig);
  const abortController = axiosService.newAbortController();
  // @note: Only call when menuService has parts to read DMS parts data
  let parts = [];
  if (!isEmpty(menuService.parts)) {
    parts = await getDMSPartsPricingForService({
      payType: newService.payTypeCode,
      vehicle,
      service: menuService,
      commonConsumerId: commonConsumerId || "",
      abortSignal: abortController.signal
    });
  }
  const newParts = newService.parts.map(part => {
    const foundPart = parts.find(p => p.partNumber === part.oemPartNumber);
    const isQtyExists = foundPart && has(foundPart, "quantityAvailable");
    const qtyAvailable = isQtyExists
      ? Number(foundPart.quantityAvailable)
      : null;
    const inStock =
      foundPart && isPartInstock(part.adjustedQuantity, qtyAvailable);
    return {
      ...part,
      ...(foundPart && isNumber(qtyAvailable) ? { inStock } : {}),
      dmsPending: false,
      quantityAvailable: qtyAvailable
    };
  });
  abortController.abort();
  return newParts;
};

export {
  asyncHandleGetPartsInventoryCommon,
  getDMSPartsPricingForService,
  updateDisplayOrderForServices,
  fetchDmsParts
};
