import { partStatusWithSpecialOrderParts } from "../../../constants/app.constants";

export const isApprovedAllPartsEnabled = parts => {
  if (parts?.length === 0) return false;

  for (const p of parts) {
    if (p.lifecycleState !== partStatusWithSpecialOrderParts.OPEN) {
      return false;
    }
  }

  return true;
};

/**
 * common utility to transfer service labor time to shopDuration property
 * @param {object} service
 * @returns modified service object
 */
export const mapLaborTimeToShopDuration = service => {
  /**
   * 1. Cap to 120 minutes, if labor is greater than 2hrs
   * 2. Copy labor time to shopDuration
   */
  const maxTimeInMinutes = 120;
  const { labor } = service || {};
  service.shopDuration = +labor.laborTime;
  if (+labor.laborTime > maxTimeInMinutes)
    service.shopDuration = maxTimeInMinutes;
  return service;
};
