import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import TextArea from "@cx/ui/TextArea";
import "./common-modal.scss";

const alertIconType = {
  danger: "danger",
  warning: "warning",
  info: "info",
  success: "success"
};

const buttonStyle = {
  danger: "danger",
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary"
};

/**
 * Reusable modal for common modal dialog needs.
 * @param props.show {bool} Whether the modal should be visible.
 * @param props.onHide {function} Handler for when the "X" in the top right corner gets clicked.
 * @param props.className {string?} Custom CSS class name for the modal. (Optional)
 * @param props.title {string} Title to display in the modal header.
 * @param props.message {message} Message to display below the modal header. (Optional)
 * @param props.alert {object?} Details about the alert section. (Optional)
 * @param props.alert.type {alertIconType} Which alert icon to display. (info, warning, danger, or success)
 * @param props.alert.message {Element} Alert message content. Can be a JSX element, or a simple string.
 * @param props.reasonProps {object?} Properties for the Reason input. (Optional)
 * @param props.reasonProps.isRequired {boolean} Whether the reason input is a required field.
 * @param props.reasonProps.characterLimit {number?} Maximum reason characters allowed. (Optional; defaults to 512.)
 * @param props.buttons {object[]} Array of buttons to show in the modal footer. They will be shown in the order they are defined.
 * @param props.button.testId {string} Value for this button's data-testid attribute.
 * @param props.button.style {buttonStyle} Style for this button.  (danger, primary, secondary, or tertiary)
 * @param props.button.text {string} Text to display for this button.
 * @param props.button.onClick {function} Click handler for this button.
 * @returns {Element}
 * @see [CommonModal documentation](https://coxautoinc.sharepoint.com/sites/community-xtimewiki/_layouts/15/Doc.aspx?sourcedoc={118c2628-28e0-4325-a437-29925b1a9dcc}&action=edit&wd=target%28common%20reusable%20components.one%7C4ca47cdd-ed40-4432-a7f2-dabf89921a82%2FCommon%20Modal%7C899a87a8-b9d5-4141-9f52-915a6b31adb9%2F%29&wdorigin=703)
 */
const CommonModal = props => {
  const {
    show,
    onHide,
    className,
    title,
    message,
    alert,
    reasonProps,
    buttons,
    children
  } = props;

  const reasonCharacterLimit = reasonProps?.characterLimit ?? 512;

  const [reason, setReason] = useState("");

  const formatNumber = value => {
    return value.toLocaleString("en-US");
  };

  const buildPrimaryButtonTooltip = useCallback(() => {
    if (!reasonProps) {
      return "";
    }
    const trimmedLength = reason.trim().length;
    if (trimmedLength === 0) {
      return "Reason is required";
    } else if (trimmedLength > reasonCharacterLimit) {
      return `Reason cannot be longer than ${formatNumber(
        reasonCharacterLimit
      )} characters`;
    } else {
      return "";
    }
  }, [reason, reasonProps, reasonCharacterLimit]);

  const footer = (
    <div className="common-modal-footer">
      {buttons?.map(button => {
        const isPrimaryOrDanger = [
          "danger",
          "primary",
          undefined,
          null
        ].includes(button.style);
        const isDisabled = isPrimaryOrDanger
          ? (reasonProps?.isRequired && reason.trim().length === 0) ||
            (reasonProps && reason.trim().length > reasonCharacterLimit)
          : false;
        return (
          <Button
            key={button.testId}
            htmlId={button.testId}
            data-testid={button.testId}
            type="button"
            buttonStyle={button.style ?? buttonStyle.primary}
            onClick={() => button.onClick(reason)}
            disabled={isDisabled}
            title={buildPrimaryButtonTooltip()}
          >
            {button.text}
          </Button>
        );
      })}
    </div>
  );

  return (
    <ModalDialog
      htmlId="commonModal"
      data-testid="commonModal"
      className={`common-modal ${className}`}
      show={show ?? false}
      header={<div data-testid="commonModalTitle">{title}</div>}
      onHide={onHide}
      footer={footer}
    >
      <>
        {message ? (
          <div className="message" data-testid="commonModalMessage">
            {message}
          </div>
        ) : null}
        {alert ? (
          <div
            data-testid="commonModalAlert"
            className={`common-modal-alert ${alert.type}`}
          >
            <Alert htmlId="commonModalAlert" type={alert.type}>
              {alert.message}
            </Alert>
          </div>
        ) : null}
        {reasonProps ? (
          <div
            data-testid="commonModalReasonSection"
            className="common-modal-reason-section"
          >
            <div className="label-with-character-count">
              <label htmlFor="reasonInput" data-testid="commonModalReasonLabel">
                Reason
                {reasonProps.isRequired ? (
                  <span
                    className="required-indicator"
                    data-testid="commonModalReasonRequiredIndicator"
                  >
                    {" "}
                    *
                  </span>
                ) : null}
              </label>
              <span
                id="reasonCharacterCount"
                data-testid="commonModalReasonCharacterCountSection"
                className={`character-count ${
                  reason.trim().length > reasonCharacterLimit ? "error" : ""
                }`}
              >
                Character count:&nbsp;
                <span data-testid="commonModalReasonCharacterCount">
                  {reason.trim().length.toLocaleString("en-US")}
                </span>
                /
                <span data-testid="commonModalReasonCharacterLimit">
                  {reasonCharacterLimit.toLocaleString("en-US")}
                </span>
              </span>
            </div>
            <TextArea
              htmlId="reasonInput"
              data-testid="commonModalReasonInput"
              name="reasonInput"
              label="Reason"
              onChange={cxEvent => setReason(cxEvent.target.value)}
              onBlur={cxEvent => setReason(cxEvent.target.value.trim())}
              value={reason}
              displayLabel={false}
              rows={3}
            />
          </div>
        ) : null}
        {children}
      </>
    </ModalDialog>
  );
};

CommonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  alert: PropTypes.shape({
    type: PropTypes.oneOf([
      alertIconType.info,
      alertIconType.warning,
      alertIconType.danger,
      alertIconType.success
    ]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }),
  reasonProps: PropTypes.shape({
    isRequired: PropTypes.bool,
    characterLimit: PropTypes.number
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string,
      style: PropTypes.oneOf([
        buttonStyle.primary,
        buttonStyle.secondary,
        buttonStyle.tertiary,
        buttonStyle.danger
      ]),
      text: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

export { CommonModal, alertIconType, buttonStyle };
