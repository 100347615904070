import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { AppContext } from "../state/app-context";
import { useNewQuoteContext, Actions } from "../state/NewQuoteContext";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
// @import custom components
import BodyMask from "../features/ui/reusable/common/body-mask.component";
import CustomerVehicleHeader from "../features/quote/quote-header";
import RepairOrderHeader from "../features/quote-summary/components/ro-details-header/ro-details-header.component";
import { ErrorNotification } from "../features/ui/reusable/errormodule";
import { NoAccess } from "../features/ui/reusable/common";
import ObjectViewer from "../features/ui/reusable/object-viewer/object-viewer.component";
import {
  SearchServiceWrapper,
  EditServiceWrapper,
  MenuPackageWrapper,
  CustomerSearchWrapper,
  QuoteSummaryWrapper
} from "../features/page-wrapper";
// @import services
import { loadSupportedMakes } from "../features/page-wrapper/services/makes.service";
import { loadQuote } from "../features/page-wrapper/services/quote-api.service";
import appServices from "../services/app.service";
import csrService from "../features/quote-summary/services/csr.service";
import customerService from "../features/customer/services/search-customer.service";
import {
  getPayTypeDetails,
  getServiceTypeDetails,
  getCustomerAndVehicle,
  loadDrivingConditionsData
} from "../features/page-wrapper/services/customer-vehicle.service";
import {
  getAdvisorTechnicianList,
  getTransportationList
} from "../features/page-wrapper/services/advisors.service";
// @import utils
import { isDev } from "../utils/is-dev";
import { doesEmpty } from "../utils/object";
import { checkIfStandaloneApp } from "../api/app.util";
import {
  getVariantForVehicle,
  transformQuoteCustomerAndVehicle,
  updateVin
} from "../features/utils/data-transformer";
import {
  CUSTOMER_SEARCH,
  SERVICE_SUMMARY,
  CUSTOMER_SEARCH_WRAPPER,
  SEARCH_SERVICE_WRAPPER,
  QUOTE_SUMMARY_WRAPPER,
  EDIT_SERVICE_WRAPPER,
  EDIT_MENU_PACKAGE_WRAPPER,
  SEARCH_SERVICE,
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER,
  VEHICLE_RESULTS,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  SKIP_CUSTOMER
} from "../constants/pages.constants";
// @import css
import "./page-wrapper.scss";
import QuoteStatusConstant, {
  roStatusOptions
} from "../constants/quote-status.constants";
import { appSources, appTypes } from "../constants/app.constants";
import { checkChargeAccountValid } from "../features/page-wrapper/utils/quote-util";
import { doesServiceContractExists } from "../utils/quote.util";
import useComponentDidMount from "../hooks/useComponentDidMount";
import {
  filterServiceContracts,
  getServiceContractsInfo$,
  getVendorListTransformed
} from "../features/service-contract/utils/service-contracts.util";
import { getInventoryPartsLocations } from "../features/quote-summary/services/modify-parts.service";

const PageWrapper = props => {
  const appContext = useContext(AppContext);
  const {
    quote,
    appSource,
    locale,
    localeStrings,
    schemaName,
    dealer,
    userPermissions,
    appType,
    isPartsView,
    isDebug,
    dealerProperties
  } = appContext;
  const isEmergencyPartsEnabled =
    dealerProperties?.ENABLE_DMSPLUS_EMERGENCY_PARTS === "Y";
  const { dealerCode, dmsType } = dealer;
  const { customerId, vehicleId, quoteId, repairOrder } = quote;
  const { state, dispatch } = useNewQuoteContext();
  const {
    currentPage,
    showPageMask,
    customer,
    vehicle,
    pageTitle,
    pageSubtitle,
    accessType
  } = state;
  const [canAccess, setCanAccess] = useState(true);
  const [pageName, setPageName] = useState("");
  const [ready, setReady] = useState(false);
  const [noAccessTitle, setNoAccessTitle] = useState(
    localeStrings["sq.common.app_name"]
  );
  const [noAccessMessage, setNoAccessMessage] = useState(
    localeStrings["sq.errors.no_access_msg"]
  );
  const showObjectViewer =
    isDev() && !window.location.href.includes("&hideObjectViewer=1");

  const pagesToHideHeader = [
    SEARCH_SERVICE,
    EDIT_SERVICE_WRAPPER,
    EDIT_MENU_PACKAGE_WRAPPER
  ];

  const showHeader =
    (appType === "CSR" && currentPage !== EDIT_CUSTOMER) ||
    pagesToHideHeader.includes(currentPage) ||
    currentPage === SERVICE_SUMMARY
      ? false
      : true;
  const enableVehicleResults = customer => {
    setReady(true);
    dispatch({
      type: Actions.SET_CUSTOMER,
      payload: {
        customer,
        isNewCustomer: false
      }
    });
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: VEHICLE_RESULTS
    });
  };
  // IMPORTANT: Make necessary calls to retrieve initial data first time when Quote slider initiated
  useComponentDidMount(() => {
    let mounted = true;
    const loadMasterData = async () => {
      const { makeList, makeVariantMap } = await loadSupportedMakes(
        dealer,
        localeStrings
      );
      dispatch({
        type: Actions.SET_MAKES,
        payload: makeList
      });
      dispatch({
        type: Actions.SET_MAKE_VARIANT_MAP,
        payload: makeVariantMap
      });
      // @note: update debugMode in Newquote context reading from appContext as well
      dispatch({
        type: Actions.SET_DEBUG_MODE,
        payload: appContext.isDebug
      });
      dispatch({
        type: Actions.SET_DEALER_PROPERTIES,
        payload:
          appContext.dealerProperties ??
          JSON.parse(sessionStorage.getItem("dealerProperties"))
      });
      dispatch({
        type: Actions.SET_USER_PERMISSIONS,
        payload: appContext.userPermissions
      });
      return { makeList, makeVariantMap };
    };

    const setChargeAccountInfo = async quoteData => {
      // sometimes dealerProperties aren't loaded at this point, so load them if necessary
      const dealerPropertiesToUse =
        dealerProperties ??
        (await appServices.loadDealerProperties(dealerCode));
      // async call for getting charge account info
      if (checkChargeAccountValid(appType, quoteData, dealerPropertiesToUse)) {
        try {
          const response = await appServices.getChargeAccountInfo(
            dealerCode,
            checkChargeAccountValid(appType, quoteData, dealerPropertiesToUse)
          );
          if (response) {
            dispatch({
              type: Actions.SET_CUSTOMER_CHARGE_ACCOUNT_INFO,
              payload: response
            });
          }
        } catch (e) {
          dispatch({
            type: Actions.SET_CUSTOMER_CHARGE_ACCOUNT_INFO,
            payload: null
          });
        }
      }
    };

    const fetchData = async () => {
      const { makeList, makeVariantMap } = await loadMasterData();
      const payTypeSubTypes = await csrService.getPayTypeSubTypes(dealerCode);
      dispatch({
        type: Actions.SET_PAY_TYPE_SUB_TYPES,
        payload: payTypeSubTypes
      });
      let payTypeCostAllocationTypes = [];
      try {
        payTypeCostAllocationTypes =
          await csrService.getPayTypeCostAllocationTypes(dealerCode);
      } catch (e) {
        console.log(e, payTypeCostAllocationTypes);
      }
      dispatch({
        type: Actions.SET_PAY_TYPE_COST_ALLOCATION_TYPES,
        payload: payTypeCostAllocationTypes
      });
      if (appType === "CSR") {
        let advisorTechnicianList = {};
        try {
          advisorTechnicianList = await getAdvisorTechnicianList(
            dealer,
            localeStrings
          );
        } catch (e) {
          advisorTechnicianList = {
            serviceAdvisors: [],
            technicians: [],
            partsPersons: []
          };
          console.error("Unable to fetch advisorTechnicianList list", e);
        }
        dispatch({
          type: Actions.SET_ADVISOR_TECHNICIAN_LIST,
          payload: advisorTechnicianList
        });
      }
      if (appContext?.isCreateSpecialPartsEnabled && appType === "CSR") {
        let specialOrderPriorityList = [];
        try {
          const response = await appServices.getSpecialOrderPriorities(
            dealerCode
          );
          specialOrderPriorityList = response?.items;
          dispatch({
            type: Actions.SET_SPECIAL_ORDER_PRIORITIES,
            payload: specialOrderPriorityList
          });
        } catch (e) {
          console.log(e, specialOrderPriorityList);
        }
      }
      let landingPage = currentPage;
      if (!doesEmpty(quoteId) || !doesEmpty(repairOrder)) {
        let data = null;
        if (!doesEmpty(repairOrder)) {
          data = repairOrder;
        } else {
          try {
            data = await loadQuote({
              confirmationId: quoteId,
              localeStrings,
              dealerCode,
              appContext
            });
          } catch (e) {
            setReady(false);
            setCanAccess(false);
            return;
          }
        }
        if (mounted) {
          if (!isEmpty(data)) {
            const { quoteId, confirmationId, quoteStatus, customer, vehicle } =
              transformQuoteCustomerAndVehicle(data);
            // @note: this code block should consider appType and status to render specific wrapper component
            // @todo: if we need to land on service search page when no services under quote; need to check data.quoteServices and redirect to SEARCH_SERVICE_WRAPPER
            let page = QUOTE_SUMMARY_WRAPPER; // @note: set default wrapper here
            if (appType === appTypes.CSR) {
              page = QUOTE_SUMMARY_WRAPPER;
              landingPage = SERVICE_SUMMARY;
            } else if (appType === appTypes.SQ) {
              page =
                quoteStatus === QuoteStatusConstant.PROGRESS ||
                quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE ||
                quoteStatus === QuoteStatusConstant.SENT ||
                quoteStatus === QuoteStatusConstant.READY_TO_SEND ||
                quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
                quoteStatus === QuoteStatusConstant.EXPIRED
                  ? QUOTE_SUMMARY_WRAPPER
                  : CUSTOMER_SEARCH_WRAPPER;
              // @note: this code block should consider appType and status to render specific landing page
              // @todo: if we need to land on service search page when no services under quote; need to check data.quoteServices and redirect to SEARCH_SERVICE
              landingPage =
                quoteStatus === QuoteStatusConstant.PROGRESS ||
                quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE ||
                quoteStatus === QuoteStatusConstant.SENT ||
                quoteStatus === QuoteStatusConstant.READY_TO_SEND ||
                quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
                quoteStatus === QuoteStatusConstant.EXPIRED
                  ? SERVICE_SUMMARY
                  : CUSTOMER_SEARCH;
            }

            let newVehicle = null;
            // added makeVariantMap check to allow ANY catalog quotes to open as we allow them to create.
            if (
              !isEmpty(vehicle) &&
              (!isEmpty(makeList) || !isEmpty(makeVariantMap))
            ) {
              const payTypesData = await getPayTypeDetails(
                dealerCode,
                vehicle.make
              );
              dispatch({
                type: Actions.SET_PAY_TYPES,
                payload: payTypesData
              });
              const serviceTypesData = await getServiceTypeDetails(
                dealerCode,
                vehicle.make,
                dmsType
              );
              dispatch({
                type: Actions.SET_SERVICE_TYPES,
                payload: serviceTypesData || []
              });
              if (doesServiceContractExists(payTypesData)) {
                // TODO: uncomment this lines once vendors API is working
                // const vendorListData = await getVendorListDetails(
                //   dealerCode,
                //   vehicle.make
                // );
                // dispatch({
                //   type: Actions.SET_VENDOR_LIST,
                //   payload: vendorListData || []
                // });

                if (isCSRApp) {
                  const serviceContracts = await getServiceContractsInfo$(
                    dealerCode,
                    vehicle?.vin,
                    customer?.commonConsumerId
                  );

                  dispatch({
                    type: Actions.SET_SERVICE_CONTRACTS,
                    payload: filterServiceContracts(serviceContracts)
                  });

                  dispatch({
                    type: Actions.SET_VENDOR_LIST,
                    payload: getVendorListTransformed(serviceContracts)
                  });

                  if (isPartsView && isEmergencyPartsEnabled) {
                    const inventoryPartsLocation =
                      await getInventoryPartsLocations(dealer);

                    dispatch({
                      type: Actions.SET_INVENTORY_PARTS_LOCATION,
                      payload: inventoryPartsLocation || []
                    });
                  }
                }
              }
              vehicle.vin = updateVin(vehicle);
              vehicle.variant = getVariantForVehicle(vehicle, makeList);
              // @todo: skip-customer case, update variant from supported makeList
              newVehicle = await loadDrivingConditionsData(
                vehicle,
                dealerCode,
                locale
              );
              dispatch({
                type: Actions.SET_VEHICLE,
                payload: newVehicle
              });
              if (!isEmpty(customer)) {
                customer.tempCCID = customer.commonConsumerId;
                dispatch({
                  type: Actions.SET_CUSTOMER,
                  payload: {
                    customer,
                    isNewCustomer: false
                  }
                });
              }
              // @note: skip customer flow, customer{} will be empty in quote, expected landing page as summary page
              dispatch({
                type: Actions.SET_CURRENT_PAGE,
                payload: landingPage
              });
              setReady(true);
              setPageName(page);
              dispatch({
                type: Actions.SET_EDIT_CUSTOMER_VEHICLE_ACCESS,
                payload: false
              });
            }
            // @note: skip customer flow, vehicleId is empty in quote, try to read metavehicleId from quote (checked to show vehicle card)
            dispatch({
              type: Actions.SET_QUOTE_PARAMS,
              payload: {
                customerId: !isEmpty(customer) ? customer.personId : null,
                vehicleId:
                  !isEmpty(vehicle) && has(vehicle, "vehicleId")
                    ? vehicle.vehicleId
                    : null,
                accessType: !quoteId ? null : quoteStatus,
                appType, // @csr-logic
                isPartsView, // @csr-logic
                quoteId: confirmationId
              }
            });
            // @important: must update past quote to NewQuote context here
            dispatch({
              type: Actions.SET_QUOTE,
              payload: data
            });
            dispatch({
              type: Actions.SET_ORIGINAL_QUOTE,
              payload: data
            });
            setChargeAccountInfo(data);
            // logic to check user permission for deeplink
            if (!isEmpty(userPermissions) && quoteStatus) {
              if (
                (quoteStatus === QuoteStatusConstant.PROGRESS ||
                  quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE ||
                  quoteStatus === QuoteStatusConstant.SENT ||
                  quoteStatus === QuoteStatusConstant.READY_TO_SEND ||
                  quoteStatus ===
                    QuoteStatusConstant.CONVERTED_TO_APPOINTMENT) &&
                !userPermissions.canEditQuote
              ) {
                setCanAccess(false);
                setReady(false);
                setNoAccessTitle("");
                setNoAccessMessage(
                  localeStrings["sq.errors.edit_quote_access"]
                );
              }
            }
          }
        }
      } else if (!doesEmpty(customerId) && !doesEmpty(vehicleId)) {
        // @note: deeplink case - fetch customer , vehicle data using customerId, vehicleId
        const { customer, vehicle } = await getCustomerAndVehicle({
          customerId,
          vehicleId,
          schemaName,
          dealerCode
        });
        dispatch({
          type: Actions.SET_QUOTE_PARAMS,
          payload: { customerId, vehicleId, quoteId: null, accessType: null }
        });
        let newVehicle = null;
        if (!isEmpty(customer) && isEmpty(vehicle)) {
          enableVehicleResults(customer);
        } else if (isEmpty(customer)) {
          setReady(false);
          setCanAccess(false);
        }
        if (!isEmpty(vehicle) && !isEmpty(makeList)) {
          const serviceTypesData = await getServiceTypeDetails(
            dealerCode,
            vehicle.make,
            dmsType
          );
          dispatch({
            type: Actions.SET_SERVICE_TYPES,
            payload: serviceTypesData
          });
        }
        if (!isEmpty(vehicle) && !isEmpty(makeList)) {
          const payTypesData = await getPayTypeDetails(
            dealerCode,
            vehicle.make
          );
          dispatch({
            type: Actions.SET_PAY_TYPES,
            payload: payTypesData
          });
          vehicle.vin = updateVin(vehicle);
          vehicle.variant = getVariantForVehicle(vehicle, makeList);
          newVehicle = await loadDrivingConditionsData(
            vehicle,
            dealerCode,
            locale
          );
          if (doesServiceContractExists(payTypesData)) {
            if (isCSRApp) {
              const serviceContracts = await getServiceContractsInfo$(
                dealerCode,
                vehicle?.vin,
                customer?.commonConsumerId
              );

              dispatch({
                type: Actions.SET_SERVICE_CONTRACTS,
                payload: filterServiceContracts(serviceContracts)
              });

              dispatch({
                type: Actions.SET_VENDOR_LIST,
                payload: getVendorListTransformed(serviceContracts)
              });
            }
            // TODO: uncomment this lines once vendors API is working
            // const vendorListData = await getVendorListDetails(
            //   dealerCode,
            //   vehicle.make
            // );
            // dispatch({
            //   type: Actions.SET_VENDOR_LIST,
            //   payload: vendorListData || []
            // });
          }
          // @todo when customer is invalid, show NoAccessPage
          // @todo when vehicle is invalid, show NoAccessPage
          // @todo when vehicle is missing, land on CUSTOMER_SEARCH_WRAPPER and currentPage must be VEHICLE_RESULTS
          if (!isEmpty(customer)) {
            dispatch({
              type: Actions.SET_VEHICLE,
              payload: newVehicle
            });
            dispatch({
              type: Actions.SET_CUSTOMER,
              payload: {
                customer,
                isNewCustomer: false
              }
            });
            dispatch({
              type: Actions.SET_CURRENT_PAGE,
              payload: SEARCH_SERVICE
            });
          }
          setReady(true);
          setPageName(SEARCH_SERVICE_WRAPPER);
          dispatch({
            type: Actions.SET_EDIT_CUSTOMER_VEHICLE_ACCESS,
            payload: false
          });
        }
        // @todo: special case - when customerId only passed, deep link to render customer wrapper with vehcile results page
      } else if (doesEmpty(customerId) && !doesEmpty(vehicleId)) {
        setReady(false);
        setCanAccess(false);
      } else if (!doesEmpty(customerId)) {
        try {
          const response = await customerService.searchCustomerById({
            appContext,
            customerId
          });
          if (response && response.success) {
            enableVehicleResults(response.customers);
          } else {
            setReady(false);
            setCanAccess(false);
          }
        } catch {
          setReady(false);
          setCanAccess(false);
        }
      } else {
        if (appType === "CSR") {
          // Someone tried to load the RO Details page without a quoteId.
          // Redirect them to the RO dashboard if this happens.
          const params = new URLSearchParams(window.location.search);
          params.delete("quoteId");
          params.delete("roNumber");
          window.location.href = "/dashboard?" + params.toString();
          return;
        }
        setReady(true);
        setPageName(CUSTOMER_SEARCH_WRAPPER); // @note - default fallback page
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: CUSTOMER_SEARCH
        });
        dispatch({
          type: Actions.SET_EDIT_CUSTOMER_VEHICLE_ACCESS,
          payload: true
        });
        dispatch({
          type: Actions.SET_QUOTE_PARAMS,
          payload: { customerId, vehicleId, quoteId, accessType: null }
        });
      }
    };
    fetchData();
    if (isCSRApp) fetchTransportationsList();
    return () => {
      mounted = false;
      dispatch({
        type: Actions.WIPE_STATE
      });
    };
  }, []);

  const fetchTransportationsList = async () => {
    try {
      const response = await getTransportationList(dealer);
      dispatch({
        type: Actions.SET_TRANSPORTATION_LIST,
        payload: response?.transportationTypes
      });
    } catch (e) {
      console.error("error", e);
    }
  };

  useEffect(() => {
    switch (currentPage) {
      case SERVICE_SUMMARY:
        setPageName(QUOTE_SUMMARY_WRAPPER);
        break;
      case SEARCH_SERVICE:
        setPageName(SEARCH_SERVICE_WRAPPER);
        break;
      case ADD_CUSTOMER:
      case EDIT_CUSTOMER:
      case ADD_CUSTOMER_ADD_VEHICLE:
      case ADD_VEHICLE:
      case EDIT_VEHICLE:
      case VEHICLE_RESULTS:
      case SKIP_CUSTOMER:
        setPageName(CUSTOMER_SEARCH_WRAPPER);
        break;
      case EDIT_SERVICE_WRAPPER:
        setPageName(EDIT_SERVICE_WRAPPER);
        break;
      case EDIT_MENU_PACKAGE_WRAPPER:
        setPageName(EDIT_MENU_PACKAGE_WRAPPER);
        break;
      default:
        setPageName(CUSTOMER_SEARCH_WRAPPER);
        break;
    }
  }, [currentPage]);

  // TODO: placeholder logic, we will add this listener for window.close() if needed
  /* useEffect(() => {
    window.addEventListener("beforeunload", handleUnload, false);
    return () => window.removeEventListener("beforeunload", handleUnload, false);
  }, []);
  */

  // TODO: handler for window.beforeunload
  /* const handleUnload = event => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = "";
    }
    window.alert("Are you sure...");
    return "";
  } */
  const conditionalRender = page => {
    switch (page) {
      case CUSTOMER_SEARCH_WRAPPER:
        return <CustomerSearchWrapper />;
      case QUOTE_SUMMARY_WRAPPER:
        return <QuoteSummaryWrapper />;
      case SEARCH_SERVICE_WRAPPER:
        return <SearchServiceWrapper />;
      case EDIT_SERVICE_WRAPPER:
        return <EditServiceWrapper />;
      case EDIT_MENU_PACKAGE_WRAPPER:
        return <MenuPackageWrapper />;
      default:
        return <h3> No Page found</h3>;
    }
  };

  // @note: when user clicked on "Reset Quote", this action will reset NewQuote state
  const handleStartNewQuote = () => {
    dispatch({
      type: Actions.NAVIGATION.START_OVER
    });
    appContext.updateQuoteParams({
      accessType: null,
      quoteId: null,
      vehicleId: null,
      customerId: null
    });
  };

  // NOTE: This is the global loadMask that should render when we want to show page level mask within page wrapper components.
  // IMPORTANT: NEVER COMMENT OR DELETE THIS CODE BLOCK
  const pagemask = showPageMask ? (
    <div className="sq-flex-grid-container">
      <BodyMask loadingText="" />
    </div>
  ) : null;
  const stepperCls =
    appSource !== "IND" || props.module === "SLIDER"
      ? "sticky-page-header border-top border-sides"
      : "sticky-page-header top-80 position-fixed";
  // @csr-logic
  const isStandalone = checkIfStandaloneApp(appSource);
  const customerEditSectionCSS =
    appType === appTypes.CSR && currentPage === EDIT_CUSTOMER
      ? "customer-edit-section"
      : "";
  const contentCls =
    props.module === "SLIDER" || appSource !== "IND"
      ? `quote-slider-container slider-content ${customerEditSectionCSS}`
      : isStandalone && appType !== "CSR"
      ? `quote-center-container ${"padding-top-0"}`
      : `quote-center-container ${"padding-top-0"}`;

  let content = (
    <div style={{ margin: 25 }}>
      <LoadingIndicator htmlId="LoadingMask" size="large" />
    </div>
  );
  if (pageName && ready) {
    content = conditionalRender(pageName);
  } else if (!canAccess) {
    content = <NoAccess title={noAccessTitle} message={noAccessMessage} />;
  }
  const pageReady = pageName && ready;
  const enableStartOver = () => {
    const isDeeplink =
      !isEmpty(quoteId) || !isEmpty(customerId) || !isEmpty(vehicleId);
    switch (true) {
      case isDeeplink:
      case accessType === "EDIT":
        return false;
      case !isEmpty(vehicle || customer):
        return true;
      default:
        return false;
    }
  };

  const quoteTitle = !quoteId
    ? localeStrings["sq.nav.new_quote"]
    : localeStrings["sq.pastquotes.edit_quote_lbl"];

  const appEntity = appType === "CSR" ? "RO" : "Quote";
  const quoteStatuslbl = !isEmpty(state.quoteSummary)
    ? state?.quoteSummary?.quoteStatus
    : "";

  // @note: When is RO opened from Engage, quoteTitle and CustomerVehicleHeader are not rendered,
  // AND, IF the RO status is WITH_ADVISOR, RepairOrderHeader is not rendered either;
  // If the RO status is other than WITH_ADVISOR, RepairOrderHeader is rendered, but some elements inside it are not.
  const isCSRApp = appType === appTypes.CSR;
  const isEngageSource = appSource === appSources.ENG;
  const hasAdvisorStatus =
    state?.quoteSummary?.quoteStatus === roStatusOptions.WITH_ADVISOR;

  return (
    <>
      {pagemask}
      {appSource !== appSources.IND && !isEngageSource ? (
        <h3 className="quote-title">{quoteTitle}</h3>
      ) : null}
      {pageReady && isCSRApp && !(isEngageSource && hasAdvisorStatus) ? (
        <RepairOrderHeader />
      ) : null}
      {pageReady && !isEngageSource ? (
        <div className={stepperCls}>
          <CustomerVehicleHeader
            enableNavigationButtons={enableStartOver()}
            onStartNewQuote={handleStartNewQuote}
          />
        </div>
      ) : null}
      <div className={contentCls}>
        {isDebug ? <ErrorNotification /> : null}
        {showHeader ? (
          <div className="quote-page-header">
            {" "}
            <div className="left-block">
              <h3 className="sq-h3">{pageTitle} </h3>
              <span className="quote-label">
                {appContext.isDebug ? quoteStatuslbl : null}{" "}
              </span>
            </div>
            {!pageSubtitle ||
            (appType === appTypes.CSR && currentPage === EDIT_CUSTOMER) ? (
              ""
            ) : (
              <div className="right-block">
                {appEntity} # {pageSubtitle}
              </div>
            )}
          </div>
        ) : null}
        {content}
        {showObjectViewer ? (
          <ObjectViewer data={state} label="Quote context" />
        ) : null}
      </div>
    </>
  );
};

export default PageWrapper;

PageWrapper.defaultProps = {
  module: "IND"
};

PageWrapper.propTypes = {
  module: PropTypes.string
};
