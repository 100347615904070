import { makeSecureRestApi } from "../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";

export const getAdvisorTechnicianList = async ({
  dealerCode,
  schemaName,
  localeStrings
}) => {
  const restUrl = `/user/dealer/${dealerCode}/listOfSpecializedUsers`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: {
          schema: schemaName
        }
      },
      response => {
        resolve(response);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.api_failure_msg"];
        console.error(msg);
        reject(error);
      },
      "Unable to retrieve employee lists."
    );
  });
};

//* CSR delete technician from service
export const removeTechnicianFromService = async ({
  dealerCode,
  confirmationId,
  serviceId,
  techTimeId
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}/service/${serviceId}/technician/${techTimeId}/techtime`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "delete"
      },
      response => {
        resolve(response);
      },
      error => {
        console.error(error);
        reject(error);
      },
      "Unable to remove technician."
    );
  });
};

//* CSR get Expense code for warranty pay type  service
export const getExpenseCodeForWarranty = async ({
  dealerCode,
  make,
  countryCode
}) => {
  const restUrl = `service/${dealerCode}/listOfExpenseCodes?make=${make}&countryCode=${countryCode}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        console.error(error);
        reject(error);
      },
      "Unable to retrieve warranty expense codes."
    );
  });
};

//* CSR start tech time
export const startTimeForTechnician = async ({
  dealerCode,
  confirmationId,
  serviceId,
  techTimeId,
  payload
}) => {
  const restUrl = `/quote/${dealerCode}/${confirmationId}/service/${serviceId}/technician/${techTimeId}/techtime/start`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        console.error(error);
        if (error.response.status === 400) {
          const openTechLines = error.response.data?.openTechLines;
          if (openTechLines?.length) {
            const { roNumber, serviceLineNumber } = openTechLines[0];
            const errorMessage = `Clock running on RO ${roNumber} - line ${serviceLineNumber}`;
            toast.error(errorMessage);
            reject({
              error,
              errorMessage
            });
          }
        } else {
          toast.error("Unable to start working time for technician.");
          reject({
            error
          });
        }
      }
    );
  });
};

//* CSR stop tech time
export const stopTimeForTechnician = async ({
  dealerCode,
  confirmationId,
  serviceId,
  techTimeId,
  payload
}) => {
  const restUrl = `/quote/${dealerCode}/${confirmationId}/service/${serviceId}/technician/${techTimeId}/techtime/stop`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        console.error(error);
        reject(error);
      },
      "Unable to stop working time for technician."
    );
  });
};

export const addTechnicianToService = async ({
  dealerCode,
  confirmationId,
  serviceId,
  payload
}) => {
  const restUrl = `service/${serviceId}/dealerCode/${dealerCode}/${confirmationId}/jsonPatch`;
  const data = payload;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save technician."
    );
  });
};

export const overrideTechnicianTime = async ({
  dealerCode,
  confirmationId,
  serviceId,
  payload,
  techTimeId
}) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}/service/${serviceId}/technician/${techTimeId}/techtime/override`;
  const data = payload;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to override technician time."
    );
  });
};
