/* eslint-disable no-console */
/* Reference
https://www.quirksmode.org/js/detect.html
https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
*/

export const isIE = document.documentMode;
export const isEdge = /Edge/.test(navigator.userAgent);
export const isFirefox = typeof InstallTrigger !== "undefined";
export const isIEorEdge = isIE || isEdge;

export const getMetaTags = () => document.getElementsByTagName("meta");

/**
 * @function detectBrowser
 * @returns {string} browser name
 */
export const detectBrowser = () => {
  const sUsrAg = window.navigator.userAgent;
  let sBrowser = "unknown";
  // The order matters here, and this may report false positives for unlisted browsers.
  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Chrome";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser;
};
/**
 * @function getBrowserId
 * @returns {int} browserId
 */
export const getBrowserId = () => {
  const aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
    sUsrAg = window.navigator.userAgent,
    nIdx = aKeys.length - 1;
  for (let nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

  return nIdx;
};

export const detectOperationSystem = () => {
  const userAgent = window.navigator.userAgent;
  let operationSys = "unknown";
  // let name = "unknown";
  // if (window.navigator.appVersion.indexOf("Win") !== -1){
  //   name = "Windows";
  // }
  if (userAgent.match(/Linux/i)) {
    // Do Linux related stuff
    operationSys = "Linux";
  } else if (userAgent.match(/Windows/i)) {
    // Do Windows related stuff
    operationSys = "Windows";
  } else if (userAgent.match(/X11/i)) {
    // Do Unix related stuff
    operationSys = "Unix";
  } else if (userAgent.match(/Mac/i)) {
    // Do macOS related stuff
    operationSys = "Mac";
  } else if (userAgent.indexOf("Android") !== -1) {
    operationSys = "Android OS";
  }
  return operationSys;
};

export const getResolution = () => {
  const flexWidth = window.innerWidth;
  const flexHeight = window.innerHeight;
  console.log("window inner wt/ht", flexWidth, flexHeight);
  console.log(
    "Available Width/Ht" + window.screen.availWidth,
    window.screen.availHeight
  );
  console.log(
    "Your screen resolution is: " +
      window.screen.width +
      "x" +
      window.screen.height
  );
  console.log(
    "Your screen resolution ratio: " +
      window.screen.width * window.devicePixelRatio +
      "x" +
      window.screen.height * window.devicePixelRatio
  );
  const screen = {
    flexWidth,
    flexHeight
  };
  return screen;
};

export const setPendoLocation = path => {
  const { search, hash } = window.location;
  const domain = window.location.origin;
  const cleanUrl = `${domain}${path}${search}${hash}`;
  pendo.location.setUrl(cleanUrl);
};

// mock-sessionStorage
export const mockSessionStorage = () => {
  let store = {};

  return {
    getItem: key => store[key] || null,
    setItem: (key, value) => (store[key] = value.toString()),
    removeItem: key => delete store[key],
    clear: () => (store = {})
  };
};
