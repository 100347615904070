import React, { useState } from "react";
import { alertIconType, buttonStyle, CommonModal } from "./common-modal.component";
import Button from "@cx/ui/Button";
import "./common-modal-test-page.scss";

// Sample URL to launch this test page locally, with the required query params:
// http://localhost:3000/common-modal-test-page?userName=csr.advisor1&webKey=dmspluse2enext3xx1&appType=CSR&appEnv=ua9

// prettier-ignore
const CommonModalTestPage = () => {
  const [visibleModal, setVisibleModal] = useState(null);
  const [codeTitle, setCodeTitle] = useState("");
  const [code, setCode] = useState("");

  const modals = [
    {
      id: "basic",
      name: "Basic confirmation modal",
      modal:
        <CommonModal
          show={visibleModal === "basic"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          message="Your feedback has been submitted."
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />,
      code: `
        <CommonModal
          show={visibleModal === "basic"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          message="Your feedback has been submitted."
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
      `
    },
    {
      id: "basicUsingAlert",
      name: "Basic confirmation modal using alert",
      modal:
        <CommonModal
          show={visibleModal === "basicUsingAlert"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          alert={{
            type: alertIconType.success,
            message: "Your feedback has been submitted."
          }}
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />,
      code: `
        <CommonModal
          show={visibleModal === "basicUsingAlert"}
          onHide={() => setVisibleModal("")}
          title="Feedback"
          alert={{
            type: alertIconType.success,
            message: "Your feedback has been submitted."
          }}
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "feedbackModalOKButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
      `
    },
    {
      id: "speedBumpModal",
      name: "Typical \"speed bump\" modal, with a danger style button",
      modal:
        <CommonModal
          show={visibleModal === "speedBumpModal"}
          onHide={() => setVisibleModal("")}
          title="Delete user"
          message="Are you sure you want to delete this user?"
          buttons={[
            {
              text: "No",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Yes",
              style: buttonStyle.danger,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />,
      code: `
        <CommonModal
          show={visibleModal === "speedBumpModal"}
          onHide={() => setVisibleModal("")}
          title="Delete user"
          message="Are you sure you want to delete this user?"
          buttons={[
            {
              text: "No",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Yes",
              style: buttonStyle.danger,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
      `
    },
    {
      id: "fullFeaturedModal",
      name: "Full-featured modal",
      modal:
        <CommonModal
          show={visibleModal === "fullFeaturedModal"}
          onHide={() => setVisibleModal("")}
          title="Full-featured modal"
          message="This modal has a title, a message, an alert panel, a required Reason input, and OK & Cancel buttons."
          alert={{
            type: alertIconType.warning,
            message: (
              <>
                <p>This a "warning" alert panel.</p>
                <p>Also, it contains JSX elements instead of a simple string.</p>
              </>
            )
          }}
          reasonProps={{
            isRequired: true
          }}
          buttons={[
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />,
      code: `
        <CommonModal
          show={visibleModal === "fullFeaturedModal"}
          onHide={() => setVisibleModal("")}
          title="Full-featured modal"
          message="This modal has a title, a message, an alert panel, a required Reason input, and OK & Cancel buttons."
          alert={{
            type: alertIconType.warning,
            message: (
              <>
                <p>This a "warning" alert panel.</p>
                <p>Also, it contains JSX elements instead of a simple string.</p>
              </>
            )
          }}
          reasonProps={{
            isRequired: true
          }}
          buttons={[
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />
      `
    },
    /*
    TEMPLATE:
    {
      id: "_____",
      name: "_______________",
      modal:
        <CommonModal
          show={visibleModal === "___copy_the_id_here___"}
          onHide={() => setVisibleModal("")}
          title="_______________"
          message="_______________"
          buttons={[
            {
              text: "OK",
              style: buttonStyle.primary,
              testId: "someModalPrimaryButton",
              onClick: () => { setVisibleModal("") }
            },
            {
              text: "Cancel",
              style: buttonStyle.secondary,
              testId: "someModalSecondaryButton",
              onClick: () => { setVisibleModal("") }
            }
          ]}
        />,
      code: `
      `
    },
    */
  ];

  const activateModal = modal => {
    setVisibleModal(modal.id);
    setCodeTitle(modal.name);
    setCode(modal.code.replace(/(^\s+|\s+$)/g, "").replaceAll("\n        ", "\n"));
  };

  const buttons = modals.map(modal => {
    return (
      <Button key={modal.name} htmlId={`${modal.name}Button`} onClick={() => activateModal(modal)}>{modal.name}</Button>
    );
  });

  return (
    <div className="common-modal-test-page">
      <div className="buttons">
        {buttons}
      </div>
      {code.length ? (
        <div className="modal-code">
          <header>{codeTitle}:</header>
          <pre>{code}</pre>
        </div>
      ) : null}
      {modals.map(modal => {
        return (
          <div key={modal.name}>
            {modal.modal}
          </div>
        );
      })}
    </div>
  )
};

export default CommonModalTestPage;
