/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from "react";
import Badge from "@cx/ui/Badge";
import { toast } from "@cx/ui/Toast";
import csrService from "../../services/csr.service";
import TopLevelMenuModal from "../../../repair-order/components/top-level-menu-modal.component";
import SubHeader from "../../../repair-order/components/sub-header/sub-header.component";
import { useNewQuoteContext, Actions } from "../../../../state/NewQuoteContext";
import {
  buildSpecialOrderStatusBadge,
  isROOkayToVoid
} from "../../../../utils/quote.util";
import {
  PAY_TYPE_GROUPS,
  RO_STATUSES,
  TECH_STATUSES
} from "../../../repair-order/constants/csr.constants";
import { SERVICE_SUMMARY } from "../../../../constants/pages.constants";
import "./ro-details-header.scss";
import moment from "moment";
import { AppContext } from "../../../../state/app-context";
import QuoteStatusConstant from "../../../../constants/quote-status.constants";
import RoDetailsHeaderMenuOptions from "./components/ro-details-header-menu-options.component";
import {
  alertIconType,
  buttonStyle,
  CommonModal
} from "../../../ui/modals/common-modal/common-modal.component";

const RepairOrderHeader = () => {
  // REFERENCE: RO Details header - UX: https://www.sketch.com/s/8e61c245-4ea7-4e43-a0de-10834a4a2fe5/a/g10RpKz

  const [currentModalType, setCurrentModalType] = useState(null);
  const [showVoidRODeniedModal, setShowVoidRODeniedModal] = useState(false);
  const [showVoidROConfirmationModal, setShowVoidROConfirmationModal] =
    useState(false);
  const { state, dispatch } = useNewQuoteContext();
  const { quoteSummary, advisors } = state;
  const { quoteStatus } = quoteSummary;
  const roNumberDisplay = csrService.getRONumberDisplay(quoteSummary);
  const transportType = quoteSummary?.transportation?.start?.transportType;
  const isWaiter = transportType?.includes("WAITER");
  const appContext = useContext(AppContext);
  const { appSource, user, dealerProperties } = appContext;
  const legitPayers = csrService.getLegitPayers(quoteSummary, dealerProperties);
  const advisorIdCurrentlyAssigned = quoteSummary.serviceWriter;
  const advisorIdThatShouldBeAssigned = csrService.determineAdvisorToAutoAssign(
    user,
    quoteSummary,
    advisors
  );

  if (advisorIdCurrentlyAssigned !== advisorIdThatShouldBeAssigned) {
    dispatch({
      type: Actions.SET_ADVISOR,
      payload: advisorIdThatShouldBeAssigned
    });
  }

  // @note: When RO is opened from Engage, and the status is other than WITH_ADVISOR,
  // only the RO Number and the status badge are rendered and the roStatusInfo.cssClass is not used.
  // This whole component (RepairOrderHeader) will not render when the status is WITH_ADVISOR.
  const isEngageSource = appSource === "ENG";

  if (!quoteSummary || !quoteStatus) {
    return null;
  }

  const roStatusInfo = RO_STATUSES[quoteStatus];
  const techStatus =
    quoteSummary.quoteServices.map(c => c.technicians).flat().length > 0
      ? "ASSIGNED"
      : "UNASSIGNED";

  const promised = moment(quoteSummary.pickUpDateTime);
  const promisedDate = promised.isSame(new Date(), "day")
    ? "Today"
    : promised.format("M/D");
  const promisedTime = promised.format("h:mm");
  const promisedSuffix = promised.format("A");

  const statusBadge = (uniqueId, status) => {
    return status ? (
      <Badge
        htmlId={uniqueId}
        key={uniqueId}
        color={status.cxColor}
        className={`badge-${status.cssClass}`}
      >
        {status.displayText}
      </Badge>
    ) : null;
  };

  const beginVoidROProcess = () => {
    if (isROOkayToVoid(quoteSummary)) {
      setShowVoidROConfirmationModal(true);
    } else {
      setShowVoidRODeniedModal(true);
    }
  };

  const finishVoidROProcess = async reason => {
    setShowVoidROConfirmationModal(false);
    dispatch({ type: Actions.SET_PAGE_MASK, payload: true });
    try {
      const confirmationId = quoteSummary.confirmationId;
      const voidROResponse = await csrService.voidRO({
        appContext,
        confirmationId,
        reason
      });
      dispatch({ type: Actions.SET_PAGE_MASK, payload: false });
      toast.success("Repair order voided");
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: voidROResponse
      });
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: SERVICE_SUMMARY
      });
    } catch (e) {
      // NOTE: The failure toast message is handled in csrService.voidRO().
      console.error(e);
      dispatch({ type: Actions.SET_PAGE_MASK, payload: false });
    }
  };

  const handleCloseTopLevelMenuComponent = () => {
    setCurrentModalType(null);
  };
  /**
   *
   * @param {String} type
   */
  const selectMenuItem = type => {
    if (type === "VOID_RO") {
      beginVoidROProcess();
    } else {
      setCurrentModalType(type);
    }
  };
  const showTechnicianStatus =
    !isEngageSource && ["IN_PROCESS"].includes(quoteStatus);

  const showPayerStatuses =
    !isEngageSource &&
    ["WORK_FINISHED", "PRE_INVOICE", "FINALIZED", "PRE_RO"].includes(
      quoteStatus
    );

  const payerSortFunction = (a, b) => {
    return (
      PAY_TYPE_GROUPS.findIndex(ptg => ptg.value === a.payType) -
      PAY_TYPE_GROUPS.findIndex(ptg => ptg.value === b.payType)
    );
  };

  const hideLastServiceRemovedModal = () => {
    dispatch({
      type: Actions.SET_SHOW_LAST_SERVICE_REMOVED_MODAL,
      payload: false
    });
  };

  return (
    <>
      <div
        className={`ro-details-header ${
          isEngageSource ? "engage-source" : roStatusInfo?.cssClass
        }`}
      >
        <div id="mainGroup">
          <div id="roId" className="ro-number-container">
            {quoteStatus !== "PRE_RO" ? <label>RO</label> : null}
            <span className="ro-number">{roNumberDisplay}</span>
          </div>
          <div className="status-container ro-status">
            {statusBadge("roStatus", roStatusInfo)}
          </div>
          {showTechnicianStatus ? (
            <div id="techStatus" className="status-container">
              <label>Technician</label>
              {statusBadge("techStatus", TECH_STATUSES[techStatus])}
            </div>
          ) : null}
          {quoteSummary?.specialOrderStatus?.length ? (
            <div id="specialOrderStatus" className="status-container">
              <label>Special order status</label>
              {buildSpecialOrderStatusBadge(
                quoteSummary.roId,
                quoteSummary.specialOrderStatus
              )}
            </div>
          ) : null}
          {showPayerStatuses
            ? legitPayers?.sort(payerSortFunction).map(payer => {
                const uniqueId = `payTypeStatus${payer.payType}`;
                return (
                  <div
                    id={uniqueId}
                    key={uniqueId}
                    className="status-container"
                  >
                    <label>{payer.payType}</label>
                    <Badge
                      htmlId={`payerBadge${payer.payType}`}
                      color={payer.closedDate ? "purple" : "gray"}
                    >
                      {payer.closedDate
                        ? `Closed - ${moment(payer.closedDate).format("MM/DD")}`
                        : "Pending"}
                    </Badge>
                  </div>
                );
              })
            : null}
        </div>
        {!(isEngageSource || quoteStatus === QuoteStatusConstant.PRE_RO) ? (
          <div id="secondaryGroup">
            <div className="item-container hang-tag">
              <label>Hang tag</label>
              <span className="value">
                {state?.quoteSummary?.hangTag || "---"}
              </span>
            </div>
            <div className="item-container promised">
              <label>Promised</label>
              {quoteSummary.pickUpDateTime ? (
                <>
                  <span className="promised-date">{promisedDate}</span>
                  <span className="promised-time">{promisedTime}</span>
                  <span className="promised-suffix">{promisedSuffix}</span>
                </>
              ) : (
                <span className="value">---</span>
              )}
            </div>
            {transportType?.length ? (
              <div className="item-container transportation">
                <span className={isWaiter ? "waiter" : ""}>
                  {transportType}
                </span>
              </div>
            ) : null}
            <RoDetailsHeaderMenuOptions
              roNumberDisplay={roNumberDisplay}
              onSelectMenuItem={selectMenuItem}
            />
          </div>
        ) : null}
      </div>
      {currentModalType !== null ? (
        <TopLevelMenuModal
          currentModalType={currentModalType}
          callbackCloseAction={handleCloseTopLevelMenuComponent}
        />
      ) : null}
      {/* REFERENCE: Removing the last service on an RO - UX: https://www.figma.com/design/nVz6uqktMBbR7F7eUfCWBa/RO-Details?node-id=3038-11847&p=f&t=VTdXHvSXdhKeAzFQ-0 */}
      <CommonModal
        show={state.showLastServiceRemovedModal}
        onHide={hideLastServiceRemovedModal}
        title="Void RO?"
        message="You've removed the last service operation in the RO. Would you like to Void?"
        alert={{
          type: alertIconType.warning,
          message: "This action cannot be undone."
        }}
        buttons={[
          {
            text: "Cancel",
            style: buttonStyle.secondary,
            testId: "lastServiceRemovedModalCancelButton",
            onClick: hideLastServiceRemovedModal
          },
          {
            text: "Void RO",
            style: buttonStyle.danger,
            testId: "lastServiceRemovedModalVoidROButton",
            onClick: () => {
              hideLastServiceRemovedModal();
              beginVoidROProcess();
            }
          }
        ]}
      />
      <CommonModal
        show={showVoidRODeniedModal}
        onHide={() => setShowVoidRODeniedModal(false)}
        title="Void RO"
        alert={{
          type: alertIconType.danger,
          message: (
            <>
              <p>Cannot void repair order with tech time or approved parts.</p>
              <p>
                To prepare this RO to be voided, please remove all tech time and
                approved parts.
              </p>
            </>
          )
        }}
        buttons={[
          {
            text: "Done",
            style: buttonStyle.primary,
            onClick: () => setShowVoidRODeniedModal(false),
            testId: "voidRODeniedModalDoneButton"
          }
        ]}
      />
      <CommonModal
        show={showVoidROConfirmationModal}
        onHide={() => setShowVoidROConfirmationModal(false)}
        title="Void RO"
        message="Are you sure you want to cancel this repair order?"
        alert={{
          type: alertIconType.warning,
          message: "This action cannot be undone."
        }}
        reasonProps={{
          isRequired: true
        }}
        buttons={[
          {
            text: "No",
            style: buttonStyle.secondary,
            onClick: () => setShowVoidROConfirmationModal(false),
            testId: "voidROConfirmationModalNoButton"
          },
          {
            text: "Yes",
            style: buttonStyle.danger,
            onClick: finishVoidROProcess,
            testId: "voidROConfirmationModalYesButton"
          }
        ]}
      />
      {!isEngageSource ? <SubHeader /> : null}
    </>
  );
};

export default RepairOrderHeader;
