import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import ModalDialog from "@cx/ui/ModalDialog";
import themePrefixer from "@cx/ui/themePrefixer";
import Button from "@cx/ui/Button";
import PriceInput from "@cx/ui/PriceInput";

const WarrantyDeductibleModalComponent = ({
  show,
  warrantyDeductible,
  onCancel,
  onSave
}) => {
  // REFERENCE: Warranty deductible - UX: https://www.sketch.com/s/8e61c245-4ea7-4e43-a0de-10834a4a2fe5/v/ArnYyY/p/C9F431B4-ACD1-4085-8017-37F1F455664D/canvas?posX=-17433.78062825885&posY=-5898.028226809212&zoom=0.7545115947723389

  const [amount, setAmount] = useState(
    warrantyDeductible?.deductible ? String(warrantyDeductible?.deductible) : ""
  );

  const handleChangeAmount = cxEvent => {
    setAmount(cxEvent.target.value);
  };

  const handleSave = () => {
    const payload = {
      value: amount,
      deductibleIndex: warrantyDeductible?.index
    };

    onSave(payload);
  };

  return (
    <ModalDialog
      htmlId="warrantydeductibleModal"
      className="core-return-modal"
      show={show}
      backdrop="static"
      header={
        <h4 className={`${themePrefixer()}modal-title`}>Modify deductible</h4>
      }
      footer={
        <div className="lookup-modal-footer">
          <ButtonToolbar>
            <Button
              htmlId="warrantydeductibleCancelBtn"
              type="button"
              buttonStyle="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlId="warrantydeductibleSaveBtn"
              type="button"
              buttonStyle="primary"
              onClick={handleSave}
              disabled={
                amount === String(warrantyDeductible?.deductible) ||
                parseFloat(amount) > 9999.99 ||
                !amount
              }
            >
              Apply
            </Button>
          </ButtonToolbar>
        </div>
      }
      onHide={onCancel}
    >
      <>
        <p>{warrantyDeductible?.warrantyType}</p>
        <PriceInput
          htmlId="warrantydeductibleInput"
          label="Deductible"
          name="warrantydeductibleInput"
          onChange={handleChangeAmount}
          value={String(amount)}
          maxValue={9999.99}
          required
        />
      </>
    </ModalDialog>
  );
};

WarrantyDeductibleModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  warrantyDeductible: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default WarrantyDeductibleModalComponent;
