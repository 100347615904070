/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import TextInput from "@cx/ui/TextInput";
import PhoneInput from "@cx/ui/PhoneInput";
import EmailInput from "@cx/ui/EmailInput";
import CardGroup from "@cx/ui/CardGroup";
import Button from "@cx/ui/Button";
import { isDifferentValue } from "../../utils/string.util";
import { makeSecureRestApi } from "../../api/xmmAxios";
import { AppContext } from "../../state/app-context";
import { jsonStringfy, getVariantForVehicle } from "../utils/data-transformer";
import StatusBox from "../ui/banners/StatusBox";
import {
  ADD_VEHICLE,
  ADD_CUSTOMER,
  ADD_CUSTOMER_ADD_VEHICLE,
  EDIT_CUSTOMER
} from "../../constants/pages.constants";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import customerSchema from "../quote/schemas/customer.schema";
import isEmpty from "lodash/isEmpty";
import { appTypes } from "../../constants/app.constants";

const CustomerForm = () => {
  const appContext = useContext(AppContext);
  const { localeStrings, appType } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const { customer, vehicle, isSkipCustomerFlow } = state;
  const formType = state.currentPage;

  // TODO: check this Form failed to render for null record; Work around: when customer is undefined, NewCustomerTemplate binded
  const [newRecord, setNewRecord] = useState(
    formType === ADD_CUSTOMER || formType === ADD_CUSTOMER_ADD_VEHICLE
      ? customerSchema.customerInForm.cast({})
      : customer
  );
  const addMode = !customer || !customer.personId ? true : false;
  const isEditCustomerOnRODetails =
    formType === EDIT_CUSTOMER && appType === appTypes.CSR;
  const [dirty, setDirty] = useState(false);
  const [valid, setValid] = useState(false);
  const { firstName, lastName } = newRecord;
  const [validForm, setValidForm] = useState({
    firstName,
    lastName,
    home: {
      valid: true,
      data: newRecord.contactInfo.phoneNumbers.home ? true : false
    },
    work: {
      valid: true,
      data: newRecord.contactInfo.phoneNumbers.work ? true : false
    },
    mobile: {
      valid: true,
      data: newRecord.contactInfo.phoneNumbers.mobile ? true : false
    },
    email: true
  });
  const [statusBoxParams, setStatusBoxParams] = useState({
    statusMsg: "",
    statusType: "",
    autoClose: false,
    errorInTooltip: false
  });
  const defaultCustomerPrivacy = {
    callRemAppts: false,
    callReschNoShow: false,
    callRemSvcs: false,
    callPromoteSpecials: false,
    callShareData: false,
    callFollowup: false,
    callResvStatus: false,
    emailRemAppts: false,
    emailReschNoShow: false,
    emailRemSvcs: false,
    emailPromoteSpecials: false,
    emailShareData: false,
    emailFollowup: false,
    emailResvStatus: false,
    textRemAppts: false,
    textReschNoShow: false,
    textRemSvcs: false,
    textPromoteSpecials: false,
    textShareData: false,
    textFollowup: false,
    textResvStatus: false
  };

  // TODO: fix possible memory leak
  useEffect(() => {
    dispatch({
      type: Actions.SET_PAGE_TITLE,
      payload:
        state.currentPage === ADD_CUSTOMER ||
        state.currentPage === ADD_CUSTOMER_ADD_VEHICLE
          ? localeStrings["sq.newquote.customer.add_customer_lbl"]
          : localeStrings["sq.newquote.customer.edit_customer_lbl"]
    });
  }, [dispatch, localeStrings, state.currentPage]);

  useEffect(() => {
    // Validate the form to enabled the save button
    function isValidForm() {
      const { firstName, lastName, home, work, mobile, email } = validForm;
      if (!firstName) {
        return false;
      }
      if (!lastName) {
        return false;
      }
      if (!home.data && !work.data && !mobile.data) {
        return false;
      }
      if (!email) {
        return false;
      }
      if (!home.valid || !work.valid || !mobile.valid) {
        return false;
      }
      return true;
    }
    setValid(isValidForm());
  }, [validForm]);

  // @note: callback to redirect target page
  const redirectAfterSaveCustomer = () => {
    if (formType === ADD_CUSTOMER) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: ADD_VEHICLE
      });
    } else {
      dispatch({
        type: Actions.NAVIGATION.GO_BACK
      });
    }
  };

  const cancelCustomer = () => {
    dispatch({
      type: Actions.NAVIGATION.GO_BACK
    });
  };

  const resetState = () => {
    setNewRecord(jsonStringfy(customer));
    setDirty(false);
    setValid(false);
    setValidForm({
      home: false,
      work: false,
      mobile: false,
      email: true
    });
  };

  // call this for each field change event
  const markDirty = () => {
    setDirty(true);
  };

  const createCustomerPayload = formData => {
    const payload = {
      ...formData,
      ccId: "",
      contactInfo: {
        phoneNumbers: {
          MOBILE: formData.contactInfo.phoneNumbers.mobile,
          WORK: formData.contactInfo.phoneNumbers.work,
          HOME: formData.contactInfo.phoneNumbers.home
        },
        address: formData.contactInfo.address
      },
      customerPrivacy: createCustomerPrivacy(formData),
      vehicles: []
    };
    const formattedPayload = customerSchema.customer.cast(payload);
    return formattedPayload;
  };

  const createCustomerPrivacy = formData => {
    const { dealer } = appContext;
    const countryCode = dealer.dealerCountryCode;
    if (!countryCode || countryCode === "CA") {
      return defaultCustomerPrivacy;
    }
    if (countryCode === "US" || countryCode === "AU") {
      const isHomeNumber = formData.contactInfo.phoneNumbers.home;
      const isMobileNumber = formData.contactInfo.phoneNumbers.mobile;
      const isEmail = formData.email;
      return createUSprivacy(isHomeNumber, isMobileNumber, isEmail);
    }
  };

  const createUSprivacy = (isHomeNumber, isMobileNumber, isEmail) => {
    let preferences = defaultCustomerPrivacy;
    if (isHomeNumber || isMobileNumber) {
      preferences = {
        ...preferences,
        callRemAppts: true,
        callReschNoShow: true,
        callRemSvcs: true,
        callPromoteSpecials: true,
        callShareData: true,
        callFollowup: true,
        callResvStatus: true
      };
    }
    if (isEmail) {
      preferences = {
        ...preferences,
        emailRemAppts: true,
        emailReschNoShow: true,
        emailRemSvcs: true,
        emailPromoteSpecials: true,
        emailShareData: true,
        emailFollowup: true,
        emailResvStatus: true
      };
    }
    return preferences;
  };

  // save handler to send the payload to the correct endpoint depending of the Mode, add or edit
  const handleFormSubmit = async event => {
    event.preventDefault();
    delete newRecord["commonConsumerId"];
    const formattedPayload = createCustomerPayload(newRecord);
    if (isEditCustomerOnRODetails) {
      removeUnsupportedAttributeFromPayload(formattedPayload);
    }
    if (formType === ADD_CUSTOMER_ADD_VEHICLE) {
      formattedPayload.vehicles = [vehicle];
    }

    updateStatusBox(
      localeStrings["sq.common.saving_lbl"],
      "pending",
      false,
      false
    );
    const { dealer, schemaName, user } = appContext;
    const { dealerCode } = dealer;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let restEndPoint = `/customer/${schemaName}/${dealerCode}/${user.id}`;
    let method = "post";
    if (!addMode) {
      // Edit customer
      method = "put";
      restEndPoint = `/customer/${schemaName}/${dealerCode}/${user.id}/${newRecord.personId}`;
    }
    makeSecureRestApi(
      {
        url: restEndPoint,
        method,
        data: formattedPayload,
        params: {},
        headers
      },
      data => {
        // success case
        if (data) {
          if (data.success) {
            const { customers } = data;
            const customerToUpdate = customers;
            let isNewCustomer = false;
            if (formType === EDIT_CUSTOMER) {
              customerToUpdate.vehicles = state.customer.vehicles;
              isNewCustomer = isEmpty(state.customer.vehicles) ? true : false;
            } else if (formType === ADD_CUSTOMER) {
              isNewCustomer = true;
            }

            dispatch({
              type: Actions.UPDATE_CUSTOMER,
              payload: {
                customer: customerToUpdate,
                isNewCustomer
              }
            });
            // Case: skip customer flow, if new customer saved, update varaint in vehicle and update vehicle in newquote context
            if (isSkipCustomerFlow) {
              if (!isEmpty(customers.vehicles)) {
                const vehicleRecord = customers.vehicles[0];
                if (!isEmpty(vehicleRecord)) {
                  if (!vehicleRecord.variant) {
                    const { makes } = state;
                    vehicleRecord.variant = getVariantForVehicle(
                      vehicleRecord,
                      makes
                    );
                  }
                }
                dispatch({
                  type: Actions.UPDATE_VEHICLE,
                  payload: vehicleRecord
                });
              }
              dispatch({
                type: Actions.SET_IS_SKIP_CUSTOMER_FLOW,
                payload: false
              });
            }

            redirectAfterSaveCustomer();
            // error case
          } else if (!data.success) {
            updateStatusBox(
              localeStrings["sq.errors.error_saving_changes"],
              "error",
              false,
              true
            );
          }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["sq.errors.error_saving_changes"];
        updateStatusBox(msg, "error", false, true);
        resetState();
      },
      "Unable to save customer."
    );
  };

  // Utility function to remove specified fields from the payload in case of EDIT_CUSTOMER request is
  // made from CSR RO Details page because these values are not supported by scheduling-xt(source of truth
  // for customer) currently,
  const removeUnsupportedAttributeFromPayload = payload => {
    const fieldsToRemove = [
      "consumerIdType",
      "mobilePhone",
      "quoteCustomerId",
      "schema",
      "tempCCID"
    ];

    fieldsToRemove.forEach(field => {
      delete payload[field];
    });
  };

  // changeEvent for most of the fields
  const onChange = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(newRecord[name], value)) {
      const alfanumericValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
      const sanitizedValue = alfanumericValue.replace(/\s+/g, " ").trimStart();
      setNewRecord({
        ...newRecord,
        [name]: sanitizedValue
      });
      setValidForm({
        ...validForm,
        [name]: value
      });
      markDirty();
    }
  };

  // phone handler
  const onChangePhone = (cxEvent, isValid) => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(newRecord.contactInfo.phoneNumbers[name], value)) {
      markDirty();

      const hasData = isValid && value;
      setValidForm({
        ...validForm,
        [name]: {
          valid: isValid,
          data: hasData
        }
      });

      setNewRecord(prevNewRecord => ({
        ...prevNewRecord,
        contactInfo: {
          phoneNumbers: {
            ...prevNewRecord.contactInfo.phoneNumbers,
            [name]: value
          },
          address: prevNewRecord.contactInfo.address
        }
      }));
    }
  };

  // email handler
  const onChangeEmail = (cxEvent, isValid) => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(newRecord[name], value)) {
      markDirty();
      if (isEditCustomerOnRODetails) {
        isValid &&= value;
      }
      setValidForm({
        ...validForm,
        [name]: isValid
      });
    }

    setNewRecord(prevNewRecord => ({
      ...prevNewRecord,
      [name]: value
    }));
  };

  // update the message for status box
  const updateStatusBox = (msg, type, close, errorInTooltip) => {
    setStatusBoxParams({
      statusMsg: msg,
      autoClose: close,
      statusType: type,
      errorInTooltip
    });
  };

  const msgSection = null;
  const disable = !valid || !dirty;

  const statusBox = statusBoxParams.statusMsg ? (
    <div className="pull-left">
      <StatusBox
        htmlId="statusBox"
        type={statusBoxParams.statusType}
        autoClose={statusBoxParams.autoClose}
        linkHtml={null}
        message={statusBoxParams.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={statusBoxParams.errorInTooltip}
      />
    </div>
  ) : (
    ""
  );

  return (
    <Card header="" htmlId="customerFormCard">
      <form
        id="customerForm"
        className="customer-form"
        autoComplete="none"
        onSubmit={handleFormSubmit}
      >
        <div className="sq-flex-form">
          <TextInput
            htmlId="firstNameInput"
            label={localeStrings["sq.newquote.customer.first_name_lbl"]}
            name="firstName"
            autoComplete="none"
            required
            value={newRecord.firstName || ""}
            onChange={onChange}
            displayLabel={true}
            maxLength={64}
          />
          <TextInput
            htmlId="lastNameInput"
            label={localeStrings["sq.newquote.customer.last_name_lbl"]}
            name="lastName"
            autoComplete="none"
            value={newRecord.lastName || ""}
            required
            displayLabel={true}
            onChange={onChange}
            maxLength={64}
          />
          <EmailInput
            htmlId="emailInput"
            label={localeStrings["sq.newquote.customer.email_lbl"]}
            name="email"
            autoComplete="none"
            value={newRecord.email || ""}
            onChange={onChangeEmail}
            required={isEditCustomerOnRODetails}
          />
          <p className="sq-label">
            {
              localeStrings[
                "sq.newquote.customer.phone_number_at_least_one_required"
              ]
            }
            <span className="sq-red-label"> * </span>
          </p>
          <CardGroup className="group-container" htmlId="phoneGroupCard">
            <PhoneInput
              htmlId="homePhoneInput"
              label={localeStrings["sq.newquote.customer.home_phone_lbl"]}
              name="home"
              autoComplete="none"
              value={newRecord.contactInfo.phoneNumbers.home || ""}
              onChange={onChangePhone}
              displayLabel={true}
            />
            <PhoneInput
              htmlId="workPhoneInput"
              label={localeStrings["sq.newquote.customer.work_phone_lbl"]}
              name="work"
              autoComplete="none"
              value={newRecord.contactInfo.phoneNumbers.work || ""}
              onChange={onChangePhone}
              displayLabel={true}
            />
            <PhoneInput
              htmlId="mobilePhoneInput"
              label={localeStrings["sq.newquote.customer.mobile_phone_lbl"]}
              name="mobile"
              autoComplete="none"
              value={newRecord.contactInfo.phoneNumbers.mobile || ""}
              onChange={onChangePhone}
              displayLabel={true}
            />
          </CardGroup>

          {statusBox}

          <div className="cust-flex-bgroup">
            <span
              id="cancelButton"
              className="hand-cursor"
              onClick={cancelCustomer}
            >
              {localeStrings["sq.common.cancel_button"]}
            </span>

            <Button
              htmlId="saveButton"
              buttonStyle="primary"
              disabled={disable}
              type="submit"
            >
              {addMode
                ? localeStrings["sq.common.save_customer_button"]
                : localeStrings["sq.common.update_customer_button"]}
            </Button>
          </div>
          <span className="sq-msg">{msgSection}</span>
          <input
            autoComplete="on"
            style={{ display: "none" }}
            id="fake-hidden-input-to-stop-auto-complete"
          />
        </div>
      </form>
    </Card>
  );
};

export default CustomerForm;

CustomerForm.propTypes = {
  // cancelCustomer: PropTypes.func,
  // customer: PropTypes.object,
  // redirectAfterSaveCustomer: PropTypes.func
};
